import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProtectedResourceScopes, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalRedirectComponent, MsalService, MsalModule, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, protectedResources } from 'src/app/auth.config';
import { environment } from 'src/app/environments/environment'
import { getClaimsFromStorage } from 'src/app/storage-utils';
import { CommonInterceptor } from './common/_helpers/common.interceptor';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CrosstabModule } from './modules/crosstab/crosstab.module';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { HeaderLandingpageComponent } from './common/header/header-landingpage/header-landingpage.component';
import { SharedModule } from './common/shared/shared.module';
import { HeaderUserProjectMenuListComponent } from './common/header/header-user-project-menu-list/header-user-project-menu-list.component';
import { ProjectManagementComponent } from './modules/project-management/project-management.component';
import { UserManagementComponent } from './modules/user-management/user-management.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DataUploadComponent } from './modules/data-upload/data-upload.component';
import { DocumentclickDirective } from './common/directives/documentclick.directive';
import { FilterPopupComponent } from './modules/project-management/filter-popup/filter-popup.component';
import { HeaderUploadModuleComponent } from './common/header/header-upload-module/header-upload-module.component';
import { HeaderNavSnapOutputComponent } from './common/header/header-nav-snap-output/header-nav-snap-output.component';
import { HeaderSectionListComponent } from './common/header/header-section-list/header-section-list.component';

import { ChatbotComponent } from './modules/chatbot/chatbot.component';
import { ChatbotexpandComponent } from './modules/chatbotexpand/chatbotexpand.component';
import { RestatementComponent } from './modules/data-upload/restatement/restatement.component';




// import { HeaderNavTextSnapshotComponent } from './common/header/header-nav-text-snapshot/header-nav-text-snapshot.component';
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.Api.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.Api.scopes.read]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.Api.scopes.read]
    },
  ]);
  

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: (msalService, httpReq, originalAuthRequest) => {
      const resource = new URL(httpReq.url).hostname;
      let claim = msalService.instance.getActiveAccount()! &&
        getClaimsFromStorage(
          `cc.${msalConfig.auth.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
          }.${resource}`
        )
        ? window.atob(
          getClaimsFromStorage(
            `cc.${msalConfig.auth.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
            }.${resource}`
          )
        )
        : undefined;

      return {
        ...originalAuthRequest,
        claims: claim,
        authority: `https://login.microsoftonline.com/${originalAuthRequest.account?.tenantId ?? '1e355c04-e0a4-42ed-8e2d-7351591f0ef1'}`,
      };
    },
  };
}


/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
  DocumentclickDirective,
    AppComponent,
    LandingPageComponent,
    HeaderLandingpageComponent,
    HeaderUserProjectMenuListComponent,
    ProjectManagementComponent,
    UserManagementComponent,
    DataUploadComponent,
	 FilterPopupComponent,
   HeaderUploadModuleComponent,
   RestatementComponent,  
    ChatbotComponent,
  ChatbotexpandComponent 
    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ScrollingModule,
    NgIdleKeepaliveModule.forRoot(),
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: { scopes: ['user.read'], },
      },
      {
        interactionType: InteractionType.Redirect, protectedResourceMap: new Map([[environment.baseApiUrl, ['user.read']]]),
      }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonInterceptor,
      multi: true,
    },
  ],
  
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
