<div class="mainContainer">
  <div class="logoContainer">
    <div
      class="ImageContainer"
      [ngClass]="{
        'default-logo': this.ImageURL == null || this.ImageURL.trim() == ''
      }"
    >
      <img
        *ngIf="!(this.ImageURL == null || this.ImageURL.trim() == '')"
        [src]="ImageURL"
        alt="Logo"
        class="inner-image"
      />
    </div>
    <div class="divider"></div>
    <div class="crosstab-Text"></div>
  </div>
</div>
