import { Component } from '@angular/core';
import { AlertMessageService } from '../../services/alert-message.service';
import { AlertMessageModel } from '../../models/common.model';

@Component({
  selector: 'app-error-alert-popup',
  templateUrl: './error-alert-popup.component.html',
  styleUrls: ['./error-alert-popup.component.css']
})
export class ErrorAlertPopupComponent {
  headerTitle:string="Error";
  messageText:string="Client name is required";
  buttonText:string="Ok"
  showError:boolean=false;
  displayValue:string = "";
  heightValue:string = "";
  constructor(private _alertService:AlertMessageService){

  }
  ngOnInit() {
    this._alertService.isAlert$.subscribe((alert:AlertMessageModel) => {
      this.showError = alert.show;
      this.headerTitle=alert.header;
      this.messageText=alert.message
    });
  }
  close(){
    this.showError=false;
  }
  onOkBtnClick(){
    this.showError=false;
  }
  customcss(messageText:any){
    if(messageText.length != 0){
      if(messageText.length > 300){
        this.heightValue = '100%';
        return '';
      }
      else{
        this.heightValue = '';
        return '';
       
      }
    }
    return '';
  } 
}
