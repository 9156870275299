<div class="setting-block">
  <div class="setting-block-header" *ngIf="isHeader"></div>
  <div class="setting-icon" *ngIf="settingIconShown">
    <img *ngIf="isHeader"
      src="../../../../assets/images/common/Settings Icon.svg"
      title="setting"
      alt="setting"
      (click)="OnSettingClick()"
    />
    <img *ngIf="!isHeader"
      src="../../../../assets/images/common/download-color-icon.svg"
      title="Exports"
      alt="setting"
      (click)="OnSettingClick()"
    />
    <div class="setting-container" *ngIf="toggleSetting">
      <div class="setting-blocks"
           *ngIf="excelExport"
           [ngClass]="{ disabled: !showExcel }"
           title="Export to Excel"
           (click)="OnClickExcelExport()">
        <div class="setting-block-icon xls-icon"></div>
        <div class="setting-block-name">Export to Excel</div>
      </div>
      <div class="setting-blocks"
           *ngIf="helpGuide"
           title="Help Guide"
           (click)="onUserGuideClick()">
        <div class="setting-block-icon user-guide"></div>
        <div class="setting-block-name">Help Guide</div>
      </div>
  
      <div class="setting-blocks" *ngIf="pptExport"  [ngClass]="{ disabled: !showPPT }" title="Export to PPT" (click)="ExportToPPT()">
        <div class="setting-block-icon ppt-icon"></div>
        <div class="setting-block-name">Export to PPT</div>
      </div>
      <div class="setting-blocks" *ngIf="exportDownloads" title="Export Downloads" (click)="OnExportsDownloads()">
        <div class="setting-block-icon export-download-icon"></div>
        <div class="setting-block-name">Export Downloads</div>
      </div>
      <div class="setting-blocks" *ngIf="docClick"
           title="Document Repository" (click)="HandleDocumentClick()">
        <div class="setting-block-icon doc-repo-icon"></div>
        <div class="setting-block-name">Document Repository</div>
      </div>
      <div class="setting-blocks"
           *ngIf="userManagementShown && isUserManagement"
           title="User Management" (click)="onUserManagementClick()">
        <div class="setting-block-icon user-management-icon"></div>
        <div class="setting-block-name">User Management</div>
      </div>
      <div class="setting-blocks" *ngIf="logout" title="Log out" (click)="LogOut()">
        <div class="setting-block-icon logout-icon"></div>
        <div class="setting-block-name">Log out</div>
      </div>
    </div>
  </div>
  <div class="home-icon" *ngIf="Home" title="Home" (click)="redirectToLandingPage()">
    <img src="../../../../assets/images/common/Home Icon.svg" alt="Home" />
  </div>
</div>
<app-export-downloads-popup (closePopup)="onCloseExportDownloads()" [showExportsDownloads]="showExportsDownloadsPopup" *ngIf="showExportsDownloadsPopup" [ReportInput]="exportReportInput"></app-export-downloads-popup>
