import { Input, Component } from '@angular/core';

@Component({
  selector: 'app-header-brandlogo',
  templateUrl: './header-brandlogo.component.html',
  styleUrls: ['./header-brandlogo.component.css'],
})
export class HeaderBrandlogoComponent {
  @Input() ImageURL: string;

  constructor() {
    this.ImageURL = '';
  }
}
