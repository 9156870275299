<div class="ModuleList">
  <div class="ModuleList_Container">
    <div class="Header" [ngClass]="{'BG':active_Menu=='project'}" (click)="onMenuClick('project')">
      <div class="menu-header_txt">{{menu_1}}</div>
      <div class="header_border" *ngIf="active_line1">
        <div class="btn-icon header-line-icon"></div>
      </div>
    </div>
    <div class="Header" [ngClass]="{'BG':active_Menu=='user'}" (click)="onMenuClick('user')">
      <div class="menu-header_txt">{{menu_2}}</div>
      <div class="header_border" *ngIf="active_line2">
        <div class="btn-icon header-line-icon"></div>
      </div>
    </div>
  </div>
</div>
