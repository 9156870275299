import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.css']
})
export class TablePreviewComponent implements OnInit {
  @Input() data:any=[];
  cellWidth:number=150;
  cellHeight:number=35;
  ngOnInit():void {
  }

 // to get the row and column header text-> we are showing last checkbox selected item
  getHeader(item:any){
   
    if(item){
      let splitedText=item.split('|');
      return splitedText.join(' : ');
    }
    return item;
  }

  //To get the count of number of rows along with nesting
  getrowCount(){
    let rowCount=0;
    if(this.data!=null && this.data!=undefined && this.data["Row"]!=undefined){
      this.data.Row.forEach((x:any)=>{
        rowCount=rowCount+x.Data.length;
      })
      if(this.data["RowNesting"]!=undefined){
        this.data.RowNesting.forEach((x:any)=>{
          rowCount=rowCount*x.Data.length;
        });
      }
    }
    return rowCount;
  }
  // to get width of the cell based on the 
  getWidthofCellOnCount(panel:string,panelNested:string){
    let rowCellCount=1;
    if(this.data!=null && this.data!=undefined && this.data[panel]!=undefined){
      if(this.data[panelNested]!=undefined){
        this.data[panelNested].forEach((x:any)=>{
          rowCellCount=rowCellCount*x.Data.length;
        })
      }
    }
    let heightWidth=(panel=='Row')?this.cellHeight:this.cellWidth;
    return rowCellCount*heightWidth;
  }

  getNestingCount(index:number,panel:string,panelNested:string){
    let rowCellCount=1;
    if(this.data!=null && this.data!=undefined && this.data[panel]!=undefined){
      if(this.data[panelNested]!=undefined){
        for(let i=0;i<=index;i++){
          rowCellCount=rowCellCount*this.data[panelNested][i].Data.length;
        }
      }
    }
    return this.getWidthofCellOnCount(panel,panelNested)/rowCellCount;
  }

  //get the column and column nested count
  getrowCellCount(){
    let rowCellCount=0;
    if(this.data!=null && this.data!=undefined && this.data["Column"]!=undefined){
      this.data.Column.forEach((x:any)=>{
        rowCellCount=rowCellCount+x.Data.length;
      })
      if(this.data["ColNesting"]!=undefined){
        this.data.ColNesting.forEach((x:any)=>{
          rowCellCount=rowCellCount*x.Data.length;
        })
      }
    }
    return rowCellCount;
  }

  //get the count for row or column nesting count
  getNestedLevelCount(index:number,nesting:any){
    let count=1;
    for(let i=0;i<index;i++){
      count=count*nesting[i].Data.length;
    }
    return count;
  }
  
  // Margin for the scroll based on the count of nested rows and columns
  getMarginForScroll(){
    let colRowCount=0;
    let RowColCount=0;
    if(this.data!=null && this.data!=undefined){
      if(this.data["Column"]!=undefined && this.data["Column"].length>0)
        colRowCount=1;
      if(this.data["ColNesting"]!=undefined && this.data["ColNesting"].length>0)
        colRowCount=colRowCount+this.data["ColNesting"].length;
      if(this.data["Row"]!=undefined && this.data["Row"].length>0)
        RowColCount=1;
      if(this.data["RowNesting"]!=undefined && this.data["RowNesting"].length>0)
        RowColCount=RowColCount+this.data["RowNesting"].length;
    }
    return `${(25 * colRowCount)}px 0 0 ${(150 * RowColCount)}px`
  }
}
