<div class="popup-container" *ngIf="showExportsDownloads">
    <div class="popup-model">
        <div class="popup-header">
            <div class="popup-header-text">{{headerTitle}}</div>
            <div class="popup-refresh" style="display: none;" (click)="mesage()" title="Refresh"> message</div>
            <div class="popup-refresh" (click)="OnRefreshClick()" title="Refresh" [ngClass]="{'DN':showSummary}"></div>
            <div class="popup-close" (click)="close()" title="Close"></div>
        </div>
        <div class="popup-body">
            <div class="popup-body-container" [ngClass]="{'DN':showSummary}">
                <div class="popup-body-block" (click)="onToggleLargeExport()">
                   <div class="popup-body-header"> {{largeExportsHeader}}</div>
                   <div class="popup-body-toggle">
                    <div class="popup-body-toggle-icon popup-body-downarrow-icon" [ngClass]="{'popup-body-uparrow-icon':isLargeExportShow}"></div>
                </div>
                </div>
                <div class="table-container" *ngIf="isLargeExportShow">
                    <table class="exports-details-table">
                        <thead>
                            <tr>
                                <th class="col-popup-sm">Name</th>
                                <th class="col-popup-sm">Status</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let item of exportDownloadsItems">
                                <td class="col-size-md" >
                                    <div class="summary-text-container">
                                    <div class="text-w-300 text-ellipsis" title="{{item.selection_summary}}">{{item.selection_summary}}</div>
                                    <div class="info-summary-icon" *ngIf="item.report_type=='Crosstab'" title="Full Summary text" (click)="OnClickInfoIcon(item.selection_summary)"></div>
                                </div>
                                </td>
                                <td class="col-size-sm" title="{{item.report_status}}">
                                    <div class="status-block" *ngIf="item.report_status=='In Progress'">
                                        <div class="status-icon in-progress-icon"></div>
                                        <div class="status-text">{{item.report_status}}</div>
                                    </div>
                                    <div class="status-block" *ngIf="item.report_status=='Completed'" (click)="OnCompletedClick(item)">
                                        <div class="status-icon completed-icon"></div>
                                        <div class="status-text">{{item.report_status}}</div>
                                    </div>
                                    <div class="status-block" *ngIf="item.report_status=='Failed'">
                                        <div class="status-icon failed-icon"></div>
                                        <div class="status-text">{{item.report_status}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="exportDownloadsItems==null || exportDownloadsItems.length==0">
                                <td colspan="2" style="text-align: center;">{{tableStatus}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="popup-body-container" [ngClass]="{'DN':showSummary}" *ngIf="customReportsDownloadsItems!=null">
                <div class="popup-body-block" (click)="onToggleDownloadExport()" >
                   <div class="popup-body-header"> {{downloadReports}}</div>
                   <div class="popup-body-toggle">
                    <div class="popup-body-toggle-icon popup-body-downarrow-icon" [ngClass]="{'popup-body-uparrow-icon':isDownloadExportShow}"></div>
                </div>
                </div>
            <div class="table-container" *ngIf="isDownloadExportShow">
                <table class="exports-details-table">
                    <thead>
                        <tr>
                            <th class="col-popup-sm">Name</th>
                            <th class="col-popup-sm">Status</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of customReportsDownloadsItems">
                            <td class="col-size-md" >
                                <div class="summary-text-container">
                                <div class="text-w-300 text-ellipsis" title="{{item.selection_summary}}">{{item.selection_summary}}</div>
                                <div class="info-summary-icon" *ngIf="item.report_type=='Crosstab'" title="Full Summary text" (click)="OnClickInfoIcon(item.selection_summary)"></div>
                            </div>
                            </td>
                            <td class="col-size-sm" title="{{item.report_status}}">
                                <div class="status-block" *ngIf="item.report_status=='In Progress'">
                                    <div class="status-icon in-progress-icon"></div>
                                    <div class="status-text">{{item.report_status}}</div>
                                </div>
                                <div class="status-block" *ngIf="item.report_status=='Completed'" (click)="OnCompletedClick(item)">
                                    <div class="status-icon completed-icon"></div>
                                    <div class="status-text">{{item.report_status}}</div>
                                </div>
                                <div class="status-block" *ngIf="item.report_status=='Failed'">
                                    <div class="status-icon failed-icon"></div>
                                    <div class="status-text">{{item.report_status}}</div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="exportDownloadsItems==null || exportDownloadsItems.length==0">
                            <td colspan="2" style="text-align: center;">{{tableStatus}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
            <div class="full-summary-container" [ngClass]="{'DN':!showSummary}">
                <div class="summary-header-container">
                    <div class="back-button-continer" (click)="OnBackClick()">
                        Back
                    </div>
                    <div class="summary-header-text">
                        Selection Summary
                    </div>
                </div>
                <div class="full-summary-text">{{selectionSummary}}</div>
            </div>
        </div>
    </div>
</div>