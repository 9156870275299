<div class="calendar-block">
    <div class="date-year-block">
        <div class="date-year-text" >{{ Year }}</div>
        <div class="arrow-icon left-arrow-icon" (click)="prevYear()" [class.disabled]="!displayPreviousYear && (Year <= fromYear)"></div>
        <div class="arrow-icon right-arrow-icon" (click)="nextYear()"
         [class.disabled]="Year === currentYear || ((!displayPreviousYear && (Year>fromYear)) ||(!displayPreviousYear && (this.maxMonthSelectable+this.fromMonth)<=this.monthCount))">
        </div>
    </div>
    <div class="date-month-block">
            <div *ngFor="let month of months;let i = index" class="month-block" (click)="onMonthClick(month)"
            [class.disabled]="month.disabled" [class.selected]="month.selected">
                <span >{{ month.name }}</span>
            </div>
         
    </div>
</div>