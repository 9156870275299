<app-header-nav-bar>
  <app-header-brandlogo style="visibility: hidden"></app-header-brandlogo>
  <app-header-user-project-menu-list [active_line1]="ActiveModule1" [active_line2]="ActiveModule2"
    [active_Menu]="'user'"></app-header-user-project-menu-list>
  <app-header-settings [settingIconShown]="true" [userManagementShown]="false" [excelExport]="excelExport"
    [helpGuide]="helpGuide" [pptExport]="pptExport" [repository]="repository"
    [exportDownloads]="exportDownloads"></app-header-settings>
</app-header-nav-bar>
<section>
  <div class="table-search-container">
    <div class="search-container">
      <div class="search-block" title="Search">
        <input type="text" [(ngModel)]="searchQuery" class="search-input" placeholder="Search" />
        <div class="search-icon"></div>
      </div>
    </div>
    <div class="table-container">
      <table class="user-table">
        <thead>
          <tr>
            <th class="col-size-sm">First Name</th>
            <th class="col-size-xsm">Last Name</th>
            <th class="col-size-md">Email ID</th>
            <th class="col-size-sm">Module</th>
            <th class="col-size-md">Client-Study/Sub Module</th>
            <th class="col-size-xsm">Is Super Admin</th>
            <th class="col-size-xsm" *ngIf="isLevel1">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let user of usersList
                | search : searchQuery : ['first_name', 'last_name', 'email'];
              let i = index
            ">
            <td class="col-size-sm text-ellipsis" title="{{ user.first_name }}">
              {{ user.first_name }}
            </td>
            <td class="col-size-xsm text-ellipsis" title="{{ user.last_name }}">
              {{ user.last_name }}
            </td>
            <td class="col-size-md text-ellipsis" title="{{ user.email }}">
              {{ user.email }}
            </td>
            <td>
              <div class="module-container" [ngClass]="{ disabled: user.super_admin }">
                <div class="selected-container" (click)="toggleModuleDropdown(i, user)"
                  [ngClass]="{ 'active-border': selectedmoduleCell === i }">
                  <div class="selected-text text-ellipsis">
                    {{
                    user.moduleName == null
                    ? "Select Module"
                    : user.moduleName
                    }}
                  </div>
                  <div class="dropdown-icon down-arrow-icon" [ngClass]="{ 'up-arrow-icon': selectedmoduleCell === i }">
                  </div>
                </div>
                <div class="dropdown-container" *ngIf="selectedmoduleCell === i">
                  <div class="search-row-block" title="Search">
                    <input type="text" [(ngModel)]="searchModule" class="search-input" placeholder="Search" />
                    <div class="search-icon"></div>
                  </div>
                  <div class="dropdown-options" *ngFor="
                      let item of modulesItems
                        | search : searchModule : ['module_name']
                    " (click)="OnRadioModuleToggle(item, user)" [ngClass]="{ 'bg-active': item.is_selected }">
                    <div class="radio-icon radio-inactive" [ngClass]="{ 'radio-active': item.is_selected }"></div>
                    <div class="option-text text-ellipsis" title="{{ item.module_name }}">
                      {{ item.module_name }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="client-study-container" [ngClass]="{
                  disabled: user.moduleName == null || user.super_admin
                }">
                <div class="selected-container" (click)="toggleClientDropdown(i, user)"
                  [ngClass]="{ 'active-border': selectedClientCell === i }">
                  <div class="selected-text text-ellipsis" >
                    {{
                    user.clientStudyName == null
                    ? "Select Client Study/Sub Module"
                    : user.clientStudyName
                    }}
                  </div>
                  <!-- <div class="selected-text text-ellipsis" *ngIf="user.moduleName=='Snapshot'">
                    {{
                    user.clientStudyName == null
                    ? "Select Client Snapshot"
                    : user.clientStudyName
                    }}
                  </div> -->
                  <div class="dropdown-icon down-arrow-icon" [ngClass]="{ 'up-arrow-icon': selectedClientCell === i }">
                  </div>
                </div>
                <div class="dropdown-container" *ngIf="selectedClientCell === i">
                  <div class="search-row-block" title="Search">
                    <input type="text" [(ngModel)]="searchClientStudy" class="search-input" placeholder="Search" />
                    <div class="search-icon"></div>
                  </div>





                  <ng-container *ngIf="user.moduleName=='Data Explorer'||user.moduleName=='ARK'||user.moduleName=='Data Upload'">
                    <div class="dropdown-options" *ngFor="
                    let item of clientStudyItems
                      | search : searchClientStudy : ['client_study_name'];
                      let ind = index
                  "  [ngClass]="{
                    'bg-active': item.isSelected||clientStudyOption==ind,
                    disabled: item.is_disabled
                  }">
                      <div class="checkbox-icon checkbox-inactive" (click)="OnCheckboxClientStudy(item, user,ind)" [ngClass]="{ 'checkbox-active': item.selectedtype==1, 'checkbox-partial': item.selectedtype==2 }">
                      </div>
                      <div class="option-text text-ellipsis" (click)="OnClickClientStudy(item, user,ind)" title="{{ item.client_name }} : {{ item.study_name }}">
                        {{ item.client_name }} : {{ item.study_name }}
                      </div>
                      <div class="dropdown-icon right-arrow-icon" (click)="OnClickClientStudy(item, user,ind)" *ngIf="item.isEntryPointPresent" [ngClass]="{ 'left-arrow-icon': clientStudyOption==ind }">
                      </div>
                    </div>
                    
                  </ng-container>



                  <ng-container *ngIf="user.moduleName=='Snapshot'">
                    <div class="dropdown-options" *ngFor="
                    let item of clientSnapshotItems
                      | search : searchClientStudy : ['client_snapshot_name']
                  " (click)="OnCheckboxClientSnapshot(item, user)" [ngClass]="{
                    'bg-active': item.isSelected,
                    disabled: item.is_disabled
                  }">
                      <div class="checkbox-icon checkbox-inactive" [ngClass]="{ 'checkbox-active': item.isSelected }">
                      </div>

                      <div class="option-text text-ellipsis" title="{{ item.client_name }} : {{ item.snapshot_name }}">
                        {{ item.client_name }} : {{ item.snapshot_name }}
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="entry-point-container" *ngIf="showEntryPoints && selectedClientCell === i && (user.moduleName=='Data Explorer'||user.moduleName=='ARK')">
                  <div class="entry-point-block" *ngIf="entryPointNames.length>0" >
                    <div class="entry-point-options" *ngFor="let entryPoint of entryPointNames; let epIndex=index">
                      <div class="entry-point-option-header" (click)="onEntryPointClick(entryPoint,epIndex)" [ngClass]="{'bg-active':entryPointDropDownShow==epIndex}">
                        <div class="ep-option-text text-ellipsis">{{entryPoint.level1}}</div>
                        <div class="dropdown-icon down-arrow-icon" [ngClass]="{'up-arrow-icon':entryPointDropDownShow==epIndex}"></div>
                      </div>
                      <div class="ep-option-dropdown" *ngIf="entryPointDropDownShow==epIndex">
                        <div class="ep-dropdown-options" *ngFor="let level1 of entryPointLevels;let levelInd=index" 
                        (click)="onEntryPointSubLevelClick(level1,levelInd,epIndex,user)" [ngClass]="{'bg-active':levelIndexValue==levelInd}">
                          <div class="ep-dropdown-option-text text-ellipsis">{{level1.level2}}</div>
                          <div class="dropdown-icon right-arrow-icon" [ngClass]="{'left-arrow-icon':levelIndexValue==levelInd}"></div>
                        </div>
                         
                      </div>
                    </div>
                    <div class="ok-btn-container" *ngIf="entryPointNames!=null && entryPointNames.length>0">
                      <div class="error-block-txt" *ngIf="errorShow">{{errorMessage}}</div>
                      <div class="ok-btn" (click)="OnOkClick(entryPointNames,user)">Save</div>
                    </div>
                  </div>
                  <div class="no-entry-point" *ngIf="entryPointNames.length==0">No entry points are present for this study.</div>

                  <div class="ep-nextlevel-container" *ngIf="showEPnextLevel">
                    <div class="ep-nextlevel-container-block">
                      <div class="ep-selectall-option" [ngClass]="{ 'bg-active': epSelecteAll }"
                       *ngIf="entryPointMetricOption.length>0" (click)="OnSelectAllEp(entryPointMetricOption)">
                        <div class="checkbox-icon checkbox-inactive" [ngClass]="{'checkbox-active':epSelecteAll}"></div>
                        <div class="ep-nextlevel-option-text text-ellipsis">
                          Select All
                        </div>
                      </div>
                    <div class="ep-nextlevel-options" *ngFor="let metricOption of entryPointMetricOption" 
                    (click)="OnEpCheckboxClick(metricOption,user)" 
                     title="{{ metricOption.metricOption }}" [ngClass]="{ 'bg-active': metricOption.is_selected }">
                      <div class="checkbox-icon checkbox-inactive" [ngClass]="{ 'checkbox-active': metricOption.is_selected }"></div>
                      <div class="ep-nextlevel-option-text text-ellipsis">
                      {{metricOption.metricOption}}
                    </div>
                    </div>
                    <div class="empty-message" *ngIf="entryPointMetricOption==null ||entryPointMetricOption.length==0">
                      Please select previous entry entry point or we have no data for this.
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="checkbox-container" *ngIf="isLevel1 && user.is_kantar_user">
                <div class="checkbox-icon checkbox-inactive" [ngClass]="{ 'checkbox-active': user.super_admin }"
                  (click)="OnSuperAdminClick(user)"></div>
              </div>
              <ng-container *ngIf="isLevel2 && user.super_admin">Super Admin</ng-container>
            </td>
            <td *ngIf="isLevel1">
              <div class="delete-container">
                <div class="delete-icon" title="Delete" (click)="OndeleteUserClick(user)"></div>
              </div>
            </td>
          </tr>
          <tr *ngIf="usersList == null || usersList.length == 0">
            <td colspan="7" style="text-align: center">No Data Available</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="submit-container">
    <!--<div class="submit-block" title="Submit" (click)="onSubmitClick()"
       *ngIf="usersList != null && usersList.length > 0">
    <div class="upload-icon"></div>
    <div class="submit-text">Upload</div>
  </div>-->


    <div class="submit-block" title="Submit" (click)="onTemplateClick()">
      <div class="save-icon"></div>
      <div class="submit-text">Excel Template</div>
    </div>

    <div class="submit-block" title="Users Upload">
      <label for="file-input1" class="upload-label">
        <i class="btn-icon upload-icon"></i> Upload
      </label>
      <input type="file" id="file-input1" class="file-input" (change)="onUserUpload($event,'logo')" #fileInput>
    </div>

    <div class="submit-block" title="Submit" (click)="onSubmitClick()"
         *ngIf="usersList != null && usersList.length > 0">
      <div class="submit-icon"></div>
      <div class="submit-text">Submit</div>
    </div>
  </div>
</section>
<app-confirmation-dialog *ngIf="isShowDialog" [messageText]="confirmationMessage"
  (confirmed)="onConfirmation($event)"></app-confirmation-dialog>
