import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges } from '@angular/core';
import { LowBase, VariableLabelCode } from 'src/app/common/models/configurations.model';
import { AlertMessageModel, InsightsModel, results } from 'src/app/common/models/common.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';

@Component({
  selector: 'app-low-base-widget',
  templateUrl: './low-base-widget.component.html',
  styleUrls: ['./low-base-widget.component.css']
})
export class LowBaseWidgetComponent implements OnInit {
  /**
* This @Input decorator is used to accept data from the parent component.
* It allows the parent to bind a value to this property.
* [header]="headerConstants.WEIGHTS"
*/
  @Input() header: string = "";
  @Output() baseIdentifier = new EventEmitter<any>();
  @Input() LowBase: number = 0;
  @Input() InsufficientBase: number = 0;

  @Input() LowBase_new: number = 0;
  @Input() IB_new: number = 0;

  /**
  * This @Input decorator is used to accept data from the parent component.
  * It allows the parent to bind a value to this property.
  * [data]="configData.weight"
  */
  @Input() data: LowBase[] = [];
  /**
   * This @Output decorator is used to emit events to the parent component.
   * It allows the child component to trigger events that the parent can listen to.
   * Example usage: (OncheckboxInputCheck)="OncheckboxInputClick($event)"
   */
  @Output() OncheckboxInputCheck: EventEmitter<{ data: VariableLabelCode, header: string }> = new EventEmitter<{ data: VariableLabelCode, header: string }>();


  constructor(private _alertService: AlertMessageService) {
  }

  ngOnInit(): void {
    const data = this.data;
    this.InsufficientBase = this.IB_new;
    this.LowBase = this.LowBase_new;
    //this.InsufficientBase = this.data
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['IB_new'] || changes['LowBase_new']) {
      //this.InsufficientBase
      this.InsufficientBase = this.IB_new;
      this.LowBase = this.LowBase_new;
    }
  }

  onKeyDown(event: KeyboardEvent, key: any, value: any): void {
    // Get the pressed key
    const pressedKey = event.key;

    const isValidKey = /^[0-9]+$/.test(pressedKey);

    if (!isValidKey && pressedKey !== 'Backspace') {
      event.preventDefault();
    }
  }

  onInput(event: any, item: { key: string, data_value: string }) {
    item.data_value = event.target.value;
    console.log(`Saved value for key ${item.key}: ${item.data_value}`);
    if (item.key == "Low Base") {
      this.LowBase = item.data_value == "" ? 0 : Number(item.data_value);
      //if (this.InsufficientBase > this.LowBase) {
      //  this.errorPopup("Insufficencient Base should be lesser then Low Base.");
      //}
    }
    else {
      this.InsufficientBase = item.data_value == "" ? 0 : Number(item.data_value);
      //if (this.InsufficientBase < this.LowBase) {
      //  this.errorPopup("Insufficencient Base should be lesser then Low Base.");
      //}
    }

    this.baseIdentifier.emit({
      LowBase: this.LowBase,
      InsufficientBase: this.InsufficientBase
    });
    // Here you can perform any additional logic you need, such as saving to a database or updating other parts of your application.
  }
  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }
}
