import {
  Component,
  Input,
  EventEmitter,
  Output,
  HostListener,
  SimpleChanges,
} from '@angular/core';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LoaderService } from 'src/app/common/services/loader.service';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { AlertMessageModel, results } from 'src/app/common/models/common.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CancelEntryPointItem } from '../../models/crosstab.model';

interface VariableList {
  Level0: string;
  isEntryPoint: boolean;
  hasOnlyBrandIndependentChildren: boolean;
  hasOnlyBrandDependentChildren: boolean;
  hasSomeBrandDependentChildrenNotAll: boolean;
  Level1: {
    Level1_value: string;
    is_derived: string;
    is_brand_analysis: number;
    calc_type: string;
    tooltip: string;
    hasNextLevel: boolean;
    data_variable_code: string;
    response_code: string;
    sort_id: string;
    is_multi_punch: string;
    date: string;
    year_code: string;
    is_dimension: string;
    isCustomGrpPresent: string;
    isCustomCalcPresent: string;
    kibanaFilterScript: string;
    customGroupId: string;
    customCalculationId: string;
    dataYearCode: string;
    is_time_Period_Present: string;
    //isCustomCalcPresent: string;
    //customCalculationId: string;
  }[];
}

interface MetricOptions {
  Name: string;
  isActive: boolean;
  responseCode: string;
}

interface Config {
  key: string;
  value: boolean;
}
@Component({
  selector: 'app-leftpanel',
  templateUrl: './leftpanel.component.html',
  styleUrls: ['./leftpanel.component.css'],
})
export class LeftpanelComponent {
  $unSubscribeSubmitData1!: Subscription;
  $unSubscribeSubmitData2!: Subscription;
  $unSubscribeSubmitData3!: Subscription;
  Reciveddata: any;
  @Output() BindImageURL = new EventEmitter<any>();
  @Input() ImageURL: string = '';
  @Input() landingdata: any;
  @Input() ReceivedVarData: any;
  @Input() selected_study: string = '';
  @Input() selected_client: string = '';
  @Input() isSavedScenarioSelected: boolean = false;
  dimensionList: string[] = [];
  @Input() isbackButton: boolean = false;
  @Input() isChangeEntryPoint: boolean = false;
  @Input() isCreateCustomGroup: boolean = false;
  @Input() isCustomGroupClick: boolean = false;

  @Input() isCreateCustomCalculation: boolean = false;
  @Input() isCustomCalculationClick: boolean = false;

  @Input() isBrandAnalysis: boolean = false;
  @Input() hidePanel: boolean = false;
  @Input() disableVariables: boolean = false;
  custom_calculation: string[] = [];
  custom_group: string[] = [];
  custom_report: string[] = [];
  export_download: string[] = [];
  metricOption: string[] = [];
  isStudyPanel = false;
  levelName: string = '';
  sidePanelblock: boolean = false;
  entryFlag: boolean = false;
  isEntry_Point = false;
  isVariableTab = false;
  isEntry_Tab = true;
  left_panel_collapse: boolean = false;
  is_variable_expand: boolean = false;
  is_load_btn: boolean = true;
  tempIsVariableTab = false;
  dataFormat: string = '';
  /* @Output() isBtnVisible = new EventEmitter<string>();*/
  @Output() selectedVariableLevel1 = new EventEmitter<any>();
  @Output() selectedVariableLevel0 = new EventEmitter<string>();
  @Output() entryListChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() dataListChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedConfig = new EventEmitter<any>();
  @Output() selectedEntry = new EventEmitter<any>();
  @Output() LeftPanelClick = new EventEmitter<any>();
  @Output() btnVisible = new EventEmitter<any>();
  @Output() dimensionListChange: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();
  @Output() cancelEntryPointChange: EventEmitter<CancelEntryPointItem> =
    new EventEmitter<CancelEntryPointItem>();
  variable_expand: string = '';
  entry_items_expand: string = '';
  entry_items_expand_lvl: boolean = false;
  entry_nested_items_expand: string = '';
  studyname: string = '';
  isLoadSubmitted: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();
  @Output() sendHelpGuide: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSelectAll: boolean = false;
  isconfigCustomGrpPresent: boolean = false;
  isconfigCustomCalcPresent: boolean = false;
  isSelectAllMetrics: boolean = false;
  selectAllCheck: number = 0;
  entryPanel: {
    Level1: string;
  }[] = [];

  @Input() dataArray: any[] = [];
  entryPanel_Lvl2: {
    Level1: string;
    Level2: string;
  }[] = [];

  entryPanel_Lvl3: {
    Level: number;
    Level1: string;
    Level2: string;
    MetricOptions: string;
    responseCode: string;
    isActive: boolean;
    variableCode: string;
    e1_variable_code: string;
    e2_variable_code: string;
    e3_variable_code: string;
    mandatory_variable_mapping: any[];
    dimension: any[];
  }[] = [];

  entryList: {
    Level1: string;
    variableCode: string;

    mandatory_variable_mapping: any[];
    dimension: any[];
    NestedEntryArray: {
      Level2: any[];
      MetricOptions: {
        Name: string;
        responseCode: string;
        isActive: boolean;
        e1_variable_code: string;
        e2_variable_code: string;
        e3_variable_code: string;
      }[];
    }[];
    entryDetails: string;
  }[] = [];
  variableList: VariableList[] = [];
  private selectedItem: HTMLElement | null = null;
  dragdropConnected: any = [
    'row',
    'col',
    'rowNest',
    'colNest',
    'filter',
    'customGroup',
    'customCalculation',
    'PreviewTable'
  ];
  drag_drop_container = [...this.dragdropConnected];

  searchText: string = '';
  filteredStudies: string[] = [];
  filteredEntryStudies: {
    study_name: string;
    entry_name: string;
    values: any[];
  }[] = [];
  entryArray: string[] = [];
  filterdEntryList: any[] = [];

  NestedEntryPoints = false;
  searchTextLevel2: string = '';
  searchMetricOption: string = '';
  selectedMarketItems: string[] = [];
  searchVariable: string = '';
  leftPanelSearchVariable: string = '';

  showCancelBtn: boolean = false;
  // variableTabData: string[] = [];
  variableTabData: any = [];
  EntryPointHeader = 'ENTRY POINT';
  isAggregatedData: boolean = false;
  isSemiAggregatedData: boolean = false;

  @Input() landingData!: any[];
  clientcode: string = '';
  SelectionObject: {
    Row: {
      Parent: string;
      Data: {
        Child: string;
      }[];
    }[];
    Row_Nest: {
      Parent: string;
      Data: {
        Child: string;
      }[];
    }[];
    Column: {
      Parent: string;
      Data: {
        Child: string;
      }[];
    }[];
    ColumnNest: {
      Parent: string;
      Data: {
        Child: string;
      }[];
    }[];
  }[] = [];
  @Output() isClearEverything = new EventEmitter<boolean>();
  brandIndependentVariableList: VariableList[] = [];
  brandDependentVariableList: VariableList[] = [];

  constructor(
    private router: Router,
    private dataSharingService: CrosstabService,
    private crossTabService: CrosstabService,
    private Loader: LoaderService,
    private _alertService: AlertMessageService
  ) {
    this.landingdata = this.router.getCurrentNavigation()?.extras.state;
  }
  ngOnInit() {
    this.dimensionList = [];
    this.entryPanel = [];
    this.variableList = [];
    this.getEntryData();
    this.crossTabService.selectedStudyName.next(this.selected_study);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //whenever load is clicked for particular saved scenario to bind leftpanel data this is called
    // This method will be called whenever there is a change in the @Input properties
    this.showCancelBtn = false;
    if (changes['isSavedScenarioSelected'] && this.isSavedScenarioSelected) {
      this.getVariableData();
    } else if (changes['dataArray'] && this.isSavedScenarioSelected) {
      this.getVariableData();
    } else if (changes['isbackButton'] && this.isbackButton) {
      this.getVariableData();
    } else if (changes['isChangeEntryPoint'] && this.isChangeEntryPoint) {
      this.tempIsVariableTab = false;
      if (this.isVariableTab) this.tempIsVariableTab = true;

      this.isVariableTab = false;
      this.is_load_btn = true;
      this.isEntry_Tab = true;
      this.showCancelBtn = true;
    }
    // else if (changes['isCustomGroupClick'] && this.isCustomGroupClick && this.variableList.length > 0) {
    //   let customObject = this.variableList;
    //   //let listToUpdate = this.customGroupSelectionsObj;

    //   let listToUpdate = customObject.filter(
    //     (a) => a.isEntryPoint != true && a.Level0 != 'Time Period' && a.Level0 != 'Custom Group'
    //   );
    //   this.variableList = listToUpdate;
    // }
    else if (
      changes['isCreateCustomCalculation'] &&
      this.isCreateCustomCalculation &&
      this.variableList.length > 0
    ) {
      const customObject = this.isBrandAnalysis
        ? this.brandDependentVariableList
        : this.variableList;
      const listToUpdate = customObject.filter(
        (a) =>
          a.isEntryPoint != true &&
          a.Level0 != 'Time Period' &&
          a.Level0 != 'Custom Mean Variable - User Defined' &&
          a.Level0 != 'Custom Mean Variable - Shared With Me'
      );
      if (this.isBrandAnalysis) {
        this.brandDependentVariableList = listToUpdate;
      } else {
        this.variableList = listToUpdate;
      }
    } else if (
      changes['isCreateCustomGroup'] &&
      this.isCreateCustomGroup &&
      this.variableList.length > 0
    ) {
      const customObject = this.isBrandAnalysis
        ? this.brandIndependentVariableList
        : this.variableList;
      const listToUpdate = customObject.filter(
        (a) =>
          a.isEntryPoint != true &&
          a.Level0 != 'Time Period' &&
          a.Level0 != 'Custom Percentage Variable - User Defined' &&
          a.Level0 != 'Custom Percentage Variable - Shared With Me'
      );
      if (this.isBrandAnalysis) {
        this.brandIndependentVariableList = listToUpdate;
      } else {
        console.log('variableList', this.variableList);
        this.variableList = listToUpdate;
      }
    }
    if (changes['hidePanel']) {
      this.sidePanelblock = false;
      this.entry_items_expand = '';
      this.variable_expand = '';
    }
    // else if (changes['isCustomGroupClick'] && this.isCustomGroupClick && this.variableTabData.length > 0) {
    //   this.BindVariableDataLeftPanel(this.variableTabData);
    //   this.crossTabService.setVariableTabData(this.variableTabData);
    // }
  }
  ngOnDestroy() {
    if (this.$unSubscribeSubmitData1) {
      this.$unSubscribeSubmitData1.unsubscribe();
    }
    if (this.$unSubscribeSubmitData2) {
      this.$unSubscribeSubmitData2.unsubscribe();
    }
    if (this.$unSubscribeSubmitData3) {
      this.$unSubscribeSubmitData3.unsubscribe();
    }
  }

  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }

  togglePanel(Name: string, SelectedValue: string) {
    if (Name == 'study') {
      this.isStudyPanel = !this.isStudyPanel;
      this.isEntry_Point = false;
      this.entry_items_expand = '';
      this.sidePanelblock = false;
    }
  }
  selectedStudy(Name: string) {
    this.studyname = Name;
    this.selected_study = Name;
    this.isStudyPanel = true;
    this.configChanges(this.Reciveddata, Name);
    this.BindEntryData(this.Reciveddata, Name, this.selected_client, true);
    this.crossTabService.selectedStudyName.next(this.selected_study);
  }
  collapsePanel() {
    this.left_panel_collapse = true;
    this.valueChange.emit(this.left_panel_collapse);
  }
  expandPanel() {
    this.left_panel_collapse = false;
    this.valueChange.emit(this.left_panel_collapse);
  }
  variableExpand(variableName: string) {
    this.searchVariable = '';
    this.isStudyPanel = false;
    if (this.is_variable_expand && this.variable_expand == variableName) {
      this.is_variable_expand = false;
      this.variable_expand = '';
    } else {
      this.variable_expand = variableName;
      this.is_variable_expand = true;
    }
  }

  displayBucketConditionCheck(variable: any, module: string = 'dataExplorer') {
    if (this.isCreateCustomCalculation) {
      var isMeanAvailable =
        variable.Level1.filter((item: any) => item.calc_type === 'mean')
          .length > 0;

      return !isMeanAvailable;
      // } else if (module == 'brandAnalysis') {
      //   return false;
    } else {
      var condition1 = !this.isDropDownDataAvailable(variable.Level1);
      return condition1;
    }
  }

  isDropDownDataAvailable(dropDownData: any) {
    var filteredData: any = _.filter(dropDownData, (item: any) => {
      return item.Level1_value.toLowerCase().includes(
        this.leftPanelSearchVariable.toLowerCase()
      );
    });
    return filteredData.length > 0;
  }

  onItemClicked(event: MouseEvent) {
    if (this.selectedItem) {
      this.selectedItem.classList.remove('clicked');
    }
    this.selectedItem = event.currentTarget as HTMLElement;

    const hasNextLevelValue = this.selectedItem
      .getAttribute('data-hasNextLevel')
      ?.toString();
    const has_nextLevel = hasNextLevelValue === 'true'; // Consider 'true' as true, anything else as false

    const dimension = this.selectedItem
      .getAttribute('data-is_dimension')
      ?.toString();
    const is_dimension = dimension === 'true'; // Consider 'true' as true, anything else as false

    const iscustomGrpPresent = this.selectedItem
      .getAttribute('data-is_CustomGroup')
      ?.toString();
    const is_custmGrpPresent = iscustomGrpPresent === 'true';

    const iscustomCalcPresent = this.selectedItem
      .getAttribute('data-is_CustomCalculation')
      ?.toString();
    const is_custmCalcPresent = iscustomCalcPresent === 'true';

    const isTimePeriodPresent = this.selectedItem
      .getAttribute('data-is_time_Period_Present')
      ?.toString();
    const is_Time_Period_Present = isTimePeriodPresent === 'true';
    this.selectedVariableLevel1.emit({
      tooltip: this.selectedItem.title,
      title: this.selectedItem.getAttribute('data-variable-name')?.toString(),
      Parent: this.selectedItem.getAttribute('data-attr')?.toString(),
      is_derived: this.selectedItem.getAttribute('data-is_derived')?.toString(),
      is_brand_analysis: this.selectedItem.getAttribute(
        'data-is_brand_analysis'
      ),
      is_calc_type: this.selectedItem
        .getAttribute('data-calc_type')
        ?.toString(),
      has_nextLevel,
      data_variable_code: this.selectedItem
        .getAttribute('data_variable_code')
        ?.toString(),
      data_response_code: this.selectedItem
        .getAttribute('data_response_code')
        ?.toString(),
      data_sort_id: this.selectedItem.getAttribute('data_sort_id')?.toString(),
      data_is_multi_punch: this.selectedItem
        .getAttribute('data_is_multi_punch')
        ?.toString(),
      data_date: this.selectedItem.getAttribute('data_date')?.toString(),
      data_year_code: this.selectedItem
        .getAttribute('data_year_code')
        ?.toString(),
      is_dimension,
      is_entrypoint: this.selectedItem.getAttribute('data-isEntryPoint')=='true'?true:false,
      is_custmGrpPresent,
      is_custmCalcPresent: is_custmCalcPresent,
      data_kibanaFilterScript: this.selectedItem
        .getAttribute('data-kibanaFilterScript')
        ?.toString(),
      data_customGroupId: this.selectedItem
        .getAttribute('data-customGroupId')
        ?.toString(),
      data_customCalculationId: this.selectedItem
        .getAttribute('data-customCalculationId')
        ?.toString(),
      dataYearCode: this.selectedItem
        .getAttribute('data-dataYearCode')
        ?.toString(),
      is_Time_Period_Present,
    });

    this.selectedItem.classList.add('clicked');
  }
  loadBtnClick(isZeroEntryPoint: boolean) {
    this.isStudyPanel = false;
    if (
      isZeroEntryPoint &&
      (this.dataArray.length == 0 ||
        this.dataArray.length != this.entryPanel.length)
    ) {
      this.getValidation('EntryPoint');
    } else {
      this.isVariableTab = true;
      this.is_load_btn = false;
      this.isEntry_Tab = false;
      this.sidePanelblock = false;
      this.isEntry_Point = false;
      this.entry_items_expand = '';

      this.btnVisible.emit({
        save: true,
        load: true,
        custom_groups: this.isconfigCustomGrpPresent ? true : false,
        custom_calc: this.isconfigCustomCalcPresent ? true : false,
        create_table: true,
        weighted_btns: true,
      });

      //this.isBtnVisible.emit('save');
      //this.isBtnVisible.emit('custom_groups');
      //this.isBtnVisible.emit('custom_calc');
      //this.isBtnVisible.emit('create_table');
      //this.isBtnVisible.emit('weighted_btns');
      this.entryListChange.emit(this.entryPanel);
      this.dataListChange.emit(this.dataArray);
      this.dimensionListChange.emit(this.dimensionList);
      this.getVariableData();
      //disable Study Dropdown click Event
      this.isLoadSubmitted = true;
      this.showCancelBtn = false;
    }
  }
  OnCancelBtnClick() {
    this.showCancelBtn = false;

    if (this.tempIsVariableTab) {
      this.isVariableTab = true;
      this.is_load_btn = false;
      this.isEntry_Tab = false;
    } else {
      this.dataArray = [];
    }
    let cancelData: CancelEntryPointItem = {
      isCancel: true,
      isVariableTab: this.isVariableTab,
    };
    this.cancelEntryPointChange.emit(cancelData);
  }

  toggleMetricOptions(
    Level1: any,
    nestedEntry: any,
    event: MouseEvent,
    entryDetails: string
  ) {
    this.sidePanelblock = true;
    this.variable_expand = '';
    this.entry_nested_items_expand = nestedEntry;
    this.selectedItem = event.currentTarget as HTMLElement;

    this.metricOption = [];
    this.dataArray.forEach((entry) => {
      const metricOptionsList = entry['EntryPoint1']?.MetricOptions || [];

      metricOptionsList.forEach((data: MetricOptions) => {
        this.metricOption.push(data.Name);
      });
    });

    const uniqueMetricOptionsArray =
      entryDetails == 'entry1' ? [] : this.metricOption;

    if (this.entryList.length > 0) {
      const EntryData = _.filter(
        this.entryList,
        (item) =>
          item.Level1 !== null && item.Level1 !== '' && item.Level1 === Level1
      );
      if (uniqueMetricOptionsArray.length > 0) {
        if (EntryData.length > 0) {
          this.filterdEntryList = _.filter(
            EntryData[0].NestedEntryArray,
            (item) => item.Level2 !== null && item.Level2 === nestedEntry
          );

          this.filterdEntryList =
            EntryData[0].NestedEntryArray[0].MetricOptions.filter(
              (option: any) => {
                const optionCodes = option.e2_variable_code
                  .split(',')
                  .map((code: any) => code.trim());
                return optionCodes.some((code: any) =>
                  uniqueMetricOptionsArray.includes(code)
                );
              }
            );
        }
      } else {
        if (EntryData.length > 0) {
          this.filterdEntryList = _.filter(
            EntryData[0].NestedEntryArray,
            (item) => item.Level2 !== null && item.Level2 === nestedEntry
          );
        }
      }
    }
  }
  onSearchLevel(Name: string) {
    this.sidePanelblock = false;
    this.entry_nested_items_expand = '';
  }
  shouldHideItem(item: string): boolean {
    return item.toString().includes(this.searchMetricOption);
  }

  checkBoxClick(
    index: number,
    Level: number,
    Header: string,
    Level1: string,
    Level2: string,
    MetricOptions: string,
    variableCode: string,
    responseCode: string,
    mandatory_variable_mapping: any[],
    isSelectAll: boolean,
    e1_variable_code: string,
    e2_variable_code: string,
    e3_variable_code: string,
    dimension: any[]
  ): void {
    const entryPointKey =
      Level === 0 ? 'EntryPoint1' : Level === 1 ? 'EntryPoint2' : 'EntryPoint3';

    if (Level === 0) {
      const entryPoint2Index = this.dataArray.findIndex((entry) =>
        entry.hasOwnProperty('EntryPoint2')
      );
      if (entryPoint2Index !== -1) {
        const entryPoint2 = this.dataArray[entryPoint2Index];

        const level2Index = entryPoint2['EntryPoint2'].Level2.findIndex(
          (l2: any) =>
            l2.MetricOptions.some(
              (mo: any) => mo.Dependency_e1 === MetricOptions
            )
        );

        if (level2Index !== -1) {
          const metricOptionIndex = entryPoint2['EntryPoint2'].Level2[
            level2Index
          ].MetricOptions.findIndex(
            (mo: any) => mo.Dependency_e1 === MetricOptions
          );
          if (metricOptionIndex !== -1) {
            entryPoint2['EntryPoint2'].Level2[level2Index].MetricOptions.splice(
              metricOptionIndex,
              1
            );

            // Check if Level2 length is 0, then remove the entire entryPoint2
            if (
              entryPoint2['EntryPoint2'].Level2[level2Index].MetricOptions
                .length === 0
            ) {
              entryPoint2['EntryPoint2'].Level2.splice(level2Index, 1);

              // Check if Level2 length is 0, then remove the entire entryPoint2
              if (entryPoint2['EntryPoint2'].Level2.length === 0) {
                this.dataArray.splice(entryPoint2Index, 1);

                const entryPoint3Index = this.dataArray.findIndex((entry) =>
                  entry.hasOwnProperty('EntryPoint3')
                );

                if (entryPoint3Index !== -1) {
                  this.dataArray.splice(entryPoint3Index, 1);
                }
              }
            }
          }
        }
        //const recordIndexToRemove = entryPoint2['EntryPoint2'].Level2.findIndex(
        //  (l2: any) =>
        //    l2.MetricOptions.some(
        //      (mo: any) => mo.Dependency === MetricOptions
        //    )
        //);
        //if (recordIndexToRemove !== -1) {
        //  entryPoint2['EntryPoint2'].Level2.splice(recordIndexToRemove, 1);
        //}
        //if (entryPoint2['EntryPoint2'].Level2.length === 0) {
        //  this.dataArray.splice(entryPoint2Index, 1);
        //}
      }

      const entryPoint3Index = this.dataArray.findIndex((entry) =>
        entry.hasOwnProperty('EntryPoint3')
      );
      if (entryPoint3Index !== -1) {
        const entryPoint3 = this.dataArray[entryPoint3Index];

        const level2Index = entryPoint3['EntryPoint3'].Level2.findIndex(
          (l2: any) =>
            l2.MetricOptions.some(
              (mo: any) => mo.Dependency_e1 === MetricOptions
            )
        );

        if (level2Index !== -1) {
          const metricOptionIndex = entryPoint3['EntryPoint3'].Level2[
            level2Index
          ].MetricOptions.findIndex(
            (mo: any) => mo.Dependency_e1 === MetricOptions
          );
          if (metricOptionIndex !== -1) {
            entryPoint3['EntryPoint3'].Level2[level2Index].MetricOptions.splice(
              metricOptionIndex,
              1
            );

            // Check if Level2 length is 0, then remove the entire entryPoint2
            if (
              entryPoint3['EntryPoint3'].Level2[level2Index].MetricOptions
                .length === 0
            ) {
              entryPoint3['EntryPoint3'].Level2.splice(level2Index, 1);

              // Check if Level2 length is 0, then remove the entire entryPoint2
              if (entryPoint3['EntryPoint3'].Level2.length === 0) {
                this.dataArray.splice(entryPoint3Index, 1);
              }
            }
          }
        }
      }
    } else if (Level === 1) {
      const entryPoint3Index = this.dataArray.findIndex((entry) =>
        entry.hasOwnProperty('EntryPoint3')
      );
      if (entryPoint3Index !== -1) {
        const entryPoint3 = this.dataArray[entryPoint3Index];

        const level2Index = entryPoint3['EntryPoint3'].Level2.findIndex(
          (l2: any) =>
            l2.MetricOptions.some(
              (mo: any) => mo.Dependency_e2 === e2_variable_code
            )
        );

        if (level2Index !== -1) {
          const metricOptionIndex = entryPoint3['EntryPoint3'].Level2[
            level2Index
          ].MetricOptions.findIndex(
            (mo: any) => mo.Dependency_e2 === e2_variable_code
          );
          if (metricOptionIndex !== -1) {
            entryPoint3['EntryPoint3'].Level2[level2Index].MetricOptions.splice(
              metricOptionIndex,
              1
            );

            // Check if Level2 length is 0, then remove the entire entryPoint2
            if (
              entryPoint3['EntryPoint3'].Level2[level2Index].MetricOptions
                .length === 0
            ) {
              entryPoint3['EntryPoint3'].Level2.splice(level2Index, 1);

              // Check if Level2 length is 0, then remove the entire entryPoint2
              if (entryPoint3['EntryPoint3'].Level2.length === 0) {
                this.dataArray.splice(entryPoint3Index, 1);
              }
            }
          }
        }
      }
    }
    const existingEntryIndex = this.dataArray.findIndex(
      (entry) =>
        entry.hasOwnProperty(entryPointKey) &&
        entry[entryPointKey].Level1 === Level1
    );

    if (existingEntryIndex !== -1) {
      // Entry with the same entryPointKey and Level1 exists
      const existingEntry = this.dataArray[existingEntryIndex];

      // Check if Level2 is different
      const existingLevel2Index = existingEntry[entryPointKey].Level2.findIndex(
        (l2: any) => l2.Parent === Level2
      );

      if (existingLevel2Index !== -1) {
        // Level2 with the same Parent exists
        const existingMetricOptionIndex = existingEntry[entryPointKey].Level2[
          existingLevel2Index
        ].MetricOptions.findIndex((mo: any) => mo.Name === MetricOptions);

        if (existingMetricOptionIndex !== -1) {
          // MetricOptions is the same, remove it
          if (isSelectAll == false) {
            existingEntry[entryPointKey].Level2[
              existingLevel2Index
            ].MetricOptions.splice(existingMetricOptionIndex, 1);
          }
        } else {
          // MetricOptions is different, push it to the existing MetricOptions array

          const existingMetricOptionIndex = existingEntry[
            entryPointKey
          ].Level2.findIndex((mo: any) => {
            const existingMetricOptions = mo.MetricOptions;
            return existingMetricOptions.some(
              (existingMo: any) => existingMo.Name === MetricOptions
            );
          });

          if (existingMetricOptionIndex !== -1) {
            if (isSelectAll == false) {
            }
          } else {
            existingEntry[entryPointKey].Level2[
              existingLevel2Index
            ].MetricOptions.push({
              Name: MetricOptions,
              responseCode: responseCode,
              isActive: true,
              Dependency_e1: e1_variable_code,
              Dependency_e2: e2_variable_code,
            });
          }
        }
      } else {
        const existingMetricOptionIndex = existingEntry[
          entryPointKey
        ].Level2.findIndex((mo: any) => {
          const existingMetricOptions = mo.MetricOptions;
          return (
            existingMetricOptions.findIndex(
              (existingMo: any) => existingMo.Name === MetricOptions
            ) !== -1
          );
        });

        if (existingMetricOptionIndex !== -1) {
          if (isSelectAll == false) {
            const metricOptionIndex = existingEntry[entryPointKey].Level2[
              existingMetricOptionIndex
            ].MetricOptions.findIndex(
              (existingMo: any) => existingMo.Name === MetricOptions
            );
            existingEntry[entryPointKey].Level2[
              existingMetricOptionIndex
            ].MetricOptions.splice(metricOptionIndex, 1);
          }
        } else {
          // Level2 is different, push new Level2 and MetricOptions
          existingEntry[entryPointKey].Level2.push({
            Parent: Level2,
            MetricOptions: [
              {
                Name: MetricOptions,
                responseCode: responseCode,
                isActive: true,
                Dependency_e1: e1_variable_code,
                Dependency_e2: e2_variable_code,
              },
            ],
          });
        }
      }
    } else {
      // Create a new entry
      const tempEntry = {
        [entryPointKey]: {
          Level1: Level1,
          Level2: [
            {
              Parent: Level2,
              MetricOptions: [
                {
                  Name: MetricOptions,
                  responseCode: responseCode,
                  isActive: true,
                  Dependency_e1: e1_variable_code,
                  Dependency_e2: e2_variable_code,
                },
              ],
            },
          ],
          variableCode: variableCode,
        },
        clientName: this.selected_client,
        studyName: this.selected_study,
        mandatory_variable_mapping: mandatory_variable_mapping,
        dimension: dimension,
      };
      this.dataArray.push(tempEntry);
    }
    const existingEntryIndex1 = this.dataArray.findIndex(
      (entry) =>
        entry.hasOwnProperty(entryPointKey) &&
        entry[entryPointKey].Level1 === Level1
    );

    const existingEntry = this.dataArray[existingEntryIndex1];

    const existingLevel2Index = existingEntry[entryPointKey].Level2.findIndex(
      (l2: any) => l2.Parent === Level2
    );
    if (
      this.entryPanel_Lvl3.filter(
        (a) => a.Level1 === Level1 && a.Level2 === Level2
      ).length ===
      (existingLevel2Index !== -1
        ? existingEntry[entryPointKey].Level2[existingLevel2Index]
          .MetricOptions.length
        : 0) &&
      isSelectAll == false
    ) {
      this.isSelectAll = true;
    } else {
      if (isSelectAll == false) {
        this.isSelectAll = false;
      }
    }
    this.ClearAll();
  }

  toggleStatus(
    Level: number,
    Level1: string,
    Level2: string,
    MetricOptions: string
  ): boolean {
    const entryPointKey =
      Level === 0 ? 'EntryPoint1' : Level === 1 ? 'EntryPoint2' : 'EntryPoint3';
    const entry = this.dataArray.find(
      (entry) =>
        entry.hasOwnProperty(entryPointKey) &&
        entry[entryPointKey].Level1 === Level1 &&
        entry[entryPointKey].Level2.some((l2: any) =>
          l2.MetricOptions.some((mo: any) => mo.Name === MetricOptions)
        )
    );
    if (entry) {
      // If entry is found, increment selectAllCheck
      this.selectAllCheck++;
    }

    //if (this.entryPanel_Lvl3.length == this.selectAllCheck) {
    //  this.isSelectAll = true;
    //}
    //else {
    //  this.isSelectAll = false;
    //}
    return entry ? true : false;
  }

  getValidation(Name: string): boolean {
    if (Name == 'Duplicate') {
      this.errorPopup('Already Exist');
    } else if (Name == 'EntryPoint') {
      this.errorPopup(
        'At least one option has to be selected from each Entry Point.'
      );
    }
    return false;
  }
  getEntryData(): void {
    this.Loader.showLoader();
    this.selected_study = this.landingdata.study;
    this.selected_client = this.landingdata.client_code;

    this.$unSubscribeSubmitData1 = this.crossTabService
      .getClientStudyData(this.landingdata, this.isBrandAnalysis)
      .subscribe((result: results) => {
        if (result.HasValidData) {
          this.Reciveddata = JSON.parse(result.Data);
          this.crossTabService.entrypointsData=JSON.parse(result.Data);
          this.clientcode = this.selected_client;

          if (this.Reciveddata.length > 0) {
            this.ImageURL = this.Reciveddata[0].url_logo;
            this.BindImageURL.emit({
              Image: this.ImageURL,
            });
            this.configChanges(this.Reciveddata, this.selected_study);
            this.entryPanel = [];
            this.variableList = [];
            this.BindEntryData(
              this.Reciveddata,
              this.selected_study,
              this.selected_client,
              true
            );
            let EntryData = this.Reciveddata.filter(
              (a: any) => a.Level1 != '' && a.study == this.selected_study
            );

            if (this.entryPanel.length > 0) {
              this.selectedEntry.emit({
                entryPanelData: this.entryPanel,
              });
            }
            if (EntryData.length > 0) {
              this.Loader.hideLoader();
            }
          }
        } else {
          this.Loader.hideLoader();
        }
      });
  }

  configChanges(data: any, study: any) {
    let comparisionpoint;
    let confidenceLevel;
    let weights;
    let dataFormat;
    data = data.filter((a: any) => a.study == study);
    if (data.length > 0) {
      if (data[0].comparison_point.length > 0) {
        // Check if "None" key is already present
        const isNoneKeyPresent = data[0].comparison_point.some(
          (itemConst: any) => itemConst.key === 'None'
        );
        // If the key is not present, add it
        if (!isNoneKeyPresent) {
          data[0].comparison_point.unshift({ key: 'None', value: true });
        }
        comparisionpoint = data[0].comparison_point.filter(
          (a: Config) => a.value == true
        );
        // Push other objects where value is true to comparison_point array
      }

      if (data[0].confidence_level.length > 0) {
        confidenceLevel = data[0].confidence_level.filter(
          (a: Config) => a.value == true
        );
      }

      if (data[0].weight.length > 0) {
        weights = data[0].weight.filter((a: any) => a.is_selected == true);
      }

      if (data[0].dataformat.length > 0) {
        dataFormat = data[0].dataformat.filter((a: any) => a.value == true);
      }

      this.selectedConfig.emit({
        RowNestSelection: data[0].nesting[0].is_selected,
        ColNestSelection: data[0].nesting[1].is_selected,
        is_brand_analysis: data[0].is_brand_analysis,
        is_chart_visualization: data[0].is_chart_visualization,
        is_sticky_selection: data[0].is_sticky_selection,
        is_reset_selection: data[0].is_reset_selection,
        is_Transpose: data[0].output_page[0].value,
        is_indexing: data[0].output_page[1].value,
        is_sigtesting: data[0].output_page[2].value,
        is_ranking: data[0].output_page[3].value,
        isFilterToggle: data[0].filter_function[0].value,
        isAnswerToggle: data[0].filter_function[1].value,
        comparisionpoint: comparisionpoint,
        confidenceLevel: confidenceLevel,
        excelExport: data[0].export_download[0].value,
        pptExport: data[0].export_download[1].value,
        repository: data[0].export_download[2].value,
        lowBaseIdentifier: data[0].export_download[3].value,
        is_AI_Integration: data[0].is_AI_Integration,
        is_StoryBoard: data[0].is_StoryBoard,
        is_Column_Row_Percentage: data[0].is_Column_Row_Percentage,
        insufficentBase: data[0].low_base_identifier[0].data_value,
        lowBase: data[0].low_base_identifier[1].data_value,
        threshold: data[0].threshold_identifier[0].data_value,
        configweights: weights,
        iscustomgrp: data[0].custom_content[0].value,
        iscustomCalc: data[0].custom_content[1].value,
        iscustomreport: data[0].custom_content[2].value,
        changeEntryPoint: data[0].save_selection[1].value,
        dataFormat: dataFormat.length > 0 ? dataFormat[0].key : '',
        isWeightedBase:
          data[0].aggregatedvariables[1].data_value == '' ? false : true,
        isSampleSize:
          data[0].aggregatedvariables[2].data_value == '' ? false : true,
        aggregatedVariables: data[0].aggregatedvariables,
        mandatoryVariableMapping: data[0].mandatory_variable_mapping,
        isSelectAllMetrics: data[0].is_SelectAll_Metrics,
      });
      this.dataFormat = dataFormat.length > 0 ? dataFormat[0].key : '';
      this.sendHelpGuide.emit(data[0].help_guide);
      this.custom_calculation = data[0].custom_calculation;
      this.custom_group = data[0].custom_group;
      this.custom_report = data[0].custom_report;
      this.export_download = data[0].export_download;
      this.isconfigCustomGrpPresent =
        data[0].custom_content[0].value == true ? true : false;
      this.isconfigCustomCalcPresent =
        data[0].custom_content[1].value == true ? true : false;
      this.isSelectAllMetrics = data[0].is_SelectAll_Metrics;
    }
  }

  BindEntryData(
    data: any,
    selectedStudy: string,
    ClientCode: string,
    ZeroEntryPoint: boolean
  ) {
    this.studyname = selectedStudy;
    this.entryPanel = [];
    this.variableList = [];
    this.filteredStudies = _.uniq(
      _.map(
        _.filter(
          data,
          (item) =>
            item.study !== null &&
            item.study !== '' &&
            item.client_name == ClientCode
        ),
        'study'
      )
    );
    const Leve1 = _.uniq(
      _.map(
        _.filter(
          data,
          (item) =>
            item.Level1 !== null &&
            item.Level2 !== '' &&
            item.study == selectedStudy
        ),
        'Level1'
      )
    );
    for (const element of Leve1) {
      this.entryPanel.push({
        Level1: element,
      });
    }
    if (ZeroEntryPoint && this.entryPanel.length == 0) {
      this.dataArray = [];
      const clientStudyInfo = {
        clientName: this.selected_client,
        studyName: this.selected_study,
      };
      this.dataArray.push(clientStudyInfo);
      this.loadBtnClick(false);
    }
  }
  entryExpand(lvl1: string) {
    this.isStudyPanel = false;
    this.searchTextLevel2 = '';
    this.searchMetricOption = '';
    if (this.entry_items_expand == '') {
      this.entry_items_expand = lvl1;
      this.entryFlag = true;
    } else if (this.entry_items_expand == lvl1) {
      this.entry_items_expand = '';
      this.entryFlag = false;
    } else {
      this.entry_items_expand = lvl1;
      this.entryFlag = true;
    }

    this.LeftPanelClick.emit({
      entry_items_expand: this.entry_items_expand,
      entryFlag: this.entryFlag,
    });

    this.entryPanel_Lvl2 = [];
    this.sidePanelblock = false;
    let lvl2Update = this.Reciveddata.filter(
      (a: any) => a.Level1 === lvl1 && a.study == this.selected_study
    );
    lvl2Update = _.sortBy(lvl2Update, 'sortid');
    const Leve2 = _.uniq(
      _.map(
        _.filter(
          lvl2Update,
          (item) =>
            item.Level2 !== null && item.Level2 !== '' && item.Level1 == lvl1
        ),
        'Level2'
      )
    );
    for (const lvl2 of Leve2) {
      this.entryPanel_Lvl2.push({
        Level1: lvl1,
        Level2: lvl2,
      });
    }
  }

  BindSidePanel(EntryDetails: number, lvl1: string, lvl2: string) {

    this.searchTextLevel2 = '';
    this.searchMetricOption = '';
    this.entryPanel_Lvl3 = [];
    if(this.levelName==lvl1+"|"+lvl2)
      this.sidePanelblock = !this.sidePanelblock;
    else
      this.sidePanelblock = true;
    this.levelName = lvl1+"|"+lvl2;
    this.selectAllCheck = 0;
    let lvl2Update = this.Reciveddata.filter(
      (a: any) =>
        a.Level1 === lvl1 && a.Level2 === lvl2 && a.study == this.selected_study
    );
    lvl2Update = _.sortBy(lvl2Update, 'sortid');
    this.metricOption = [];
    let uniqueMarket: any = [];
    let uniqueCate: any = [];
    this.dataArray.forEach((entry) => {
      const metricOptionsList1 = entry['EntryPoint1']?.Level2 || [];

      metricOptionsList1.forEach((data: any) => {
        data.MetricOptions.forEach((metricOption: any) => {
          this.metricOption.push(metricOption.responseCode);
          uniqueMarket.push(metricOption.responseCode);
        });
        //this.metricOption.push(data.responseCode);
      });

      const metricOptionsList2 = entry['EntryPoint2']?.Level2 || [];

      metricOptionsList2.forEach((data: any) => {
        data.MetricOptions.forEach((metricOption: any) => {
          this.metricOption.push(metricOption.responseCode);
          uniqueCate.push(metricOption.responseCode);
        });
      });

      const metricOptionsList3 = entry['EntryPoint3']?.Level2 || [];

      metricOptionsList3.forEach((data: any) => {
        data.MetricOptions.forEach((metricOption: any) => {
          this.metricOption.push(metricOption.responseCode);
        });
      });
    });

    const uniqueMetricOptionsArray = this.metricOption;

    const Leve2 = _.uniq(
      _.map(
        _.filter(
          lvl2Update,
          (item) =>
            item.Level1 === lvl1 &&
            item.Level2 === lvl2 &&
            item.MetricOption != ''
        ),
        'MetricOption'
      )
    );

    for (const lvl of Leve2) {
      let MetricOptions = this.Reciveddata.filter(
        (a: any) =>
          a.Level1 === lvl1 &&
          a.Level2 === lvl2 &&
          a.MetricOption == lvl &&
          a.study == this.selected_study
      );

      if (EntryDetails == 1) {
        MetricOptions = MetricOptions.filter((a: any) => {
          const optionCodes = a.e1_variable_code
            .split(',')
            .map((code: any) => code.trim());
          return optionCodes.some((code: any) =>
            uniqueMetricOptionsArray.includes(code)
          );
        });
        MetricOptions = MetricOptions.filter((x: any) => {
          return uniqueMarket.indexOf(x.e1_variable_code) > -1;
        });
      } else if (EntryDetails == 2) {
        MetricOptions = MetricOptions.filter((a: any) => {
          const optionCodes = a.e1_variable_code
            .split(',')
            .map((code: any) => code.trim());
          const optionCodes2 = a.e2_variable_code
            .split(',')
            .map((code: any) => code.trim());

          return (
            optionCodes.some((code: any) =>
              uniqueMetricOptionsArray.includes(code)
            ) ||
            optionCodes2.some((code: any) =>
              uniqueMetricOptionsArray.includes(code)
            )
          );
        });
        MetricOptions = MetricOptions.filter((x: any) => {
          return (
            uniqueMarket.indexOf(x.e1_variable_code) > -1 &&
            uniqueCate.indexOf(x.e2_variable_code) > -1
          );
        });
      }
      if (MetricOptions.length > 1) {
        MetricOptions = [MetricOptions[0]];
      }
      for (const metric of MetricOptions) {
        this.entryPanel_Lvl3.push({
          Level: EntryDetails,
          Level1: lvl1,
          Level2: lvl2,
          MetricOptions: metric.MetricOption,
          responseCode: metric.ResponseCode,
          isActive: false,
          variableCode: metric.VariableCode,
          e1_variable_code: metric.e1_variable_code,
          e2_variable_code: metric.e2_variable_code,
          e3_variable_code: metric.e3_variable_code,
          mandatory_variable_mapping: metric.mandatory_variable_mapping,
          dimension: metric.dimension,
        });
      }
    }

    const entryPointKey =
      EntryDetails === 0
        ? 'EntryPoint1'
        : EntryDetails === 1
          ? 'EntryPoint2'
          : 'EntryPoint3';
    const existingEntryIndex1 = this.dataArray.findIndex(
      (entry) =>
        entry.hasOwnProperty(entryPointKey) &&
        entry[entryPointKey].Level1 === lvl1
    );

    if (existingEntryIndex1 == -1) {
      this.isSelectAll = false;
    } else {
      const existingEntry = this.dataArray[existingEntryIndex1];

      const existingLevel2Index = existingEntry[entryPointKey].Level2.findIndex(
        (l2: any) => l2.Parent === lvl2
      );
      if (
        this.entryPanel_Lvl3.filter(
          (a) => a.Level1 === lvl1 && a.Level2 === lvl2
        ).length ===
        (existingLevel2Index !== -1
          ? existingEntry[entryPointKey].Level2[existingLevel2Index]
            .MetricOptions.length
          : 0)
      ) {
        this.isSelectAll = true;
      } else {
        const entryPanelOptions = this.entryPanel_Lvl3.map(
          (entry) => entry.MetricOptions
        );
        const isAllOptionsIncluded = entryPanelOptions.every((option) =>
          uniqueMetricOptionsArray.includes(option)
        );
        if (isAllOptionsIncluded) {
          this.isSelectAll = true;
        } else {
          this.isSelectAll = false;
        }
      }
    }
  }

  getVariableData(): void {
    this.Loader.showLoader();
    this.$unSubscribeSubmitData3 = this.crossTabService
      .getVariableData(this.dataArray, this.isBrandAnalysis)
      .subscribe((result: results) => {
        // Response is valid
        if (result.HasValidData) {
          let JsonData = JSON.parse(result.Data);
          if (this.isBrandAnalysis) {
            JsonData.forEach((x: any) => {
              const is_multi_punch = x.is_multi_punch > 0 ? true : false;
              if (is_multi_punch) {
                x.MetricOption = '';
              }
              //   x.Level2 = '';
              switch (x.is_brandanalysis) {
                case 1:

                  x.Level2 = x.Level3;
                  x.Level3 = x.Level4;
                  x.Level4 = x.Level5;
                  // if (is_multi_punch && x.Level3 == '') {
                  //   x.MetricOption = '';
                  //   x.Level2 = '';
                  // } else if (is_multi_punch && x.Level3 !== '') {
                  //   x.Level2 = x.Level3;
                  //   x.Level3 = '';
                  //   // x.MetricOption = x.Level3;
                  //   // x.Level2 = '';
                  // } else if (is_multi_punch && x.Level4 !== '') {
                  //   x.MetricOption = x.Level4;
                  //   x.Level2 = x.Level3;
                  // } else if (is_multi_punch && x.Level5 !== '') {
                  //   x.MetricOption = x.Level5;
                  //   x.Level3 = x.Level4;
                  //   x.Level2 = x.Level3;
                  // } else if (is_multi_punch && x.Level6 !== '') {
                  //   x.MetricOption = x.Level6;
                  //   x.Level4 = x.Level5;
                  //   x.Level3 = x.Level4;
                  //   x.Level2 = x.Level3;
                  // } else {
                  //   x.Level2 = x.Level3;
                  //   x.Level3 = x.Level4;
                  //   x.Level4 = x.Level5;
                  // }
                  break;
                case 2:
                  x.Level3 = x.Level4;
                  x.Level4 = x.Level5;
                  // if (is_multi_punch && x.Level4 == '') {
                  //   x.MetricOption = '';
                  //   x.Level3 = '';
                  // } else if (is_multi_punch && x.Level4 !== '') {
                  //   x.MetricOption = x.Level4;
                  //   x.Level3 = '';
                  // } else if (is_multi_punch && x.Level5 !== '') {
                  //   x.MetricOption = x.Level5;
                  //   x.Level3 = x.Level4;
                  // } else if (is_multi_punch && x.Level6 !== '') {
                  //   x.MetricOption = x.Level6;
                  //   x.Level4 = x.Level5;
                  //   x.Level3 = x.Level4;
                  // } else {
                  //   x.Level3 = x.Level4;
                  //   x.Level4 = x.Level5;

                  // }
                  break;
                case 3:
                  x.Level4 = x.Level5;
                  // if (is_multi_punch && x.Level5 == '') {
                  //   x.MetricOption = '';
                  //   x.Level4 = '';
                  // } else if (is_multi_punch && x.Level5 !== '') {
                  //   x.MetricOption = x.Level5;
                  //   x.Level4 = '';
                  // } else if (is_multi_punch && x.Level6 !== '') {
                  //   x.MetricOption = x.Level6;
                  //   x.Level4 = x.Level5;
                  // } else {
                  //   x.Level4 = x.Level5;
                  // }
                  break;
                case 4:
                  x.Level5 = '';
                  // if (is_multi_punch && x.Level6 == '') {
                  //   x.MetricOption = '';
                  //   x.Level6 = '';
                  // } else if (is_multi_punch && x.Level6 !== '') {
                  //   x.MetricOption = x.Level6;
                  //   x.Level6 = '';
                  // } else {
                  //   x.Level5 = '';
                  // }
                  break;
                case 5:
                  x.Level6 = '';
                  break;
                case 6:
                  x.MetricOption = '';
                  break;
              }
            });
          }
          console.log(JsonData);

          this.variableTabData = JsonData;
          this.Loader.hideLoader();
          if (this.variableTabData.length > 0) {
            this.isAggregatedData =
              this.variableTabData[0].is_aggregated_data == 1 ? true : false;
            this.isSemiAggregatedData =
              this.variableTabData[0].is_semi_aggregated_data == 1
                ? true
                : false;
            this.BindVariableDataLeftPanel(this.variableTabData);
            this.crossTabService.setVariableTabData(this.variableTabData);
          }
        } else {
          this.errorPopup(result.ExceptionMessage);
          this.Loader.hideLoader();
        }
        //hide the loader
      });
  }

  BindVariableDataLeftPanel(Variableresult: any): void {
    this.variableList = [];
    this.isVariableTab = true;
    this.is_load_btn = false;
    this.isEntry_Tab = false;
    this.sidePanelblock = false;
    this.isEntry_Point = false;
    this.isStudyPanel = false;
    this.entry_items_expand = '';
    this.dimensionList = [];
    this.btnVisible.emit({
      save: true,
      load: true,
      custom_groups: this.isconfigCustomGrpPresent ? true : false,
      custom_calc: this.isconfigCustomCalcPresent ? true : false,
      create_table: true,
      weighted_btns: true,
    });
    this.entryListChange.emit(this.entryPanel);
    this.dataListChange.emit(this.dataArray);

    const result = _.sortBy(Variableresult, 'sort_id');
    this.dimensionList = _.uniq(
      _.map(
        _.filter(result, (item) => item.is_dimension),
        'Level0'
      )
    );
    this.dimensionListChange.emit(this.dimensionList);
    let matchFound;
    const Level0Variables = _.uniq(_.map(result, 'Level0'));
    for (let i = 0; i < Level0Variables.length; i++) {
      const level0 = Level0Variables[i];
      const listToUpdate = _.filter(
        result,
        (a) => a.Level0 === Level0Variables[i]
      );
      const Level1Variables = _.uniq(_.map(listToUpdate, 'Level1'));
      const level1Array = Level1Variables.map((level1) => {
        const filteredItems = _.filter(
          listToUpdate,
          (item) => item.Level1 === level1
        );
        const is_brand_analysis = _.map(filteredItems, 'is_brandanalysis')[0];
        const derived = _.uniq(
          _.map(filteredItems, 'is_derived_variable')
        ).toString();
        const calc = _.uniq(
          _.map(filteredItems, 'calculation_type')
        ).toString();
        const tooltip = _.uniq(_.map(filteredItems, 'tooltip')).toString();
        const data_variable_code = _.uniq(
          _.map(filteredItems, 'data_variable_code')
        ).toString();
        const response_code = _.uniq(
          _.map(filteredItems, 'response_code')
        ).toString();
        const sort_id = _.uniq(_.map(filteredItems, 'sort_id')).toString();
        const is_multi_punch = _.uniq(
          _.map(filteredItems, 'is_multi_punch')
        ).toString();
        const date = _.uniq(_.map(filteredItems, 'date')).toString();
        const year_code = _.uniq(_.map(filteredItems, 'year_code')).toString();
        const dimension = _.uniq(
          _.map(filteredItems, 'is_dimension')
        ).toString();
        const is_custom_group = _.uniq(
          _.map(filteredItems, 'is_CustomGroup')
        ).toString();
        const is_custom_calculation = _.uniq(
          _.map(filteredItems, 'is_CustomCalculation')
        ).toString();
        const customGroupQuery = _.uniq(
          _.map(filteredItems, 'customGroupQuery')
        ).toString();
        const customCalculationQuery = _.uniq(
          _.map(filteredItems, 'customCalculationQuery')
        ).toString();
        const customGroupId = _.uniq(
          _.map(filteredItems, 'customGroupId')
        ).toString();
        const customCalculationId = _.uniq(
          _.map(filteredItems, 'customCalculationId')
        ).toString();
        const dataYearCode = _.uniq(
          _.map(filteredItems, 'dataYearCode')
        ).toString();
        const is_time_Period_Present = _.uniq(
          _.map(filteredItems, 'is_time_Period_Present')
        ).toString();
        const Level2Variables = _.uniq(
          _.map(
            _.filter(
              result,
              (item) =>
                item.Level0 === level0 &&
                item.Level1 === level1 &&
                (item.MetricOption !== '' || item.Level2 !== '')
            ),
            'Level2'
          )
        );
        matchFound =
          this.isBrandAnalysis && Level0Variables[i] === 'Brands'
            ? true
            : this.entryPanel.some((entry) =>
              this.entryPanel.some(
                (listItem) => entry.Level1 === Level0Variables[i]
              )
            )
              ? true
              : false;
        return {
          Level1_value: level1,
          is_derived: derived,
          is_brand_analysis: is_brand_analysis,
          calc_type: calc,
          tooltip: tooltip,
          hasNextLevel: Level2Variables.length > 0,
          data_variable_code: data_variable_code,
          response_code: response_code,
          sort_id: sort_id,
          is_multi_punch: is_multi_punch,
          date: date,
          year_code: year_code,
          is_dimension: dimension,
          isCustomGrpPresent: is_custom_group,
          isCustomCalcPresent: is_custom_calculation,
          kibanaFilterScript:
            is_custom_group.toString() == 'true'
              ? customGroupQuery
              : is_custom_calculation.toString() == 'true'
                ? customCalculationQuery
                : '',
          dataYearCode: dataYearCode,
          is_time_Period_Present: is_time_Period_Present,
          customGroupId: customGroupId,
          customCalculationId: customCalculationId,
        };
      });

      this.variableList.push({
        Level0: level0,
        isEntryPoint:
          this.dataFormat == 'Respondent Level'
            ? matchFound == true
              ? true
              : false
            : true,

        Level1: level1Array,
        hasOnlyBrandIndependentChildren: level1Array
          .map((a) => a.is_brand_analysis)
          .every((a) => a === 0 || a === 7),
        hasOnlyBrandDependentChildren: level1Array
          .map((a) => a.is_brand_analysis)
          .every((a) => a !== 0 && a !== 7),
        hasSomeBrandDependentChildrenNotAll:
          level1Array
            .map((a) => a.is_brand_analysis)
            .some((a) => a !== 0 && a !== 7) &&
            level1Array
              .map((a) => a.is_brand_analysis)
              .some((a) => a === 0 || a === 7)
            ? true
            : false,
      });
    }

    console.log('variableList', this.variableList);
    console.log('is create custom calculation', this.isCreateCustomCalculation);
    this.dimensionList = _.uniq(
      _.map(
        _.filter(result, (item) => item.is_dimension == true),
        'Level0'
      )
    );
    this.dimensionListChange.emit(this.dimensionList);
    if (this.isBrandAnalysis) {
      this.brandDependentVariableList = this.variableList.filter(
        (a) =>
          a.hasOnlyBrandDependentChildren ||
          a.hasSomeBrandDependentChildrenNotAll
      );
      this.brandIndependentVariableList = this.variableList.filter(
        (a) =>
          a.hasOnlyBrandIndependentChildren ||
          a.hasSomeBrandDependentChildrenNotAll
      );
    }
    //let listToUpdate;
    //const Level0Variables = _.uniq(_.map(_.filter(result, item => item.Level0 !== null && item.Level0 !== ''), 'Level0'));
    //if (Level0Variables.length > 0) {
    //  for (let i = 0; i < Level0Variables.length; i++) {
    //    const level0 = Level0Variables[i];
    //    let matchFound = this.entryPanel.some((entry) =>
    //      this.entryPanel.some(
    //        (listItem) => entry.Level1 === Level0Variables[i]
    //      )
    //    );
    //    listToUpdate = result.filter((a) => a.Level0 === Level0Variables[i]);
    //    listToUpdate = _.sortBy(listToUpdate, 'sort_id');
    //    const Level1Variables = _.uniq(
    //      _.map(
    //        _.filter(
    //          listToUpdate,
    //          (item) =>
    //            item.Level0 !== null &&
    //            item.Level0 !== '' &&
    //            item.Level0 == Level0Variables[i] &&
    //            item.Level1 != '' &&
    //            item.Level1 != null
    //        ),
    //        'Level1'
    //      )
    //    );
    //    const level1Array: {
    //      Level1_value: string;
    //      is_derived: string;
    //      is_brand_analysis: number;
    //      calc_type: string;
    //      tooltip: string;
    //      hasNextLevel: boolean;
    //      data_variable_code: string;
    //      response_code: string;
    //      sort_id: string;
    //      is_multi_punch: string;
    //      date: string;
    //      year_code: string;
    //      is_dimension: string;
    //      isCustomGrpPresent: string;
    //      kibanaFilterScript: string;
    //      dataYearCode: string;
    //      is_time_Period_Present: string;
    //      customGroupId: string;

    //    }[] = [];
    //    for (let j = 0; j < Level1Variables.length; j++) {
    //      const level1 = Level1Variables[j];
    //      let is_derived = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'is_derived_variable'
    //        )
    //      );
    //      let calc_type = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'calculation_type'
    //        )
    //      );
    //      let tooltip_info = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'tooltip'
    //        )
    //      );

    //      let dvariable_code = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'data_variable_code'
    //        )
    //      );

    //      let r_code = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'response_code'
    //        )
    //      );

    //      let sorting_id = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'sort_id'
    //        )
    //      );

    //      let multi_punch = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'is_multi_punch'
    //        )
    //      );

    //      let dateVal = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'date'
    //        )
    //      );

    //      let y_code = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'year_code'
    //        )
    //      );

    //      let is_dimension = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'is_dimension'
    //        )
    //      );

    //      let is_custom_group = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'is_CustomGroup'
    //        )
    //      );

    //      let customGroupQuery = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'customGroupQuery'
    //        )
    //      );
    //      let customGroupId = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'customGroupId'
    //        )
    //      );

    //      let dataYearCodeVariable = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'dataYearCode'
    //        )
    //      );

    //      let istimePeriodPresent = _.uniq(
    //        _.map(
    //          _.filter(
    //            listToUpdate,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j]
    //          ),
    //          'is_time_Period_Present'
    //        )
    //      );

    //      let Level2Variables = _.uniq(
    //        _.map(
    //          _.filter(
    //            result,
    //            (item) =>
    //              item.Level0 === Level0Variables[i] &&
    //              item.Level1 == Level1Variables[j] &&
    //              (item.MetricOption != '' || item.Level2 != '')
    //          ),
    //          'Level2'
    //        )
    //      );
    //      const filteredItems = _.filter(
    //        listToUpdate,
    //        (item) =>
    //          item.Level0 === Level0Variables[i] &&
    //          item.Level1 == Level1Variables[j]
    //      );
    //      const is_brand_analysis = _.map(filteredItems, 'is_brandanalysis')[0];

    //      if (this.isBrandAnalysis && Level0Variables[i] === "Brands") {
    //        matchFound = true;
    //      }
    //      const derived = is_derived.toString();
    //      const calc = calc_type.toString();
    //      const tooltip = tooltip_info.toString();
    //      const data_variable_code = dvariable_code.toString();
    //      const response_code = r_code.toString();
    //      const sort_id = sorting_id.toString();
    //      const is_multi_punch = multi_punch.toString();
    //      const date = dateVal.toString();
    //      const year_code = y_code.toString();
    //      const dimension = is_dimension.toString();
    //      const custom_grp = is_custom_group.toString();
    //      const custmGrpQery = customGroupQuery.toString();
    //      const custmGrpId = customGroupId.toString();
    //      const dataYearCode = dataYearCodeVariable.toString();
    //      const is_time_Period_Present = istimePeriodPresent.toString();
    //      level1Array.push({
    //        Level1_value: level1,
    //        is_derived: derived,
    //        is_brand_analysis: is_brand_analysis,
    //        calc_type: calc,
    //        tooltip: tooltip,
    //        hasNextLevel: matchFound
    //          ? true
    //          : Level2Variables.length > 0
    //            ? true
    //            : false,
    //        data_variable_code: data_variable_code,
    //        response_code: response_code,
    //        sort_id: sort_id,
    //        is_multi_punch: is_multi_punch,
    //        date: date,
    //        year_code: year_code,
    //        is_dimension: dimension,
    //        isCustomGrpPresent: custom_grp,
    //        kibanaFilterScript: custmGrpQery,
    //        dataYearCode: dataYearCode,
    //        is_time_Period_Present: is_time_Period_Present,
    //        customGroupId: custmGrpId
    //      });
    //    }
    //    this.variableList.push({
    //      Level0: level0,
    //      isEntryPoint: matchFound == true ? true : false,
    //      Level1: level1Array,
    //    });
    //  }
    //}
  }

  IsDimension(parentOption: any) {
    return this.dimensionList.includes(parentOption);
  }

  ClearAll(): void {
    this.isVariableTab = false;
    this.is_load_btn = true;
    this.isClearEverything.emit();
  }

  // Listen for click events on the document.
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    // Check if the click event occurred outside the <div> block.
    if (!this.isClickInside(event, '.sidePanelblock')) {
    }
  }

  private isClickInside(event: Event, className: string): boolean {
    const elements = document.querySelectorAll(className);
    for (let i = 0; i < elements.length; i++) {
      const container = elements[i];
      if (container.contains(event.target as Node)) {
        return true;
      }
    }
    return false;
  }

  selectAll(Parent: string, Selection: string) {
    this.isSelectAll = !this.isSelectAll;
    let selectedList: any[] = [];
    let callback: ((item: any) => void) | undefined = undefined;
    selectedList = this.entryPanel_Lvl3;
    callback = (item) =>
      this.checkBoxClick(
        0,
        item.Level,
        '',
        item.Level1,
        item.Level2,
        item.MetricOptions,
        item.variableCode,
        item.responseCode,
        item.mandatory_variable_mapping,
        this.isSelectAll,
        item.e1_variable_code,
        item.e2_variable_code,
        item.e3_variable_code,
        item.dimension
      );
    if (selectedList && callback) {
      this.processSelectedList(selectedList, callback);
    }
  }
  private processSelectedList(
    selectedList: any[],
    callback: (item: any) => void
  ): void {
    for (const item of selectedList) {
      callback(item);
    }
  }
}
