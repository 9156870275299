import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InsightspopupService } from '../../services/insightspopup.service';
import { InsightsModel } from '../../models/common.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-insights-popup',
  templateUrl: './insights-popup.component.html',
  styleUrls: ['./insights-popup.component.css']
})
export class InsightsPopupComponent {
  headerTitle:string="Error";
  messageText:string="Client name is required";
  buttonText:string="Ok"
  showError:boolean=false;
  $unSubscribeInsightsDAta! : Subscription;

  constructor(private _insightsService:InsightspopupService){

  }
  ngOnInit() {
    this.$unSubscribeInsightsDAta! = this._insightsService.isInsights$.subscribe((insights:InsightsModel) => {
      this.showError = insights.show;
      this.headerTitle=insights.header;
      this.messageText=insights.message
    });
  }
  ngOnDestroy(){
    if (this.$unSubscribeInsightsDAta) {
      this.$unSubscribeInsightsDAta.unsubscribe();
    }
  }
  close(){
    this.showError=false;
  }
 
}
