<div class="popup-container" *ngIf="showError">
    <div class="popup-model">
        <div class="popup-header">
            <div class="popup-header-text">{{headerTitle}}</div>
            <div class="popup-close" (click)="close()" title="Close"></div>
        </div>
        <div class="popup-body">
            <div class="message-block"[ngStyle]="{
                display:customcss(messageText),'height':heightValue
              }">
                {{messageText}}
            </div>
        </div>
        <div class="popup-footer">
            <div class="popup-btn" (click)="onOkBtnClick()">
                <div class="btn-icon check-circle-icon"></div>
                <div class="btn-text">{{buttonText}}</div>
            </div>
        </div>
    </div>
</div>