import { Component, OnDestroy, OnInit, Inject, HostListener } from '@angular/core';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';

import { HttpClient } from '@angular/common/http';
import { filter, switchMap, of, takeUntil, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from './common/services/authentication.service';
import { results } from './common/models/common.model';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private readonly _destroying$ = new Subject<void>();
  loginDisplay: boolean = false;
  isAuthenticated: boolean = false;
  lastPing: Date = new Date();
  idleState = 'Not started.';
  timedOut = false;
 
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private httpClient: HttpClient, private router: Router,
    private _authenticateService: AuthenticationService, private idle: Idle, private keepalive: Keepalive) {
   idle.setIdle(1200); // this will be 58*60

    //idle.setIdle(60);
    idle.setTimeout(5); //counter time.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      //this.idleState = 'No Longer Idle';
      //alert(this.idleState);
    });// this is the palce to do somthing if user comes back after being idle.

    idle.onTimeout.subscribe(() => {
     // this.idleState = 'Timed out!';
      this.timedOut = true;
      // alert(this.idleState);
      this._authenticateService.logout();
      //this._authenticateService.loginRedirect();
     // this.authService.log();
    });// this is place for redirect to login page.

    idle.onIdleStart.subscribe(() => {
      //this.idleState = 'You have gone Idle';
      //alert(this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      //this.idleState = 'You will time out in ' + countdown + 'seconds';

    }); // this is the place for alert to notify of the logout

    keepalive.interval(15);

    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
    });
    this.reset();
  }

  ngOnInit() {


    //this._authenticateService.startSessionTimeoutTimer(); // Initialize the session timeout timer

    //// Add event listeners for mousemove and keydown to reset the session timeout timer
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
      // this.authService.handleRedirectObservable().subscribe((response) => {
      //   // Check if the response contains authentication token
      //   if (response && response.idToken) {
      //     // Call API immediately after successful login
      //     this._authenticateService.AuthenticateUsers().subscribe((result:results) => {
      //       // checking response is valid
      //       if (result.HasValidData) {
              
      //       }
      //     });
      //   }
      // });
  }
  //ngOnDestroy() {
  //  // Remove event listeners when the component is destroyed
  //  document.removeEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
  //  document.removeEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
  //}
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {

    //this._authenticateService.resetSessionTimeoutTimer();
    //Check if the click event occurred outside the <div> block.
   // document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
  }

  title = 'CrossTabUIApp';

  reset() {

    this.idle.watch();
    this.idleState = 'Started';
    this.timedOut = false;
  }
}
