import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as Highcharts from 'highcharts';

import { HttpClient } from '@angular/common/http';
import { BarServiceService } from './bar-service.service';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { COMMON_CONSTANTS } from '../../constants/common-constants';
import Stock from 'highcharts/modules/stock';
import { Subscription } from 'rxjs';

Stock(Highcharts);

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class Barchart01Component implements OnInit {
  LowBaseSample = COMMON_CONSTANTS.LowBase_Sample;
  InsufficientBaseSample = COMMON_CONSTANTS.InsufficientBase_Sample;
  @Input() insufficientBase: string = '';
  @Input() LowBase: string = '';
  DefaultConfidenceLevel: string = '95%';
  Sig_LowerLimt = COMMON_CONSTANTS.SigLowerLimit_95;
  Sig_UpperLimt = COMMON_CONSTANTS.SigUpperLimit_95;
  chart: any;
  event: any;
  datalabel: any = false;
  barChartData: any;
  Highcharts: typeof Highcharts = Highcharts;
  seriesData: any = [];
  changeValue: any;
  properties: any;
  changeValueSeries: any;
  significance: any;
  sampleSize: any;
  data: any;
  defaultCategories1: any;
  max: any;
  min: any;
  public seriesData1: any[] = [];
  jsonData: any;
  categories: any;
  labels: any;
  significance2: any;
  categoriesList: any;
  selectedColor: any = "#ff0000";
  colorCodeList: any[] = [];
  //ColorCodes: string[] = ['#802AB7', '#00B6FF', '#2C70A3', '#802A71', '#EFB467', '#47B7AE', '#F9C099', '#2A80B7', '#B78000', '#2A80FF', '#00C7E8', '#1B4C96', '#571F8A', '#077A99', '#00C7E8'];
  NewColorCodes!: string[];
  chartproperties = [
    {
      "showLegend": true,
      "gridlines": false,
      "shadow": false,
      "darkTheme": false,
      "legendPosition": "bottom",
      "axisLabelOrientation": "horizontal",
      "chartTitlePosition": "center",
      "showDataLabel": true,
      "datalabelStyle": "significance + changeVs",
      "axisImage": false,
      "dataLabelPosition": "center",
      "dataLabelColor": "black",
      "marker": "disable",
      "showRemaining": false,
      "gradientBarColor": false,
      "dataLabelPositionPPT": "center",
      "datalabelFormat": "percentage",
      "markerStyle": "circle",
      "showPattern": false,
      "toolTipStyle": "default"
    }
  ];

  subscription!: Subscription; // To hold the subscription

  constructor(private http: HttpClient, private barService: BarServiceService, private dataSharingService: CrosstabService) { }
  ngOnInit(): void {
    this.LowBaseSample = this.LowBase == "" ? 0 : Number(this.LowBase);
    this.InsufficientBaseSample = this.insufficientBase == "" ? 0 : Number(this.insufficientBase);

    this.dataSharingService.ChartDataSubject$.subscribe(({ data, listOfCategory, Significance, ConfidenceLevel, numberType, chartType }) => {

      this.properties = this.chartproperties[0];
      if (Significance == "None") {
        this.properties.datalabelStyle = '';
      }
      else {
        this.properties.datalabelStyle = 'change';
        this.DefaultConfidenceLevel = ConfidenceLevel;
      }
      if (numberType == "mean") {
        this.properties.datalabelFormat = '';
      }
      //if (chartType == "bar") {
      //  this.properties.dataLabelPosition = 'right';
      //}

         // Call the service to get the color palette
         this.subscription  = this.dataSharingService.colorPalette$.subscribe((result:any) => {
          this.NewColorCodes = result; // Assign the color palette from the service
         
        });

      this.jsonData = data;
      this.data = data;//data[0];
      this.barChartData = data.Data;
      this.categoriesList = listOfCategory;
      this.colorCodeList = this.NewColorCodes;
      this.getData(this.jsonData, this.properties.datalabelStyle == "ls", chartType);
      this.getBarChart('vertical-chart-container', this.seriesData1, this.properties, chartType);
    });

    //this.http.get<any>('assets/Data/barData_Vertical.json').subscribe((data: any) => {
    //  this.jsonData = data;
    //  this.data = data[0];
    //  this.barChartData = data[0]["data"];
    //  this.properties = this.chartproperties[0];
    //  this.getData1(this.jsonData, this.properties.datalabelStyle == "ls");
    //  this.getBarChart1('chart-container', this.seriesData1, this.properties);
    //});
  }



  getData(chartData: any, lowSample1: any, chartType: string) {
    let seriesData: any[] = [];
    let IB = this.InsufficientBaseSample;
    let value = 0;
    let i = 0;
    let maxValue = 0;
    let minValue = 0;
    const firstItem = chartData.Data[0]['Series Name'];//chartData.Data[0]['Series Name'] //chartData[0].data[0]['Series Name']

    const indiaData = chartData.Data.filter((item: { [x: string]: string }) => item['Series Name'] === firstItem); //chartData[0].data.filter((item: { [x: string]: string }) => item['Series Name'] === firstItem);
    this.categories = Array.from(new Set(indiaData.map((item: { [x: string]: string }) => item['Category Name'])));
    maxValue = Math.max(...indiaData.map((item: { [x: string]: string }) => parseFloat(item['Rounded Value']) || 0));
    minValue = Math.min(...indiaData.map((item: { [x: string]: string }) => parseFloat(item['Rounded Value']) || 0));
    this.max = maxValue + Math.ceil(maxValue * 0.20);
    this.min = 0;
    this.seriesData1 = indiaData.map((item: { [x: string]: string }) => ({

      value: parseFloat(item['Rounded Value']) === 0 ? 0 : parseFloat(item['Rounded Value']) || 0.000001,
      numericValue: value,
      name: item['Category Name'],
      seriesName: item['Series Name'],

      color: this.colorCodeList[i++],
      visible: true,
      zIndex: 2,
      type: chartType,
      dataLabels: {
        //y: this.getDataLabelY(parseFloat(item['Rounded Value']) == 0 || parseFloat(item['Rounded Value']) == null ? 0 : parseFloat(item['Rounded Value'])),
        borderWidth: 0,

      },
      data: [{
        name: item['Series Name'],

        y: parseFloat(item['Rounded Value']) === 0 ? 0 : parseFloat(item['Rounded Value']) || 0.000001,
        x: parseInt(item['XValue']),

      }],
      changeValue: parseFloat(item["Change value vs PY"]),
      Significance: parseFloat(item["Significance"]),
      samplesize: parseFloat(item["Sample size"]),
    }));

    this.changeValueSeries = indiaData.map((item: { [x: string]: string }) => parseFloat(item["Change value vs PY"]));
    this.sampleSize = indiaData.map((item: { [x: string]: string }) => parseFloat(item["Sample size"]));
    this.significance = indiaData.map((item: { [x: string]: string }) => parseFloat(item["Significance"]));
    this.significance2 = indiaData.map((item: { [x: string]: string }) => parseFloat(item["Significance2"]));
    this.labels = indiaData.map((item: { [x: string]: string }) => parseFloat(item["Label"]));

    // Scatter series (Marker1) - Limit to 1 data point
    this.seriesData1.push({
      name: 'Marker1',
      type: 'scatter',
      zIndex: 1,
      marker: {
        symbol: this.properties.markerStyle,
        fillColor: 'red',
        lineColor: 'red',

        lineWidth: 2,
      },
      showInLegend: false,
      tooltip: {
        enabled: false // Disable tooltip for this series
      },
      enableMouseTracking: false,
      visible: this.properties.marker == "enable" ? true : false,

      data: indiaData.map((item: { [x: string]: string }) => ({
        name: item['Category Name'],

        y: parseFloat(item['Rounded Value']) || 0.000001,
        x: parseInt(item['XValue']),
        z: parseInt(item['Value']),

      })),


    });


  }



  patternselect(ispattern: boolean) {
    this.properties.showPattern = ispattern
    //this.getData(this.jsonData, this.properties.datalabelStyle == "ls");
    this.getBarChart('vertical-chart-container', this.seriesData1, this.properties, "");
    ;
  }
  getColor(): string {
    const color = this.barService.colors[this.barService.colorIndex];
    this.barService.colorIndex = (this.barService.colorIndex + 1) % this.barService.colors.length; // Cycle through colors
    return color;
  }
  getDataLabelY(numericValue: number): number {

    return numericValue > 0 ? (numericValue - 50) : -20;
  }
  getPattern(): string {
    const color = this.barService.patterns[this.barService.colorIndex];
    this.barService.colorIndex = (this.barService.colorIndex + 1) % this.barService.patterns.length; // Cycle through colors
    return color;
  }

  customization() {
    return this.barService.customizationService()

  }

  commonfunc(event: any, customization: string, options: any) {
    let property;
    this.event = event;
    this.barService.commonFunc(customization, event, this.properties)
    // this.getData(this.jsonData, this.properties.datalabelStyle == "ls");
    this.getBarChart('vertical-chart-container', this.seriesData1, this.properties, "");
  }
  getBarChart(containerId: string, seriesData: any[], properties: any, chartType: string) {
    localStorage.setItem("properties", JSON.stringify(this.properties));

    let labels = this.labels;
    let serviceBar = this.barService;
    const plot = this.barService.plot;
    const plotDark = this.barService.plotDark;
    let change = this.changeValueSeries;
    let signi = this.significance;
    let signi2 = this.significance2;
    let c = 0;
    let samplesize = this.sampleSize;
    const updatedCategories = this.barService.updatedCategories;

    const defaultCategories = this.categoriesList;
    const maxCatrgories = chartType == "bar" ? (this.seriesData.length >= 6 && this.categoriesList.length >= 3) ? 1 : (this.seriesData.length >= 3 && this.categoriesList.length >= 3) ? 1 : (this.categoriesList.length > 5 ? 4 : this.categoriesList.length - 1) : (this.categoriesList.length > 5 ? 4 : this.categoriesList.length - 1);

    const isScrollEnabled = chartType == "bar" ? (this.seriesData.length >= 6 && this.categoriesList.length >= 3) ? true : (this.seriesData.length >= 3 && this.categoriesList.length >= 3) ? true : (this.categoriesList.length > 5 ? true : false) : (this.categoriesList.length > 5 ? true : false);
    let InsufficientBase = this.InsufficientBaseSample;
    let lowSample = this.LowBaseSample;
    this.chart = Highcharts.chart(containerId, {
      chart: {
        type: chartType,
        backgroundColor: this.properties.darkTheme ? this.barService.darkThemeBackgroundColor : this.barService.defaultThemeBackgroundColor,
        style: {
          color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
        },
      },
      title: {
        text: '',
        align: this.properties.chartTitlePosition,
        style: {
          color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
        },
      },
      subtitle: {

      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category',
        categories: defaultCategories,
        //plotBands: this.properties.darkTheme ? plotDark : plot,
        min: 0,
        max: maxCatrgories,

        scrollbar: {
         //enabled: defaultCategories.length > 5 ? true : false,
         enabled: isScrollEnabled,
         height: 6, // Adjust the height of the scrollbar as needed
         barBackgroundColor: '#802ab7',
         barBorderRadius: 5,
         barBorderWidth: 0,
         barBorderColor: 'white',
         buttonBackgroundColor: 'white',
         buttonBorderWidth: 0,
         buttonArrowColor: 'white',
         buttonBorderRadius: 0,
         rifleColor: 'none',
         trackBackgroundColor: 'white',
         trackBorderWidth: 0,
         trackBorderColor: 'black',
         trackBorderRadius: 5
        },
        showEmpty: false,
      },
      yAxis: {
        // visible: this.properties.gridlines,
        scrollbar: {
          enabled: true,
          showFull: false, // Set to false if you want to see only part of the scrollbar initially
        },
        visible: false,
        max: this.max,
        min: this.min,
        plotLines: [{
          value: 0,
          width: 1
        }],
        gridLineWidth: this.properties.gridlines ? 1 : 0,
        //  tickInterval: 10, // Set the interval between ticks to 10 units
        accessibility: {
          rangeDescription: 'Range: 0 to 100' // Update the accessibility range description
        },
        labels: {
          style: {
            color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
          },
        },
        title: {
          text: '', // Set an empty string to hide the yAxis title
        },
      },
      legend: this.barService.customizeLegend(this.chart, this.properties.showLegend, this.properties.legendPosition, this.properties),
      plotOptions: {
        //series: {
        //  borderWidth: 0,
        //  dataLabels: {
        //    enabled: true,
        //    format: '{point.y:.1f}%'
        //  }
        //}

        series: {
          animation: true,

        },
        column: {
          pointPadding: 2,
          groupPadding: 3,
          grouping: false,
          stacking: undefined,

          shadow: this.properties.shadow ? {
            color: this.barService.shadowColor,
            offsetX: 1,
            offsetY: 1,
            opacity: 0.5,
            width: 6,
          } : false,
          pointWidth: 35,
          // dataLabels: this.barService.customizeDataLabel(this.properties, this.seriesData, change, signi, samplesize, serviceBar),

          dataLabels: {

            style: {
              backgroundColor: this.barService.transparentBackground,

              textOutline: 'none',

              color: 'black',

            },


            enabled: true,

            inside: false,

            rotation: this.properties.datalabelOrientation,

            align: this.properties.dataLabelPosition == 'center' ? 'left' : this.properties.dataLabelPosition,

            formatter: function (this: any) {

              if (properties.datalabelStyle == "change") {
                this.Sig_LowerLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigLowerLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigLowerLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigLowerLimit_98
                        : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigUpperLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigUpperLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigUpperLimit_98
                        : COMMON_CONSTANTS.SigUpperLimit_95;
                let text = this.y == 0.000001 ? 'NA' : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));

                let changevspy = (isNaN(this.series.userOptions.changeValue) ? "(NA)" : ('(' + this.series.userOptions.changeValue + (properties.datalabelFormat == "percentage" ? '%' : '') + ')'));
                let sig = this.series.userOptions.Significance;

                if (text == 'NA') {
                  return (
                    '<span >' + text + '</span>'
                  );
                }
                if (changevspy == "(NA)") {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }
                if (sig < this.Sig_LowerLimt) {
                  return (
                    '<span>' + text + '<span  style="color: red;">' + changevspy + '</span></span>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                    '<span>' + text + '<span  style="color: green;">' + changevspy + '</span></span>'
                  )
                }
                else {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }

              }

              return ((this.series.userOptions.samplesize != 0 && this.series.userOptions.samplesize < InsufficientBase) ? 'I/S' : this.y == 0.000001 ? 'NA' : (this.series.userOptions.samplesize != 0 && (this.series.userOptions.samplesize < lowSample && this.series.userOptions.samplesize > InsufficientBase)) ? (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) : (this.y + (properties.datalabelFormat == "percentage" ? '%' : '')));

            },
          },
        },
        bar: {
          pointPadding: 2,
          groupPadding: 3,
          grouping: false,
          stacking: undefined,

          shadow: this.properties.shadow ? {
            color: this.barService.shadowColor,
            offsetX: 1,
            offsetY: 1,
            opacity: 0.5,
            width: 6,
          } : false,
          pointWidth: 35,
          // dataLabels: this.barService.customizeDataLabel(this.properties, this.seriesData, change, signi, samplesize, serviceBar),

          dataLabels: {

            style: {

              backgroundColor: this.barService.transparentBackground,

              textOutline: 'none',

              color: 'black',

            },


            enabled: true,

            inside: false,

            rotation: this.properties.datalabelOrientation,

            align: 'left',


            // x: this.properties.datalabelOrientation == 90 ? -10 : (this.properties.dataLabelPosition == 'center' || this.properties.dataLabelPosition == 'left' || this.properties.dataLabelPosition == 'right') && this.properties.marker ? 7 : -10,
            //for left and right keep it different
            formatter: function (this: any) {

              if (properties.datalabelStyle == "change") {
                this.Sig_LowerLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigLowerLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigLowerLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigLowerLimit_98
                        : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigUpperLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigUpperLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigUpperLimit_98
                        : COMMON_CONSTANTS.SigUpperLimit_95;
                let text = this.y == 0.000001 ? 'NA' : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));

                let changevspy = (isNaN(this.series.userOptions.changeValue) ? "(NA)" : ('(' + this.series.userOptions.changeValue + (properties.datalabelFormat == "percentage" ? '%' : '') + ')'));
                let sig = this.series.userOptions.Significance;

                if (text == 'NA') {
                  return (
                    '<span >' + text + '</span>'
                  );
                }
                if (changevspy == "(NA)") {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }
                if (sig < this.Sig_LowerLimt) {
                  return (
                    '<span>' + text + '<span  style="color: red;">' + changevspy + '</span></span>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                    '<span>' + text + '<span  style="color: green;">' + changevspy + '</span></span>'
                  )
                }
                else {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }

              }
              return this.series.userOptions.samplesize < InsufficientBase ? 'I/S' : this.y == 0.000001 ? 'NA' : (this.series.userOptions.samplesize < lowSample && this.series.userOptions.samplesize > InsufficientBase) ? (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));

            },
          },
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        //pointFormat: '<span>{point.name}</span>: <b>{point.y:.2f}%</b><br/>'

        formatter: function (this: any) {
          return '<span style="font-size:11px">' + this.series.userOptions.name + '</br>' +
            '<span>' + this.key + '</span>: <b>' +
            (this.series.userOptions.samplesize < InsufficientBase ? 'I/S' :
              this.y == 0.000001 ? 'NA' :
                (this.series.userOptions.samplesize < lowSample && this.series.userOptions.samplesize > InsufficientBase) ?
                  (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) :
                  (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''))) +
            '</b>' +
            '</span>';
        }
      },
      series: seriesData,
    })
    //this.chart = Highcharts.chart(containerId, {
    //  chart: {
    //    type: 'column',

    //    backgroundColor: this.properties.darkTheme ? this.barService.darkThemeBackgroundColor : this.barService.defaultThemeBackgroundColor,
    //    style: {
    //      color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
    //    },
    //  },
    //  title: {
    //    text: this.data.title,
    //    align: this.properties.chartTitlePosition,
    //    style: {
    //      color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
    //    },
    //  },
    //  subtitle: {


    //  },
    //  exporting: {
    //    enabled: false,
    //  },
    //  credits: {
    //    enabled: false
    //  },

    //  legend: this.barService.customizeLegend(this.chart, this.properties.showLegend, this.properties.legendPosition, this.properties),
    //  xAxis: {

    //    categories: defaultCategories,
    //    //plotBands: this.properties.darkTheme ? plotDark : plot,
    //    min: 1,
    //    max: this.categories.length,
    //    showEmpty: false,
    //    //labels: {

    //    //  useHTML: true,
    //    //  style: {
    //    //    color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
    //    //  },
    //    //  //formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {

    //    //  //  if (properties.axisImage) {
    //    //  //    const index = defaultCategories.indexOf(String(this.value));
    //    //  //    const category = updatedCategories[index];

    //    //  //    if (category && category.image) {
    //    //  //      return '<span style="display: inline-block; width: 20px; height: 20px; background: url(' + category.image + ') no-repeat center center; background-size: cover;"></span>';
    //    //  //    } else {
    //    //  //      return '<span>No Image</span>';
    //    //  //    }
    //    //  //  } else {
    //    //  //    return String(this.value);
    //    //  //  }
    //    //  //},

    //    //  rotation: this.barService.axisLabelOrientation(this.properties.axisLabelOrientation), // Rotate labels by -90 degrees (or any other desired angle)
    //    //}
    //  },
    //  yAxis: {
    //    // visible: this.properties.gridlines,
    //    max: this.max,
    //    min: this.min,
    //    plotLines: [{
    //      value: 0,
    //      width: 1
    //    }],
    //    gridLineWidth: this.properties.gridlines ? 1 : 0,
    //    //  tickInterval: 10, // Set the interval between ticks to 10 units
    //    accessibility: {
    //      rangeDescription: 'Range: 0 to 100' // Update the accessibility range description
    //    },
    //    labels: {
    //      style: {
    //        color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
    //      },
    //    },
    //    title: {
    //      text: '', // Set an empty string to hide the yAxis title
    //    },
    //  },
    //  tooltip: {
    //    enabled: true,
    //    borderWidth: 0,
    //    useHTML: true,
    //    shape: properties.toolTipStyle == "style1" ? 'rect' : 'callout',
    //    shadow: false,
    //    backgroundColor: 'rgba(0,0,0,0)',

    //    formatter: function () {
    //      if (this.series.name === 'Marker1') return false;
    //      let isDefaultTooltip = properties.toolTipStyle;
    //      console.log("tooltip", isDefaultTooltip)
    //      if (isDefaultTooltip == "style1") {

    //        const commonContent =
    //          `<div style="border-left: 5px solid lightblue; border-bottom: 5px solid lightblue; z-index: 100; padding: 5px; border-radius: 5px; margin: 0px; background-color: white;">` +
    //          `<span style="display: block; text-align: center; color: lightblue; font-weight: bold; font-size: 12px;">Tooltip Title</span>` +
    //          `<span><strong>Series Name:</strong></span> ${this.point.name}<br>` +
    //          `<span><strong>Category Name:</strong></span> ${this.series.name}<br>` +
    //          `<span><strong>Value:</strong></span> ${this.point.y}`;



    //        return commonContent + '</div>';
    //      }
    //      else {
    //        const dotColor = this.series.color; // Get the series color

    //        const content =
    //          `<div style="background-color: #f5f5f5; padding: 5px; align-items: center; border: 1px solid ${dotColor}; ">` +
    //          `<div style="width: 10px; height: 10px; background-color: ${dotColor}; border-radius: 50%; margin-right: 5px;"></div>` +
    //          ` ${this.series.name}` +
    //          `<div style="display: block; margin-top: 5px;"><strong>Value:</strong> ${this.point.y}</div>`;

    //        return content + '</div>';

    //      }




    //    },
    //  },

    //  plotOptions: {
    //    series: {
    //      animation: true,

    //    },
    //    column: {
    //      pointPadding: 2,
    //      groupPadding: 3,
    //      grouping: false,
    //      stacking: undefined,

    //      shadow: this.properties.shadow ? {
    //        color: this.barService.shadowColor,
    //        offsetX: 1,
    //        offsetY: 1,
    //        opacity: 0.5,
    //        width: 6,
    //      } : false,
    //      pointWidth: 25,
    //      // dataLabels: this.barService.customizeDataLabel(this.properties, this.seriesData, change, signi, samplesize, serviceBar),

    //      dataLabels: {

    //        style: {

    //          backgroundColor: this.barService.transparentBackground,

    //          textOutline: 'none',

    //          color: this.properties.darkTheme ? (this.properties.dataLabelColor === 'default' ? 'white' : this.properties.dataLabelColor || this.barService.darkThemeTextColor)
    //            : (this.properties.dataLabelColor === 'default'
    //              ? 'black'
    //              : this.properties.dataLabelColor || this.barService.defaultThemeTextColor),

    //        },


    //        enabled: this.properties.showDataLabel,

    //        inside: false,

    //        rotation: this.properties.datalabelOrientation,

    //        align: this.properties.dataLabelPosition,

    //        formatter: function (this: Highcharts.PointLabelObject) {


    //          if (properties.datalabelStyle == "significance + changeVs") {

    //            let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + '(' + change[c] + ')' : 'NA';
    //            let sig = signi[c++];
    //            let s = sig > 0 ? serviceBar.upwardTriangle : (sig < 0 ? serviceBar.downwardTriangle : '');
    //            let sigcolor = sig > 0 ? serviceBar.highSignificance : (sig < 0 ? serviceBar.lowSignificance : '');
    //            return (
    //              '<span >' + text + '<span style="color:' + sigcolor + ';">' + s + '</span></span>'
    //            );
    //          }
    //          if (properties.datalabelStyle == "change") {
    //            let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + '(' + change[c++] + ')' : 'NA';
    //            return (
    //              '<span >' + text + '</span>'
    //            );

    //          }
    //          if (properties.datalabelStyle == "ls") {


    //            let text = parseFloat(samplesize[c++]) > 9000 ? this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') : 'NA' : 'ls';
    //            return (
    //              '<b>' +
    //              text +
    //              '</b> ' +
    //              '<span style="opacity: 0.5"></span>'
    //            );
    //          }
    //          if (properties.datalabelStyle == "significance") {

    //            let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') : 'NA';
    //            let sig = signi[c++];
    //            let s = sig > 0 ? serviceBar.upwardTriangle : (sig < 0 ? serviceBar.downwardTriangle : '');
    //            let sigcolor = sig > 0 ? serviceBar.highSignificance : (sig < 0 ? serviceBar.lowSignificance : '');
    //            return (
    //              '<span >' + text + '<span style="color:' + sigcolor + ';">' + s + '</span></span>'
    //            );

    //          }
    //          if (properties.datalabelStyle == "datalabel") {
    //            let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') : 'NA';
    //            return (
    //              '<span >' + text + '</span>'
    //            );
    //          }

    //          if (properties.datalabelStyle == "significance2") {
    //            let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') : 'NA';
    //            let changeText = change[c];
    //            let sig = signi[c];
    //            let sig1 = signi2[c];
    //            let sColor = sig > 0 ? 'green' : (sig < 0 ? 'red' : '');
    //            let s = sig > 0 ? serviceBar.upwardTriangle : (sig < 0 ? serviceBar.downwardTriangle : '');
    //            let changeColor = sig1 < 0 ? 'red' : (sig1 > 0 ? 'green' : 'black');
    //            c++;

    //            if (parseFloat(changeText) > 0) {
    //              changeText = "+" + changeText;
    //            }

    //            return (
    //              '<span>' + text +
    //              (this.y != 0 && changeText ? '&nbsp<span style="color:' + ';"> (' +
    //                '<span style="font-size: 80%; color:' + changeColor + ';">' + changeText + '</span>)&nbsp' +
    //                '<span style="color:' + sColor + ';">' + s + '</span>' : '') +
    //              '</span>'
    //            );
    //          }

    //          return this.y != 0 ? this.y : 'NA';

    //        },
    //      },
    //    },
    //  },
    //  series: seriesData,
    //});



  }



  userselectedproperties() {

    this.data["properties"][0] = this.properties;

    return JSON.stringify(this.data).toString();
  }

  index = -1;

  ngOnDestroy(): void {
    // Unsubscribe when the component is destroyed to prevent memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }




}





