<div class="container">
    <div class="header">
      <img src="assets/imageChatbot/Bot.svg" alt="Kantar Co-Pilot" class="robot-image" />
      <h1>Meet Your New </h1>
      <img src="assets/imageChatbot/Kantar Co-Pilot.svg" alt="Kantar Co-Pilot" class="Kantar-text-image" />
    </div>
    <div class="content-background ">
      <div class="help-text">How can I help you?</div>
      <div class="search-bar">
        <label for="search-input">
          <input id="search-input" type="text" placeholder="Ask something..." />
        </label>
        <button>
          <img src="assets/imageChatbot/send_icon.svg" alt="send_icon" class="Kantar-send-icon">
        </button>
      </div>
    </div>
  </div>
  
