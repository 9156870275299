import { Injectable } from '@angular/core';
import { CrosstabService } from '../../services/crosstab.service';

@Injectable({
  providedIn: 'root'
})
export class StackchartServiceService {
  custom: any;
  customColors: string[] = [];

  constructor(private colorService: CrosstabService) {
    this.colorService.colorPalette$.subscribe(colors => {
      this.customColors = colors;  // Store the latest value
    });
   }
  //customColors =['#802AB7', '#00B6FF', '#2C70A3', '#802A71', '#EFB467', '#47B7AE', '#F9C099', '#2A80B7', '#B78000', '#2A80FF', '#00C7E8', '#1B4C96', '#571F8A', '#077A99', '#00C7E8'];

  customColors1 = this.customColors.map((color, index) => {
    const darkerShade = this.getDarkerShade(color);
    return {
      darkerShade: darkerShade,
      linearGradient: { x1: 0.4, x2: 0.5, y1: 0, y2: 0 },
      stops: [
        [0, color],
        [1, darkerShade]
      ]
    };
  });

  PatterncustomColors = ['url(#highcharts-default-pattern-1)', 'url(#highcharts-default-pattern-3)'];
  plotBandsColor = '#DCDCDC';//'#F0F0F0';
  darkThemebg = '#333';
  lightThemebg = 'white';
  plotBandsdark = '#4f4f4f';
  darkThemeTextColor = 'white';
  lightThemeTextColor = 'black';
  textfont = 'Kantar Regular';
  upwardTriangle = '&#9650';
  downwardTriangle = '\u25BC';

  updatedCategories = [
    { name: 'Brand 1', image: 'https://img.freepik.com/premium-vector/highly-detailed-vietnam-map-with-borders-isolated-background_599062-8131.jpg' },
    { name: 'Brand 2', image: 'https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX7783047.jpg' },
    { name: 'Brand 3', image: 'https://th.bing.com/th/id/OIP.gxQmRFM-PSHbekk72mukxwHaH_?rs=1&pid=ImgDetMain' },
    { name: 'Brand 4', image: 'https://th.bing.com/th/id/R.4abe7f8a1272b46cb5d1e67d4341b6ec?rik=lW0lyg9V5L%2b7UQ&riu=http%3a%2f%2fwww.mapsof.net%2fuploads%2fstatic-maps%2feuropecontour.png&ehk=aDTomnr7%2bgplizpCBegO2ztJrXsGolh7sWqvGkqOQAI%3d&risl=&pid=ImgRaw&r=0' },
    { name: 'Brand 5', image: 'https://media.istockphoto.com/vectors/black-silhouette-country-borders-map-of-india-on-white-background-of-vector-id955286158?k=6&m=955286158&s=170667a&w=0&h=zeBvYe1ttcA8HTslBhAfLRfzS0q2D3o7LQ9Jtp8ift4=' },
    { name: 'Brand 6', image: 'https://th.bing.com/th/id/OIP.xB_qm1E9-36RTaYXsankpgAAAA?rs=1&pid=ImgDetMain' },
    { name: 'Brand 7', image: 'https://th.bing.com/th/id/OIP.Goh-tNxcyXD550sCNWLfWwHaFu?rs=1&pid=ImgDetMain' },
    { name: 'Brand 8', image: 'https://media.istockphoto.com/vectors/black-map-of-africa-vector-id538983577?k=6&m=538983577&s=170667a&w=0&h=kNULQNQDiGAGXAHDgQktQZlmDx96bqip3Eb-5Z-z6sA=' },
    { name: 'Brand 9', image: 'https://media.istockphoto.com/vectors/vector-map-of-japan-with-high-details-vector-id522130269?k=6&m=522130269&s=612x612&w=0&h=YaRe4yUW_zZo81B4zWEUqqxHP23eJaeQubWgJA6LGzc=' },
  ];
  xAxisLabelOrientation(xAxisLabelOrientation: any, properties: any) {
    let rotation = 0; // Default rotation value for horizontal orientation

    if (xAxisLabelOrientation === 'vertical') {
      rotation = -90; // Rotate labels to vertical orientation
    } else if (xAxisLabelOrientation === 'angled') {
      rotation = -45; // Rotate labels to an angled orientation
    }

    return rotation;
  }

  customizeLegend(showlegend: any, legendPos: any, properties: any): Highcharts.LegendOptions {
    let alignLegend: Highcharts.VerticalAlignValue = 'bottom'; // Default value
    let alignx: Highcharts.AlignValue | undefined = 'center';
    let layout1: Highcharts.OptionsLayoutValue | undefined = 'horizontal';

    if (showlegend) {
      switch (legendPos) {
        case "bottom":
          alignLegend = 'bottom';
          alignx = 'center';
          layout1 = 'horizontal';
          break;
        case "right":
          alignLegend = 'middle';
          alignx = 'right';
          layout1 = 'vertical';
          break;
        case "left":
          alignLegend = 'middle';
          alignx = 'left';
          layout1 = 'vertical';
          break;
        default:
          showlegend = false
          break;
      }
    }

    return {
      enabled: showlegend,
      align: alignx,
      verticalAlign: alignLegend,
      layout: layout1,
      itemHiddenStyle: {
        color: "gray"
      },
      itemStyle: {
        color: properties.darkTheme ? "white" : "black"
      },
      reversed: true
    };
  }

  customizationStack() {
    let custom: any = [
      { customization: 'Dark Theme', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Gridlines', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Plotbands', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Negative Values', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Shadow', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Gradient Bar Color', func: 'commonfunc', inputType: 'checkbox' },
      { customization: '100% Stacked Chart', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Axis Image', func: 'commonfunc', inputType: 'checkbox' },
      {
        customization: 'Axis Label Orientation : ',
        func: 'commonfunc',
        inputType: 'dropdown', options: [
          { value: 'horizontal', label: 'Horizontal' },
          { value: 'vertical', label: 'Vertical' },
          { value: 'angled', label: 'Angled' },
        ]
      },
      {
        customization: 'ToolTip Style : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Default' },

          { value: 'style1', label: 'Style1' },
        ]
      },
      {
        customization: 'Chart Title Position : ',
        func: 'commonfunc',
        inputType: 'dropdown', options: [
          { value: 'center', label: 'Center' },
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ]
      },
      {
        customization: 'Legend : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'enable', label: 'Enable' },
          { value: 'disable', label: 'Disable' },
        ]
      },
      {
        customization: 'Legend Position : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'bottom', label: 'Bottom' },
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ]
      },
      {
        customization: 'Data Label : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'enable', label: 'Enable' },
          { value: 'disable', label: 'Disable' },
        ]
      },
      {
        customization: 'Data Label Overlap : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'disable', label: 'Disable' },
          { value: 'enable', label: 'Enable' },
        ]
      },
      {
        customization: 'Data Label Format : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'number', label: 'Number' },
          { value: 'percentage', label: 'Percentage' },
        ]
      },
      {
        customization: 'Data Label Property : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'datalabel', label: 'default' },
          { value: 'change', label: 'Change Versus' },
          { value: 'significance', label: 'Significance' },
          { value: 'sigchan', label: 'Change Versus + Significance' },
          { value: 'significance2', label: 'Significance Level2' },
          { value: 'lowSample', label: 'Low Sample' },
        ]
      },
      {
        customization: 'Data Label Color : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Default' },
          { value: 'black', label: 'Black' },
          { value: 'blue', label: 'Blue' },
          { value: 'red', label: 'Red' },
          { value: 'green', label: 'Green' }
        ]
      },
      {
        customization: 'Data Label Position : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'center', label: 'Center' },
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
        ]
      },
      {
        customization: 'Data Label Position* : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Position' },
          { value: 'Inside Base', label: 'Inside Base' },
          { value: 'Center', label: 'Center' },
          { value: 'Inside End', label: 'Inside End' }
        ]
      },
    ];
    return custom
  }

  getDarkerShade(color: any) {
    const darkenFactor = 0.9; // Adjust this factor for a darker shade
    const rgb = color.substring(1); // Remove the '#' from the color
    const r = parseInt(rgb.substring(0, 2), 16);
    const g = parseInt(rgb.substring(2, 4), 16);
    const b = parseInt(rgb.substring(4, 6), 16);
    const darkerR = Math.round(r * darkenFactor);
    const darkerG = Math.round(g * darkenFactor);
    const darkerB = Math.round(b * darkenFactor);
    return `#${darkerR.toString(16).padStart(2, '0')}${darkerG.toString(16).padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
  }


  properties: any;
  commonFunc(customization: any, event: any, property: any) {
    this.properties = property;
    switch (customization) {
      case 'Gridlines':
        this.properties.gridlines = event.target.checked ? 1 : 0;
        break;
      case 'Shadow':
        this.properties.shadow = event.target.checked;
        break;
      case '100% Stacked Chart':
        this.properties.stacking = event.target.checked ? 1 : 0;
        this.properties.showNegative = false;
        break;
      case 'Legend Position : ':
        this.properties.legendPosition = event.target.value;
        break;
      case 'Legend : ':
        this.properties.showLegend = (event.target.value == 'enable' ? true : false);
        break;
      case 'Dark Theme':
        this.properties.darkTheme = event.target.checked;
        break;
      case 'Data Label : ':
        this.properties.showDataLabel = (event.target.value == 'enable' ? true : false);
        break;
      case 'Data Label Format : ':
        this.properties.datalabelFormat = event.target.value;
        break;
      case 'Data Label Property : ':
        this.properties.datalabelStyle = event.target.value;
        break;
      case 'Data Label Position : ':
        this.properties.dataLabelPosition = event.target.value;
        break;
      case 'Chart Title Position : ':
        this.properties.chartTitlePosition = event.target.value;
        break;
      case 'Axis Label Orientation : ':
        this.properties.axisLabelOrientation = event.target.value;
        break;
      case 'Plotbands':
        this.properties.plotBands = event.target.checked;
        break;
      case 'Axis Image':
        this.properties.axisImage = event.target.checked;
        break;
      case 'Gradient Bar Color':
        this.properties.gradientBarColor = event.target.checked;
        break;
      case 'Gradient Series Colors':
        this.properties.seriesGradient = event.target.checked;
        break;
      case 'Negative Values':
        this.properties.showNegative = event.target.checked;
        this.properties.stacking = 0;
        break;
      case 'Data Label Color : ':
        this.properties.dataLabelColor = event.target.value;
        break;
      case 'Data Label Position* : ':
        this.properties.dataLabelPosition = event.target.value;
        break;
      case 'Data Label Overlap : ':
        this.properties.allowOverlap = (event.target.value == 'enable' ? true : false);
        break;
      case 'ToolTip Style : ':
        this.properties.toolTipStyle = event.target.value;
        break;
    }
  }

}
