import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/app/environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { ExcelExportReportInputs } from '../../models/excel-export-report.model';

@Component({
  selector: 'app-header-settings',
  templateUrl: './header-settings.component.html',
  styleUrls: ['./header-settings.component.css']
})
export class HeaderSettingsComponent implements OnInit {
  toggleSetting: boolean = false;
  @Input() settingIconShown: boolean = true;
  @Input() helpGuide: boolean = false;
  @Input() showExcel: boolean = false;
  @Input() showPPT: boolean = false;
  @Input() excelExport: boolean = false;
  @Input() pptExport: boolean = false;
  @Input() repository: boolean = false;
  @Input() exportDownloads: boolean = false;
  @Input() userManagementShown: boolean = false;
  @Input() lowBaseIdentifier: boolean = false;
  @Input() exportReportInput!: ExcelExportReportInputs;
  @Input() logout:boolean=true;
  @Input() Home:boolean=true;
  @Input() isHeader:boolean=true;
  isUserManagement:boolean=false;
  guideUrl = environment.UserGuideUrl;
  receivedBoolValue: boolean = false;
  showExportsDownloadsPopup: boolean = false;
  @Output() excelExportClick = new EventEmitter<void>();
  @Output() pptExportClick = new EventEmitter<void>();
  @Output() documentRepoClick = new EventEmitter<void>();

  @Input() docClick: boolean = true;
  constructor(private router: Router, private el: ElementRef, private _authenticateService: AuthenticationService) { }
  ngOnInit(): void {
   
    let accessLevel = this._authenticateService.GetAccess();
    if (accessLevel != null) {
      if (accessLevel == this._authenticateService.level1 || accessLevel == this._authenticateService.level2) {
        this.isUserManagement = true;
      }
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    // Check if the clicked element is outside the component
    if (!this.el.nativeElement.contains(event.target)) {
      this.toggleSetting = false;
    }
  }
  redirectToLandingPage() {
    this.router.navigate(['/landing']);
  }
  onUserManagementClick() {
    this.router.navigate(['/user-management']);
  }
  OnSettingClick() {
    this.toggleSetting = !this.toggleSetting;
  }
  OnClickExcelExport() {
    this.excelExportClick.emit();
    this.toggleSetting = false;
  }
  onUserGuideClick() {
    const guideUrl = this.guideUrl;
    window.open(guideUrl, '_blank');
  }
  OnExportsDownloads() {
    this.showExportsDownloadsPopup = true;
  }
  onCloseExportDownloads() {
    this.showExportsDownloadsPopup = false;
  }

  HandleDocumentClick() {
    this.documentRepoClick.emit();
    this.toggleSetting = false;
  }

  ExportToPPT() {
    this.pptExportClick.emit();
    this.toggleSetting = false;
  }
  LogOut() {
    this._authenticateService.logout();
  }

 
}
