<div class="ModuleList">
  <div class="ModuleList_Container">
    <div
      *ngFor="let module of moduleList"
      class="Header"
      [class.BG]="module.isSelected" [class.DN]="module.name!=selectedModule"
      (click)="navigateToModule(module.name)"
    >
      <div class="Data_Explorer_txt">{{ module.name }}</div>
      <div class="header_border" *ngIf="module.isSelected">
        <div class="btn-icon header-line-icon"></div>
      </div>
    </div>
  </div>
</div>
