<div id="chart-container">
 
</div>
 
<svg height="0" width="0" style="position:absolute;">
<defs>
<pattern id="pattern3" width="10" height="10" patternUnits="userSpaceOnUse">
<rect width="10" height="10" fill="red"></rect>
<path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
</pattern>
</defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
<defs>
<pattern id="pattern2" width="10" height="10" patternUnits="userSpaceOnUse">
<!-- Modify the path element to create a single line stroke pattern -->
<path d="M 0 0 L 10 10" stroke="#00FF00" strokeWidth="2"></path>
</pattern>
</defs>
</svg>