// src/app/pipes/filter.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, fields: string[]): any[] {
     ;
    // Check if the input array, search text, or fields are not provided
    if (!items || !searchText || !fields || fields.length === 0) {
      return items; // Return the original array if any of the conditions is not met
    }

    searchText = searchText.toLowerCase(); // Convert the search text to lowercase for case-insensitive comparison

    return items.filter(item => {
      return fields.some(field => {
        // Check if the field exists and contains the search text
        return item[field] && item[field].toLowerCase().includes(searchText);
      });
    });
  }
}
