<div class="widget-main">
  <div class="widget-header">{{header}}</div>
  <div class="widget-body">
      <div class="widget-items" *ngFor="let item of data">
        <!--<div class="checkbox-icon" [ngClass]="{'checkbox-icon-active':item.is_selected}" (click)="onCheckboxClick(item)"></div>-->
        <div class="checkbox-text">{{item.key}}</div>
        <input class="checkbox-input-text" [(ngModel)]="item.data_value"  type="text" placeholder="Type here.." (input)="onInput($event, item)"  (keydown)="onKeyDown($event,item.key,item.data_value)">
      </div>
  </div>
</div>
