import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { results } from '../models/common.model';
import { environment } from 'src/app/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ExportSnapshotUserDataSelection, ExportUserDataSelection, IUsageLogs, ModuleClientStudy, userDetails, Users } from '../models/usermanagement.model';

@Injectable({
  providedIn: 'root',
})
export class UserProjectManagementService {
  private apiUrl = environment.baseApiUrl + 'api/UserProjectManagement/';

  private MN_GetUsers: string = 'getUsers';
  private MN_UpdateUsers: string = 'updateUsers';
  private MN_DeleteUsers: string = 'deleteUser';
  private MN_GetModules: string = 'getModules';
  private MN_UsersOnModuleClientStudy: string = 'usersOnModuleClientStudy';
  private MN_DeleteAccess: string = 'deleteAccess';
  private MN_UpdateUserAccess: string = 'updateUserAccess';
  private MN_DownloadUserDetails: string = 'downloadUserExcel';
  private MN_ExportUserData: string = 'exportUserData';
  private MN_ExportSnapshotUserData: string = 'exportSnapshotUserData';
  private MN_SaveUsageLogs: string = 'saveUsageLog';
  private MN_GetUsersBasedOnStudy: string = 'getUsersToShare';
  private MN_UploadUsers: string = 'uploadUsers';
  private Static_Template: string = 'staticTemplate';
  private MN_GetEntryPointDetails: string = 'getEntryPoints';
  constructor(private http: HttpClient) { }
  /**
   * Get All the users details
   * @returns {Observable<results>}
   */
  getUsers(): Observable<results> {
    return this.http.get<any>(`${this.apiUrl}${this.MN_GetUsers}`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }
  //Update all the users
  updateUsers(userList: Users[]): Observable<results> {
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_UpdateUsers}`, userList)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }

  //delete selected user
  deleteUser(user: Users): Observable<results> {
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_DeleteUsers}`, user)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }
  /**
   * Get the module client details
   * @returns {Observable<results>}
   */
  getModuleDetails(): Observable<results> {
    return this.http.get<any>(`${this.apiUrl}${this.MN_GetModules}`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }

  // get users based on the module client and study wise
  GetUsersBasedOnModuleClientStudy(
    Moduledata: ModuleClientStudy
  ): Observable<results> {
    return this.http
      .post<any>(
        `${this.apiUrl}${this.MN_UsersOnModuleClientStudy}`,
        Moduledata
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }

  // delete user access based on the module client and study
  DeleteAccessOnModuleClientStudy(userData: userDetails): Observable<results> {
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_DeleteAccess}`, userData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }
  // update all the users access data
  UpdateAllUserAccess(userData: userDetails[]): Observable<results> {
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_UpdateUserAccess}`, userData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }

  // update all the users access data
  ExportUserData(
    exportUserDataSelection: ExportUserDataSelection
  ): Observable<results> {
    return this.http
      .post<any>(
        `${this.apiUrl}${this.MN_ExportUserData}`,
        exportUserDataSelection
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }
  ExportSnapshotUserData(
    exportUserDataSelection: ExportSnapshotUserDataSelection
  ): Observable<results> {
    ;
    return this.http
      .post<any>(
        `${this.apiUrl}${this.MN_ExportSnapshotUserData}`,
        exportUserDataSelection
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }
  UsageData(
    module_id: string,
    client: string,
    study: string,
    feature: string,
    selectionSummary: string,
    snapshotName: string
  ) {
    const usageLogs: IUsageLogs = {
      module_id: module_id,
      client_name: client,
      study_name: study,
      user_name: '',
      role_name: '',
      features: feature, // Assuming Feature is an enum defined earlier
      datetime: '',
      selection_summary: selectionSummary,
      snapshotName: snapshotName
    };
    this.SaveUsageData(usageLogs).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        console.log('Log Saved successfully');
      }
    });
  }
  SaveUsageData(usageData: IUsageLogs): Observable<results> {
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_SaveUsageLogs}`, usageData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }

  // get the excel file
  DownloadUserDetails(data: ModuleClientStudy): Observable<Blob> {
    return this.http.post(
      `${this.apiUrl}${this.MN_DownloadUserDetails}`,
      data,
      { responseType: 'blob' }
    );
  }
  getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    return formattedDateTime;
  }
  downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getUsersBasedonStudy(selected_client: string,
    selected_study: string,
    selected_module: string
  ): Observable<results> {
    const data1: ModuleClientStudy = {
      client_name: selected_client,
      study_name: selected_study,
      module_name: selected_module,
      client_study_name: '',
      snapshot_name: '',
      module_id: '',
    };

    return this.http
      .post<any>(`${this.apiUrl}${this.MN_GetUsersBasedOnStudy}`, data1)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }



  uploadFile(file: File): Observable<results> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_UploadUsers}`, formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }


  downloadExcelFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  staticTemplate(): Observable<Blob> {
    return this.http
      .post(`${this.apiUrl}${this.Static_Template}`, null, { responseType: 'blob' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          return throwError(() => new Error(error.message));
        })
      );
  }

  getEntryPointDetails(clientName:string,study:string): Observable<results> {
    const data1: ModuleClientStudy = {
      client_name: clientName,
      study_name: study,
      module_name: "",
      client_study_name: '',
      snapshot_name: '',
      module_id: '',
    };
    return this.http
      .post<any>(`${this.apiUrl}${this.MN_GetEntryPointDetails}`, data1)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }
}
