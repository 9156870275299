import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CalendarMonths, CalenderModel } from '../../models/calendar.model';

@Component({
  selector: 'app-calendar-year-month',
  templateUrl: './calendar-year-month.component.html',
  styleUrls: ['./calendar-year-month.component.css']
})
export class CalendarYearMonthComponent implements OnInit,OnChanges {
  @Input() displayPreviousYear: boolean=false;
  @Input() fromYear: number=0;
  @Input() fromMonth: number=0;
  selectedYear:number=0;
  selectedMonth:number=0
  Year: number=new Date().getFullYear();
  currentYear:number=new Date().getFullYear();
  maxMonthSelectable:number=11;
  monthCount:number=11;
  months: CalendarMonths[]=[];
  @Output() monthSelected = new EventEmitter<{ year: number, month: number }>();
  constructor() { }

  ngOnInit(): void {
    // Initialize with current year
    // if (this.displayPreviousYear) {
    //   this.Year = new Date().getFullYear() - 1;
    // } else {
      this.Year = new Date().getFullYear();
   // }
    this.generateMonths();
  }
  ngOnChanges() {
    if (!this.displayPreviousYear) {
      this.Year = this.fromYear;
      this.selectedMonth=-1
    } 
    this.generateMonths();
    }   
  prevYear(): void {
    this.Year--;
    this.generateMonths();
  }

  nextYear(): void {
    this.Year++;
    this.generateMonths();
  }

  generateMonths(): void {
    this.currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.months = [];
    for (let i = 0; i <=this.monthCount; i++) {
      const date = new Date(this.Year, i, 1);
      const monthName = date.toLocaleString('default', { month: 'short' });
      let disabled = false;
    if (this.Year === this.currentYear && i > currentMonth) {
      disabled = true;
    }
    if (!this.displayPreviousYear){
      if((this.Year < this.fromYear )||(this.Year==this.fromYear && i<this.fromMonth)){
        disabled = true;
      }
      else if((this.maxMonthSelectable+this.fromMonth)<this.monthCount){
        if((this.Year==this.fromYear  && i>(this.maxMonthSelectable+this.fromMonth)) ||
        (this.Year==this.fromYear+1)||
      (this.Year>this.fromYear+1)
        ){
          disabled = true;
        }
      }
else if((this.Year==this.fromYear  && i>(this.maxMonthSelectable+this.fromMonth))||
      (this.Year==this.fromYear+1 && i>=this.maxMonthSelectable-(this.monthCount-this.fromMonth))||
      (this.Year>this.fromYear+1)) // After next year
     {
      disabled = true;
    }
  }
      let selected = (i === this.selectedMonth && this.selectedYear === this.Year);
      this.months.push({ name: monthName, disabled ,selected,index:i});
    }
  }
  onMonthClick(month:CalendarMonths): void {
    month.selected=true;
    this.selectedMonth = month.index;
    this.selectedYear = this.Year;
    let prevMonth=this.months.find((x:CalendarMonths)=>{return x.selected && x.name!=month.name});
    if(prevMonth!=undefined && prevMonth!=null){
      prevMonth.selected=false;
    }
    this.monthSelected.emit({ year: this.selectedYear, month: this.selectedMonth });
  }
}
