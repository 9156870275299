import { BrandAnalysisComponent } from "src/app/modules/brand-analysis/brand-analysis/brand-analysis.component";

export const COMMON_CONSTANTS = {
  NestingConfiguration: 2,
  InsufficientBase_Sample: 15,
  LowBase_Sample: 30,
  SigLowerLimit_95: -1.96,
  SigUpperLimit_95: 1.96,
  SigLowerLimit_90: -1.65,
  SigUpperLimit_90: 1.65,
 SigLowerLimit_98: -2.58,
SigUpperLimit_98: 2.58,
  BrandAnalysisModuleId: 999,
  Configuration: 998,
  SnapshotModuleId: 2,
  DataExplorerModuleId: 1,
  DataUploadModuleId : "Data Upload",
  CrossTabModuleId:"Crosstab",
  BrandAnalysisModuleNameId:"Brand Analysis",
  ARKName:"ARK",
  DataUploadName:"Data Upload",
  powerBIurl:
    'https://app.powerbi.com/reportEmbed?reportId=fe44bd33-c58e-4b4e-83fc-45ffaaf97f3f&appId=e4d78180-0859-4013-96fc-3d87815368b8&autoAuth=true&ctid=1e355c04-e0a4-42ed-8e2d-7351591f0ef1&filterPaneEnabled=false&navContentPaneEnabled=false',
};
export const COMMON_CONSTANTS_SNAPSHOT = {
  NestingConfiguration: 2,
  InsufficientBase_Sample: 15,
  LowBase_Sample: 30,
  SigLowerLimit_95: -1.96,
  SigUpperLimit_95: 1.96,
  SigLowerLimit_90: -1.65,
  SigUpperLimit_90: 1.65,
  SigLowerLimit_99: -2.58,
  SigUpperLimit_99: 2.58,
  SigUpperLimit_None: 0,
  SigLowerLimit_None: 0,
  BrandAnalysisModuleId: 999,
  Configuration: 998,
  SnapshotModuleId: 2,
  DataExplorerModuleId: 1,
  powerBIurl:
    'https://app.powerbi.com/reportEmbed?reportId=fe44bd33-c58e-4b4e-83fc-45ffaaf97f3f&appId=e4d78180-0859-4013-96fc-3d87815368b8&autoAuth=true&ctid=1e355c04-e0a4-42ed-8e2d-7351591f0ef1&filterPaneEnabled=false&navContentPaneEnabled=false',
};
export const COMMON_CONSTANTS_Percentage = {
  ConfidencePercent_90: '90%',
  ConfidencePercent_95: '95%',
  ConfidencePercent_99: '99%',
  ConfidencePercent_None: 'None'

};

export const BUTTON_HOVER_NAMES = {
  Filtering: `User can see shares on selected Total, the metric in 'Rows' are FILTERED on the metrics selected in 'Row Nesting'.`,
  Profiling: `This allows user to see shares of Nested groups on Parent Metric. Here, Metrics selected in 'Rows' are PROFILED by the metrics selected in 'Row Nesting'.`,
  Answer_Base: `All the Metrics selected in the Filters and Columns along with an additional filter of Respondents who have answered that particular question(in Row) constitutes the base`,
  Total_Base: `All the Metrics selected in the Filters and Columns constitute the base`,
  Load_Selection: `Auto generate saved selection`,
  Custom_Groups: `Define new metrics using existing variables`,
  Custom_Report: `Combine saved selections into a report`

}
