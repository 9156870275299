<div id="vertical-chart-container">

</div>

<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern1" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#9BC5E5"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern2" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#D5BCE4"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern3" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#F9C099"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern4" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#FF97A8"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern5" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#A6E2D7"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern6" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#BDE6BD"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern7" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#BFE1FD"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern8" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#F4E4B3"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<svg height="0" width="0" style="position:absolute;">
  <defs>
    <pattern id="pattern9" width="10" height="10" patternUnits="userSpaceOnUse">
      <rect width="10" height="10" fill="#CD978B"></rect>
      <path d="M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11" stroke="#000FFF" strokeWidth="3"></path>
    </pattern>

  </defs>
</svg>
<!-- Include this element in your HTML -->

