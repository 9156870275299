import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { results } from '../models/common.model';
import { environment } from 'src/app/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomReportForDownload, CustomReports, CustomReportsInputs, DeleteInputs } from '../models/custom-report.model';
@Injectable({
  providedIn: 'root',
})
export class CustomReportService {
  private apiUrl = environment.baseApiUrl + 'api/CustomReports/';
  private MN_GetListOfCustonReports: string = 'getAllCustomReports';
  private MN_GetSavedSelections: string = 'getSavedSelections';
  private MN_SavedCustomReport: string = 'saveCustomReport';
  private MN_DeleteCustomReport: string = 'deleteCustomReport';
  private MN_UpdateCustomReport: string = 'updateCustomReport';
  private MN_GetUserList: string = 'getUserList';
  private MN_ShareCustomReport: string = 'shareCustomReport';
  private MN_DownloadCustomReport: string = 'downloadCustomReport';
  
  constructor(private http: HttpClient) {}
  /**
   * Get All the Custom Reports
   * @returns {Observable<results>}
   */
  getAllCustomReports(inputobj:CustomReportsInputs): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_GetListOfCustonReports}`,inputobj).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }
  //Get all saved selections
  getSavedSelection(inputobj:CustomReportsInputs): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_GetSavedSelections}`,inputobj).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }
  //Save the custom reports
  SaveCustomReport(CustomReportsObj:CustomReports): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_SavedCustomReport}`,CustomReportsObj).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }

  //Update the custom report
  UpdateCustomReport(CustomReportsObj:CustomReports): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_UpdateCustomReport}`,CustomReportsObj).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }
  //Delete the custom report
  DeleteCustomReport(deleteObj:DeleteInputs): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_DeleteCustomReport}`,deleteObj).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }
  //Get the user list to share
  GetUserList(reportItem:CustomReports): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_GetUserList}`,reportItem).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }

  //Save share custom report details
  ShareCustomReport(reportItem:CustomReports): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_ShareCustomReport}`,reportItem).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }

  //Download the custom reports
  DownloadCustomReport(reportItem:CustomReportForDownload): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_DownloadCustomReport}`, reportItem).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }
}
