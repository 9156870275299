/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, PublicClientApplication, EndSessionRequest } from '@azure/msal-browser';
import { environment } from 'src/app/environments/environment'

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: environment.kantarClientID, // This is the ONLY mandatory field that you need to supply.
    authority: environment.kantarAuthorityID, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: environment.redirectUrl, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: environment.logoutURL,    
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
        }
    }
}
const msalInstance = new PublicClientApplication(msalConfig);
export const protectedResources = {
  Api: {
    endpoint: environment.baseApiUrl,
        scopes: {
          read: [`api://${environment.kantarApiClientID}/user_impersonation`]
        }
    },
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    // scopes: ["openid", "profile"],
    // loginHint: "example@domain.net"
};



