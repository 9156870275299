import { NgModule } from '@angular/core';
import { HeaderNavBarComponent } from '../header/header-nav-bar/header-nav-bar.component';
import { HeaderNavTextComponent } from '../header/header-nav-text/header-nav-text.component';
import { HeaderBrandlogoComponent } from '../header/header-brandlogo/header-brandlogo.component';
import { HeaderHomeComponent } from '../header/header-home/header-home.component';
import { HeaderModuleListComponent } from '../header/header-module-list/header-module-list.component';
import { HeaderSettingsComponent } from '../header/header-settings/header-settings.component';
import { SearchPipe } from '../pipes/search.pipe';
import { CalendarYearMonthComponent } from './calendar-year-month/calendar-year-month.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ErrorAlertPopupComponent } from './error-alert-popup/error-alert-popup.component';
import { ExportDownloadsPopupComponent } from './export-downloads-popup/export-downloads-popup.component';
import { InsightsPopupComponent } from './insights-popup/insights-popup.component';
import { LoaderComponent } from './loader/loader.component';
import { SelectionSummaryComponent } from './selection-summary/selection-summary.component';
import { SharedLayoutComponent } from './shared-layout/shared-layout.component';
import { SharedPopupComponent } from './shared-popup/shared-popup.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LowBaseWidgetComponent } from 'src/app/modules/configuration/low-base-widget/low-base-widget.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeftpanelComponent } from 'src/app/common/shared/leftpanel/leftpanel.component';
import { TablePreviewComponent } from 'src/app/common/shared/table-preview/table-preview.component';
import { TableOuputComponent } from 'src/app/common/shared/table-ouput/table-ouput.component';
import { LineOrTrendChart1Component } from 'src/app/common/charts/Trend Chart/lineChart.component';
import { Barchart01Component } from 'src/app/common/charts/VerticalBarChart/vertical-bar-chart.component';
import { Barchart18Component } from 'src/app/common/charts/VerticalClusterBarChart/vertical-cluster-bar.component';
import { Stackedbarchart1Component } from 'src/app/common/charts/VerticalStackChart/verticalStackChart.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomReportsComponent } from './custom-reports/custom-reports.component';
import { DocumentclickDirective } from '../directives/documentclickHeader.directive';

@NgModule({
  declarations: [
    DocumentclickDirective,
    HeaderNavBarComponent,
    HeaderNavTextComponent,
    HeaderModuleListComponent,
    HeaderBrandlogoComponent,
    HeaderHomeComponent,
    HeaderSettingsComponent,
    LoaderComponent,
    ErrorAlertPopupComponent,
    ConfirmationDialogComponent,
    SharedLayoutComponent,
    SharedPopupComponent,
    SelectionSummaryComponent,
    SearchPipe,
    UnauthorizedComponent,
    InsightsPopupComponent,
    ExportDownloadsPopupComponent,
    CalendarYearMonthComponent,
    LowBaseWidgetComponent,
    LeftpanelComponent,
    TablePreviewComponent,
    TableOuputComponent,
    LineOrTrendChart1Component,
    Barchart01Component,
    Barchart18Component,
    Stackedbarchart1Component,
    CustomReportsComponent,
  ],
  imports: [CommonModule, FormsModule, DragDropModule],
  exports: [
    HeaderNavBarComponent,
    HeaderNavTextComponent,
    HeaderModuleListComponent,
    HeaderBrandlogoComponent,
    HeaderHomeComponent,
    HeaderSettingsComponent,
    LoaderComponent,
    ErrorAlertPopupComponent,
    ConfirmationDialogComponent,
    SharedLayoutComponent,
    SharedPopupComponent,
    SelectionSummaryComponent,
    SearchPipe,
    UnauthorizedComponent,
    InsightsPopupComponent,
    ExportDownloadsPopupComponent,
    CalendarYearMonthComponent,
    CommonModule,
    FormsModule,
    LowBaseWidgetComponent,
    LeftpanelComponent,
    TablePreviewComponent,
    TableOuputComponent,
    LineOrTrendChart1Component,
    Barchart01Component,
    Barchart18Component,
    Stackedbarchart1Component,
    CustomReportsComponent,
  ],
})
export class SharedModule {}
