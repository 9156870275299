import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertMessageModel } from '../models/common.model';
@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {
  private isAlert = new BehaviorSubject<any>({});
  isAlert$ = this.isAlert.asObservable();
  /**
   * show the loader icon
   */
  showAlert(alertMessage:AlertMessageModel):void {
    this.isAlert.next(alertMessage);
  }
}
