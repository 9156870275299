import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-header-nav-text',
  templateUrl: './header-nav-text.component.html',
  styleUrls: ['./header-nav-text.component.css']
})
export class HeaderNavTextComponent {
  @Input() isAddClientDetails: boolean = false;
}
