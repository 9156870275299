import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from 'src/app/modules/landing-page/landing-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { UserManagementComponent } from './modules/user-management/user-management.component';
import { ProjectManagementComponent } from './modules/project-management/project-management.component';
import { UnauthorizedComponent } from './common/shared/unauthorized/unauthorized.component';
import { RoleGuard } from './common/_helpers/role.guard';
import { AuthGuard } from './common/_helpers/auth.guard';
import { DataUploadComponent } from './modules/data-upload/data-upload.component';
import { ChatbotComponent } from './modules/chatbot/chatbot.component';
import { ChatbotexpandComponent } from './modules/chatbotexpand/chatbotexpand.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing',
    component: LandingPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/configuration/configuration.module').then(
        (module) => module.ConfigurationModule
      ),
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'Crosstab',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/crosstab/crosstab.module').then(
        (module) => module.CrosstabModule
      ),
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'BrandAnalysis',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/brand-analysis/brand-analysis.module').then(
        (module) => module.BrandAnalysisModule
      ),
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'Snapshot',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/snapshot/snapshot.module').then(
        (module) => module.SnapshotModule
      ),
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'chatbot',
    component: ChatbotComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'chatbotexpand',
    component: ChatbotexpandComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: ['Admin', 'Super Admin'] },
  },
  {
    path: 'project-management',
    component: ProjectManagementComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { role: ['Admin', 'Super Admin'] },
  },
  {
    path: 'dataupload',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () =>
      import('./modules/data-upload/upload.module').then(
        (module) => module.UploadModule
      ),
    data: { role: ['Admin', 'Super Admin', 'Normal user'] },
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  { path: '**', redirectTo: 'landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
