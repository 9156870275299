<div class="popup-container" *ngIf="showError">
    <div class="popup-model">
        <div class="popup-header">
            <div class="popup-header-text">{{headerTitle}}</div>
            <div class="popup-close" (click)="close()" title="Close"></div>
        </div>
        <div class="popup-body">
            <div class="message-block">
                {{messageText}}
            </div>
        </div>
        
    </div>
</div>