import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { results } from '../models/common.model';
import { environment } from 'src/app/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DataObject, ExcelDataModel } from '../models/crosstab.model';
import { ExcelExportReport, ExcelExportReportInputs } from '../models/excel-export-report.model';
@Injectable({
  providedIn: 'root'
})
export class ExportDownloadServices {
    private apiUrl = environment.baseApiUrl+"api/Export/";
    private MN_StartExcelExport:string="startExcelExport";
    private MN_GetReports:string="getExcelReports";
    private MN_UpdateDownloadedStatus:string="updateDownloadedStatus";
    private MN_GetMessage:string="getmessage";
    constructor(private http: HttpClient) { }

    getExcelReports(reportType:ExcelExportReportInputs): Observable<results> {
      
      return this.http.post<any>(`${this.apiUrl}${this.MN_GetReports}`,reportType).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error:any) => new Error(error));
        })
      );
    }
    getmessage(): Observable<results> {
      return this.http.get<any>(`${this.apiUrl}${this.MN_GetMessage}`).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error:any) => new Error(error));
        })
      );
    }
    UpdateDownloadedStatus(data:ExcelExportReport): Observable<Blob> {
      return this.http.post(`${this.apiUrl}${this.MN_UpdateDownloadedStatus}`, data,{ responseType: 'blob' });
    }
  exportToExcelLargeSelection(data:ExcelDataModel): Observable<results> {
    return this.http.post<any>(`${this.apiUrl}${this.MN_StartExcelExport}`,data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error:any) => new Error(error));
      })
    );
  }
  getFileBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    return formattedDateTime;
  }
}

