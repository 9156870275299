import { Component } from '@angular/core';

@Component({
  selector: 'app-header-upload-module',
  templateUrl: './header-upload-module.component.html',
  styleUrls: ['./header-upload-module.component.scss']
})
export class HeaderUploadModuleComponent {

}

