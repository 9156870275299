import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InsightsModel } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class InsightspopupService {

  private isInsights = new BehaviorSubject<any>({});
  isInsights$ = this.isInsights.asObservable();
  /**
   * show the loader icon
   */
  showInisghts(InsightsMessage:InsightsModel):void {
    this.isInsights.next(InsightsMessage);
  }
}
