
<div class="table-preview">
    <div class="preview-table-container" [ngStyle]="{'--custom-margin': getMarginForScroll()}">
        <div class="rows-left-header-block">
            <div class="empty-header" *ngIf="data['Row'].length>0 || data['Column'].length>0">
                <div class="empty-row-block"></div>
                <ng-container *ngIf="data['ColNesting']!=undefined">
                    <div class="empty-col-nesting" *ngFor="let colnesting of data.ColNesting"></div>
                </ng-container>
            </div>
            <div class="table-rows-header-block">
                <ng-container *ngIf="data['Row']!=undefined">
                    <div class="preview-rows" *ngFor="let row of data.Row">
                    <div class="preview-row-header" *ngFor="let row_item of row.Data">
                        <div class="preview-row-header-text"  [style.height.px]="getWidthofCellOnCount('Row','RowNesting')" title="{{getHeader(row_item.Child)}}"><div class="row-col-header-title">{{getHeader(row_item.Child)}}</div></div>
                        <ng-container *ngIf="data['RowNesting']!=undefined">
                            <div class="row-nesting-block" *ngFor="let rownesting of data.RowNesting;let ind=index">
                                <ng-container *ngIf="ind==0">
                                    <div class="row-nest-header-block">
                                        <div class="row-nest-header" [style.height.px]="getNestingCount(ind,'Row','RowNesting')" *ngFor="let nesting of rownesting.Data">
                                            <div class="row-nest-header-txt" title="{{getHeader(nesting.Child)}}">{{getHeader(nesting.Child)}}</div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="ind!=0" >
                                    <div class="row-nest-header-block" *ngFor="let item of [].constructor( getNestedLevelCount(ind,data.RowNesting))">
                                        <div class="row-nest-header" [style.height.px]="getNestingCount(ind,'Row','RowNesting')" *ngFor="let nesting of rownesting.Data">
                                            <div class="row-nest-header-txt" title="{{getHeader(nesting.Child)}}">{{getHeader(nesting.Child)}}</div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="column-body">
            <div class="preview-columns-header">
                <ng-container *ngIf="data['Column']!=undefined">
                    <div class="preview-row-header" *ngFor="let col of data.Column">
                        <div class="row-block" *ngFor="let col_item of col.Data">
                            <div class="row-header-text" [style.width.px]="getWidthofCellOnCount('Column','ColNesting')"><div class="row-header-title" title="{{getHeader(col_item.Child)}}"> {{getHeader(col_item.Child)}}</div></div>
                            <ng-container *ngIf="data['ColNesting']!=undefined">
                                <div class="col-nesting-block" *ngFor="let colnesting of data.ColNesting;let ind=index">
                                    <ng-container *ngIf="ind==0">
                                        <div class="col-nest-header-block">
                                            <div class="col-nest-header" [style.width.px]="getNestingCount(ind,'Column','ColNesting')" *ngFor="let nesting of colnesting.Data">
                                                <div class="col-nest-header-txt" title="{{getHeader(nesting.Child)}}"> {{getHeader(nesting.Child)}}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="ind!=0" >
                                        <div class="col-nest-header-block" *ngFor="let item of [].constructor( getNestedLevelCount(ind,data.ColNesting))">
                                            <div class="col-nest-header" [style.width.px]="getNestingCount(ind,'Column','ColNesting')" *ngFor="let nesting of colnesting.Data">
                                                <div class="col-nest-header-txt" title="{{getHeader(nesting.Child)}}">{{getHeader(nesting.Child)}}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="preview-table-body">
                <div class="row-data-container" *ngFor="let item of [].constructor( getrowCount())">
                    <div class="row-cell-block" *ngFor="let item of [].constructor( getrowCellCount())">
                        <div class="cell-data"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

