export const environment = {
  baseApiUrl: 'https://appservicekapdashboardapitest.azurewebsites.net/',
  redirectUrl: 'https://uat-dataexplorer.kantar.com/landing',
  kantarClientID: '156c98f2-2904-4c60-9d8f-f16d939f5cae',
  kantarApiClientID: '2dd9b892-d10f-47f9-9545-4e21d06d0564',
  kantarAuthorityID: 'https://login.microsoftonline.com/1e355c04-e0a4-42ed-8e2d-7351591f0ef1',
  UserGuideUrl: 'https://elearning.easygenerator.com/bcd568f0-29e3-43fb-a042-238bfbb04e6e/',
  UserGuideSnapshotUrl: 'https://elearning.easygenerator.com/4168a9e6-5f42-4a18-80cd-5aeab0add565/',
  logoutURL: 'https://uat-dataexplorer.kantar.com/signout-oidc',
  sessionTimeOut: '20',
  env: 'uat',
  showUploadModule: 'false',
};
