import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  headerTitle:string="Confirmation";
  @Input() confirmBtnText:string="Confirm";
  @Input() cancelBtnText:string="Cancel";
  @Input() messageText: string="";
  @Output() confirmed: EventEmitter<number> = new EventEmitter();

  confirm() {
    this.confirmed.emit(1);
  }

  cancel() {
    this.confirmed.emit(0);
  }
  close() {
    this.confirmed.emit(-1);
  }
}
