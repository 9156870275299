// role.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedRole = route.data["role"];

    if (!this.authService.isAuthenticated() || !this.authService.hasRole(expectedRole)) {
      // Redirect to the unauthorized page if not authenticated or lacking the required role
      this.router.navigate(['/unauthorized']);
      return false;
    }

    return true;
  }
}

