<div class="selectionSummaryMain">
  <div class="FilterMain">
    <div class="FilterIcon">
      <div class="FilterImage">
      </div>
    </div>
    <div class="FilterText">
      FILTERS
    </div>
  </div>
  <div class="seperator">
    <div class="seperatorIcon">
    </div>
  </div>
  <div class="FilterSummary">

  </div>
  <div class="filterDelete">
    <div class="deleteIcon">

    </div>
  </div>
</div>
