<app-header-nav-bar>
    <app-header-brandlogo style="visibility: hidden;"></app-header-brandlogo>
    <app-header-user-project-menu-list [active_line1]="ActiveModule1" [active_line2]="ActiveModule2" [active_Menu]="'project'"></app-header-user-project-menu-list>
    <app-header-settings [settingIconShown]="true" [exportReportInput]="exportReportInput" [userManagementShown]="true"></app-header-settings>
</app-header-nav-bar> 
<section>
    <div class="table-search-container">
    <div class="search-export-container">
        <div class="search-block" title="Search">
            <input type="text" [(ngModel)]="searchQuery" class="search-input" placeholder="Search">
            <div class="search-icon"></div>
        </div>
       
        <div class="exports-downloads-container">
            <div class="export-block" *ngIf="isLevel1" title="Export User Data" (click)="OnExportUserDataClick()">
                <div class="btn-icon export-icon"></div>
                <div class="export-header-text">Export User Data</div>
            </div>
        <app-header-settings *ngIf="isLevel1"
                       [exportDownloads]="exportDownloads"
                       [exportReportInput]="exportReportInput"
                       [logout]="false"
                       [Home]="false"
                       [userManagementShown]="false"
                       [isHeader]="false"
                      ></app-header-settings>
        </div>
    </div>
    <div class="table-container">
        <table class="module-table">
            <thead>
                <tr>
                    <th class="col-33">
					Module
                            <div class="PM_M_Filter">
                                <div (click)="showFilterPopup('module',$event)" class="FilterImage"></div>
                                <div *ngIf="filterModulePopup" DocumentClick class="filterPopup"
                                    (DocumentClicked)="closeSummary()">
                                    <app-filter-popup [input]="uniqModule" (selectedOptionsFilter)="receivedModuleFilters($event)"></app-filter-popup>
                                </div>
                            </div>
                        </th>
                    <th class="col-33">
					Client-Study/Sub Module
                            <div class="PM_CS_Filter">
                                <div (click)="showFilterPopup('clientStudy',$event)" class="FilterImage"></div>
                                <div *ngIf="filterClientStudyPopup" DocumentClick class="filterPopup"
                                    (DocumentClicked)="closeSummary()">
                                    <app-filter-popup [input]="uniqClientStudy" (selectedOptionsFilter)="receivedStudyFilters($event)"></app-filter-popup>
                                </div>
                            </div>
                        </th>
                    <th class="col-33">User Details</th>
                </tr>
            </thead>
            <tbody >
                <tr *ngFor="let item of ModuleClientStudyListDisplay | search: searchQuery : ['client_study_name', 'module_name'];let i = index">
                    <td class="text-ellipsis" title="{{item.module_name}}">{{item.module_name}}</td>
                    <td class="text-ellipsis" title="{{item.client_study_name}}">{{item.client_study_name}}</td>
                    <td class="text-ellipsis" title="">
                        <div class="edit-download-container">
                            <div class="edit-block" title="Edit Details" (click)="OnEditClick(item)">
                                <div class="btn-icon edit-icon"></div>
                                <div class="btn-header-text">Edit Details</div>
                            </div>
                            <div class="download-block" title="Download Details" (click)="OnDownloadClick(item)">
                                <div class="btn-icon download-icon"></div>
                                <div class="btn-header-text">Download Details</div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="ModuleClientStudyList==null || ModuleClientStudyList.length==0">
                    <td colspan="3" style="text-align: center;">No Data Available</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</section>
<div class="table-popup-container" *ngIf="showUserPopup">
    <div class="table-popup-model">
        <div class="table-popup-header-block">
            <div class="table-popup-header-name">{{userDetailsPopupHeader}}</div>
            <div class="table-popup-close-icon" title="Close" (click)="onClosePopup()"></div>
        </div>
        <div class="table-popup-body">
            <div class="search-user-container">
                <div class="search-block" title="Search">
                    <input type="text" [(ngModel)]="searchUsers" class="search-input" placeholder="Search">
                    <div class="search-icon"></div>
                </div>
            </div>
            <div class="table-container">
                <table class="user-details-table">
                    <thead>
                        <tr>
                            <th class="col-popup-sm">First Name</th>
                            <th class="col-popup-sm">Last Name</th>
                            <th class="col-popup-md">Mail-ID</th>
                            <th class="col-popup-sm">User Role</th>
                            <th class="col-popup-sm">Active Flag</th>    
                            <th class="col-popup-xsm">Action</th>    
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let item of listOfUser | search: searchUsers : ['first_name', 'last_name','email'];let i = index">
                            <td class="text-ellipsis" title="{{item.first_name}}">{{item.first_name}}</td>
                            <td class="text-ellipsis" title="{{item.last_name}}">{{item.last_name}}</td>
                            <td class="text-ellipsis" title="{{item.email}}">{{item.email}}</td>
                            <td class="text-ellipsis" title="Role">
                                <div class="role-flag-container" *ngIf="isLevel1 && !item.super_admin">
                                    <div class="selected-container" (click)="toggleroleDropdown(i,item)" [ngClass]="{'active-border':selectedRoleCell === i}">
                                        <div class="selected-text text-ellipsis">{{item.access.role_name==null|| item.access.role_name==''?'Select Role':item.access.role_name}}</div>
                                        <div class="dropdown-icon down-arrow-icon" [ngClass]="{'up-arrow-icon':selectedRoleCell === i}"></div>
                                    </div>
                                    <div class="dropdown-container" *ngIf="selectedRoleCell === i">
                                        <div class="dropdown-options" *ngFor="let roleItem of rolesListItems"
                                        (click)="OnRadioRoleFlagToggle(roleItem,item)" [ngClass]="{'bg-active':roleItem.is_selected}">
                                            <div class="radio-icon radio-inactive" [ngClass]="{'radio-active':roleItem.is_selected}"></div>
                                            <div class="option-text text-ellipsis" title="{{roleItem.role_name}}">{{roleItem.role_name}}</div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="item.super_admin">Super Admin</ng-container>
                                <ng-container *ngIf="isLevel2 && !item.super_admin">{{item.access.role_name}}</ng-container>
                            </td>
                            <td class="text-ellipsis" title="Active Status">
                                <div class="active-flag-container" *ngIf="!item.super_admin">
                                    <div class="selected-container" (click)="toggleActiveDropdown(i,item)" [ngClass]="{'active-border':selectedActiveCell === i}">
                                        <div class="selected-text text-ellipsis">{{item.access.is_active==false?'Inactive':'Active'}}</div>
                                        <div class="dropdown-icon down-arrow-icon" [ngClass]="{'up-arrow-icon':selectedActiveCell === i}"></div>
                                    </div>
                                    <div class="dropdown-container" *ngIf="selectedActiveCell === i">
                                        <div class="dropdown-options" *ngFor="let activeItem of activeFlagList"
                                        (click)="OnRadioActiveFlagToggle(activeItem,item)" [ngClass]="{'bg-active':activeItem.is_selected}">
                                            <div class="radio-icon radio-inactive" [ngClass]="{'radio-active':activeItem.is_selected}"></div>
                                            <div class="option-text text-ellipsis" title="{{activeItem.name}}">{{activeItem.name}}</div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="item.super_admin">Active</ng-container>
                            </td>
                            <td class="text-ellipsis" title="">
                                <div class="delete-container" *ngIf="!item.super_admin">
                                    <div class="delete-icon" title="Delete" (click)="OnDeleteModuleClick(item)"></div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="listOfUser==null || listOfUser.length==0">
                            <td colspan="6" style="text-align: center;">No Data Available</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="submit-container" *ngIf="listOfUser!=null && listOfUser.length>0">
                <div class="submit-block" title="Submit User Data" (click)="OnSubmitClick()">
                    <div class="btn-icon submit-icon"></div>
                    <div class="btn-header-text">Submit</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="exports-popup-container" *ngIf="showExportsPopup">
    <div class="exports-popup-model">
        <div class="exports-popup-header-block">
            <div class="exports-popup-header-name">Export User Data</div>
            <div class="exports-popup-close-icon" title="Close" (click)="onCloseExportsPopup()"></div>
        </div>
        <div class="exports-popup-body">
            <div class="search-client-study-container">
                <p style="font-size:0.7rem;display:flex;align-items: center;margin-right:1%">Module</p>

                <div class="module-dropdown" (click)="moduleDropdown()" DocumentClick (DocumentClicked)="closeModuleDropdown()">
                    <p style="font-size:0.7rem;display:flex;align-items: center;padding-left:1%;">{{moduleSelected}}</p>
                    <img src="../../../../assets/ImagesSnapshot/Public/key-downDown.svg" alt="" class="icon">
                </div>
                <div *ngIf="moduleToggle" class="main-module-container" >
                    <div *ngFor="let module of moduleLists" class="module-container"
                        [ngClass]="{'selectedDropdownItem': module.isSelected}" (click)="moduleClick(module,$event)">
                        <div class="module-radio"><input type="radio" [checked]="module.isSelected"></div>
                        <div class="module-content">{{module.name}}</div>
                    </div>
                </div>
                <ng-container *ngIf="DDModule && !SnapModule">
                    <div class="search-exports-block" title="Search">
                        <input type="text" [(ngModel)]="searchClientStudy" class="search-input" placeholder="Search">
                        <div class="search-icon"></div>
                    </div> 
                </ng-container>
                <ng-container *ngIf="!DDModule && SnapModule">
                    <div class="search-exports-block" title="Search">
                        <input type="text" [(ngModel)]="searchClientSnapshot" class="search-input" placeholder="Search">
                        <div class="search-icon"></div>
                    </div> 
                </ng-container>
            </div>
            <ng-container *ngIf="DDModule && !SnapModule">
                <div class="exports-client-study-container">
                    <div class="client-study-option" (click)="OnSelectAllClick('Crosstab')"
                        [class.active-option]="clientStudySelectAll">
                        <div class="checkbox-icon checkbox-inactive"
                            [ngClass]="{'checkbox-active':clientStudySelectAll}"></div>
                        <div class="client-study-option-text">Select All</div>
                    </div>
                    <div class="client-study-option"
                        *ngFor="let item of clientStudyListCopy| search: searchClientStudy : ['client_study_name'];"
                        (click)="OnCheckBoxClick(item)" [class.active-option]="item.isSelected">
                        <div class="checkbox-icon checkbox-inactive" [ngClass]="{'checkbox-active':item.isSelected}">
                        </div>
                        <div class="client-study-option-text" title="{{item.client_study_name}}">
                            {{item.client_study_name}}</div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!DDModule && SnapModule">
                <div class="exports-client-study-container">
                    <div class="client-study-option" (click)="OnSelectAllClick('Snapshot')"
                        [class.active-option]="clientSnapshotSelectAll">
                        <div class="checkbox-icon checkbox-inactive"
                            [ngClass]="{'checkbox-active':clientSnapshotSelectAll}"></div>
                        <div class="client-study-option-text">Select All</div>
                    </div>
                    <div class="client-study-option"
                        *ngFor="let item of clientSnapshotListCopy| search: searchClientSnapshot : ['client_snapshot_name'];"
                        (click)="OnSnapshotCheckBoxClick(item)" [class.active-option]="item.isSelected">
                        <div class="checkbox-icon checkbox-inactive" [ngClass]="{'checkbox-active':item.isSelected}">
                        </div>
                        <div class="client-study-option-text" title="{{item.client_snapshot_name}}">
                            {{item.client_snapshot_name}}</div>
                    </div>
                </div>
            </ng-container>

            <div class="date-container" >
                <app-calendar-year-month class="date-block" [displayPreviousYear]="true"
                    (monthSelected)="onFromMonthSelected($event)"></app-calendar-year-month>
                <app-calendar-year-month class="date-block" [class.disabled]="calenderData.fromYear==-1"
                    [fromYear]="calenderData.fromYear" [fromMonth]="calenderData.fromMonth"
                    (monthSelected)="onToMonthSelected($event)"></app-calendar-year-month>
            </div>
            <div class="download-container" *ngIf="DDModule && !SnapModule">
                <div class="exports-download-block" title="download User Data" (click)="OnDownloadUserDataClick()"
                [class.disabled]="SelectedClientStudy.length<=0 || (calenderData.fromYear==-1 ||calenderData.fromMonth==-1 ||calenderData.toYear==-1 ||calenderData.toMonth==-1)">
                    <div class="btn-icon export-download-icon"></div>
                    <div class="btn-header-text">Download</div>
                </div>
            </div>
            <div class="download-container" *ngIf="!DDModule && SnapModule">
                <div class="exports-download-block" title="download User Data" (click)="OnSnapDownloadUserDataClick()"
                    [class.disabled]="SelectedClientSnapshot.length<=0 || (calenderData.fromYear==-1 ||calenderData.fromMonth==-1 ||calenderData.toYear==-1 ||calenderData.toMonth==-1)">
                    <div class="btn-icon export-download-icon"></div>
                    <div class="btn-header-text">Download</div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-confirmation-dialog *ngIf="isShowDialog" [messageText]="confirmationMessage" (confirmed)="onConfirmation($event)"></app-confirmation-dialog>
