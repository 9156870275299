import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertMessageModel } from '../models/common.model';
import { AlertMessageService } from '../services/alert-message.service';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
    constructor(private _alertService:AlertMessageService) { 
    }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Intercept the request before it is sent
    // Continue with the request handling process
    return next.handle(request)
      .pipe(
        // Intercept the response data
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if(event.body!=null){
                if(event.body["HasException"]!=undefined && event.body["HasException"]){
                    const alertObj:AlertMessageModel={header:"Error",message:event.body["ExceptionMessage"],show:true};
                    this._alertService.showAlert(alertObj);
                }
            }
            //console.log('Intercepted Response:', event);
            // You can handle the response data here
            // For example, you can modify the response or perform some actions
          }
        })
      );
  }
}
