import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-user-project-menu-list',
  templateUrl: './header-user-project-menu-list.component.html',
  styleUrls: ['./header-user-project-menu-list.component.css']
})
export class HeaderUserProjectMenuListComponent {
  menu_1: string = "PROJECT MANAGEMENT";
  menu_2: string = "USER MANAGEMENT";
  menu_underline1: boolean = false;
  menu_underline2: boolean = true;
  @Input() active_Menu: string = "";
  @Input() active_line1: boolean = false;
  @Input() active_line2: boolean = false;
  constructor(private router: Router) {
   
  }
 
  onMenuClick(menu: string) {
    this.menu_underline1 = false;
    this.menu_underline2 = false;

    if (menu == "project") {
      this.menu_underline1 = true;
      this.router.navigateByUrl("project-management");
     
    }
    if (menu == "user") {
      this.menu_underline2 = true;
      this.router.navigateByUrl("user-management");
    }   
  }
}
