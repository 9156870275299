<ng-container *ngIf="!this.RestatementNotAllowed">
  <div class="upload_container">
    <div class="upload_parent">
      <div class="upload_header">
        <span> Data Update </span>
        <div class="status_div">
          <div class="in_progress">
            <span class="material-symbols-outlined"> schedule </span>
            In Progress
          </div>
          <div class="failed">
            <span class="material-symbols-outlined"> cancel </span>
            Failed
          </div>
          <div class="success">
            <span class="material-symbols-outlined"> check_circle </span>
            Success
          </div>
        </div>
      </div>
      <div
        class="upload_body_grid_container"
        [ngClass]="{ disabled: !Is_Submit }"
      >
        <div class="grid_div">
          <div
            class="grid_header"
            [ngStyle]="{ 'background-color': getStatusColor('section1') }"
          >
            <span>Intermediate Data Update</span>
          </div>
          <div
            class="upload_div"
            [ngClass]="{ statusActive: getSectionActive('section1') }"
            [ngStyle]="{ 'border-color': getStatusColor('section1') }"
            #dataSanityRawDataConfig_Restatement
          >
            <div class="upload_div_span">
              <span [ngStyle]="{ color: getStatusColor('section1') }"
                >Place the raw data file and config File:</span
              >
              <div
                class="upload_text"
                [ngClass]="{ disabled: getUploadDisableStatus('section1') }"
              >
                <span class="upload_span">{{ BindFileName.sanityInput }}</span>
                <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section1'],'pointer':this.StatusUpdate.section1}" (click)="ChooseFile.click()"> -->
                <span
                  class="material-symbols-outlined"
                  [ngClass]="
                    !Is_Submit
                      ? 'disabled'
                      : this.isTriggered['section1']
                      ? 'disabled'
                      : ''
                  "
                  (click)="ChooseFile.click()"
                >
                  upload
                  <input
                    #ChooseFile
                    type="file"
                    accept=".zip"
                    onclick="value = null"
                    (change)="onFileInput($event, 'section1')"
                    style="display: none"
                  />
                </span>
              </div>
            </div>
            <div
              class="play"
              (click)="onTrigger('section1', 'restatement')"
              [ngClass]="{
                disabled:
                  isFilenotAvailable || isButtonDisableWhenClickOnTrigger
              }"
            >
              <span
                class="material-symbols-outlined"
                [ngStyle]="{ color: getStatusColor('section1') }"
              >
                {{
                  this.defaultPlayButton["section1"]
                    ? "play_circle"
                    : getStatusIcon("section1")
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="grid_div">
          <div
            class="grid_header"
            [ngStyle]="{ 'background-color': getStatusColor('section2') }"
          >
            <span>Final Data Update</span>
          </div>
          <div
            class="upload_div"
            [ngClass]="{ statusActive: getSectionActive('section2') }"
            [ngStyle]="{ 'border-color': getStatusColor('section2') }"
            #finalDataConfigFile
          >
            <div class="upload_div_span">
              <span>Place the config File:</span>
              <div
                class="upload_text"
                [ngClass]="{ disabled: getUploadDisableStatus('section2') }"
              >
                <div class="upload_span">{{ BindFileName.finalLoadInput }}</div>
                <div class="multiple_upload">
                  <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section9']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section9 ? 'pointer' : 'auto' }" (click)=" finalDataChooseFile.click()"> -->
                  <span
                    class="material-symbols-outlined"
                    [ngClass]="{ disabled: this.isTriggered['section2'] }"
                    (click)="finalDataChooseFile.click()"
                  >
                    upload
                    <input
                      #finalDataChooseFile
                      type="file"
                      onclick="value = null"
                      (change)="onFileInput($event, 'section2')"
                      style="display: none"
                    />
                  </span>
                  <!-- <span class="material-symbols-outlined">
                    download
                  </span> -->
                </div>
              </div>
            </div>
            <div
              class="play"
              (click)="onTrigger('section2', 'restatement_uat')"
              [ngClass]="{
                disabled: isfinalLoadTrigger
              }"
            >
              <span
                class="material-symbols-outlined"
                [ngStyle]="{ color: getStatusColor('section2') }"
              >
                {{
                  this.defaultPlayButton["section2"]
                    ? "play_circle"
                    : getStatusIcon("section2")
                }}
              </span>
            </div>
          </div>
        </div>

        <!-- <div style="display: flex">
        <div class="download_text">Final FOM file download</div>
        <span
          style="font-size: 0.8rem"
          class="material-symbols-outlined"
          (click)="OnDownloadFile('section7', 'fom_file')"
          >download
        </span>
      </div> -->
      </div>
    </div>
    <div class="grid_parent">
      <div class="grid_header">
        <span> Status Grid </span>
        <div class="restatementArea" [ngClass]="{'initialSelected': isRestatementSelected}"  (click)="onToggleClick('Restatement')" >
          <!-- <div class="sigArea" > -->
          <div style="display:flex;position:relative;width:100%;height:100%;align-items: center; cursor: pointer;">
              <p style="width:100%;text-align: center; font-size: 0.75rem;">Restatement/Deletion</p>
          </div>
        </div>
        <div class="backupArea" [ngClass]="{'initialSelected': isBackUpAreaSelected}" (click)="onToggleClick('Backup')">
          <p style="width:100%;height:100%;text-align: center;cursor: pointer;">Backup</p>
        </div>
        <div
          class="client_dropdown"
          [ngClass]="{ disabled: !Is_Submit || statusdata.length == 0 }"
        >
          <div class="submit_div" (click)="DownloadUsageLogReport()">
            <span class="material-symbols-outlined"> Usage Log </span>
          </div>
        </div>
      </div>
      <div
        class="scrollbar-container"
        style="margin-top: 5px; height: 91%; overflow: hidden"
      >
        <div
         *ngIf="isRestatementSelected == true"
          class="grid_table_container"
          id="grid_table_container_restatement"
          [ngStyle]="{ '--custom-margin': getMarginForScroll() }"
        >
        <!-- *ngIf="isBackUpAreaSelected == false && firstClick == true" -->
          <table [cellPadding]="0" [cellSpacing]="0">
            <thead>
              <tr style="padding: 0; margin: 0">
                <th class="Process" rowspan="0">Process</th>
                <th class="regular-data-update" rowspan="2">Entry Point 1</th>
                <th class="regular-data-update" rowspan="2">Entry Point 2</th>
                <th class="regular-data-update" rowspan="2">Entry Point 3</th>
                <th class="timeperiod-type" rowspan="2">Week</th>
                <th class="timeperiod-type" rowspan="2">Month</th>
                <th class="timeperiod-type" rowspan="2">Quarter</th>
                <th class="timeperiod-type" rowspan="2">Year</th>
                <th class="variable-data" rowspan="2">Variable code</th>
                <th colspan="3">Update data in Intermediate server</th>
                <th colspan="3">Update data in UAT server</th>
              </tr>
              <tr style="padding: 0; margin: 0">
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of statusdata">
                <td>{{ item.Case }}</td>
                <td>{{ item.EP1 }}</td>
                <td>{{ item.EP2 }}</td>
                <td>{{ item.EP3 }}</td>
                <td>{{ item.week_code }}</td>
                <td>{{ item.month_code }}</td>
                <td>{{ item.quarter_code }}</td>
                <td>{{ item.year_code }}</td>
                <td>{{ item.Variable_code }}</td>
                <td>{{ item.DEV_status }}</td>
                <td>{{ item.DEV_initiated_by }}</td>
                <td>{{ item.DEV_process_time }}</td>
                <td>{{ item.UAT_status }}</td>
                <td>{{ item.UAT_initiated_by }}</td>
                <td>{{ item.UAT_process_time }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
        *ngIf="isRestatementSelected == false"
          style="width: 98%;"
          class="grid_table_container"
          id="grid_table_container_restatement"
          [ngStyle]="{ '--custom-margin': getMarginForScroll() }"
        >
          <table [cellPadding]="0" [cellSpacing]="0">
            <thead>
              <tr style="padding: 0; margin: 0">
                <th class="sl-no" rowspan="2">Sl No</th>
                <th class="entry-point" rowspan="2">Entry Point 1</th>
                <th class="entry-point" rowspan="2">Entry Point 2</th>
                <th class="entry-point" rowspan="2">Entry Point 3</th>
                <th class="timeperiod-type-backup" rowspan="2">Week</th>
                <th class="timeperiod-type-backup" rowspan="2">Month</th>
                <th class="timeperiod-type-backup" rowspan="2">Quarter</th>
                <th class="timeperiod-type-backup" rowspan="2">Year</th>
                <th class="variable-data-backup" rowspan="2">Variable code</th>
                <th class="variable-data-backup" rowspan="2">Initiated By</th>
                <th class="variable-data-backup" rowspan="2">Backed Up Date</th>
                <th class="close-revert-icon" rowspan="2"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of backUpStatusData ;index as i ">
                <td>{{ i+1 }}</td>
                <td>{{ item.EP1 }}</td>
                <td>{{ item.EP2 }}</td>
                <td>{{ item.EP3 }}</td>
                <td>{{ item.week_code }}</td>
                <td>{{ item.month_code }}</td>
                <td>{{ item.quarter_code }}</td>
                <td>{{ item.year_code }}</td>
                <td>{{ item.Variable_code }}</td>
                <td>{{ item.initiated_by}}</td>
                <td>{{ item.backup_date}}</td>
                <td>
                  <div class="backup-icon">
                    <div [ngClass]="{ disabled: getUploadDisableStatus('section1') }" class="close-icon" title="Delete" (click)="OnDeleteClick(item)"></div>
                    <div [ngClass]="{ disabled: getUploadDisableStatus('section1') }" class="revert-icon" title="Revert" (click)="OnRevertClick(item)"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <table [cellPadding]="0" [cellSpacing]="0">
          <thead>
            <tr style="padding: 0; margin: 0">
              <th class="Process" rowspan="0">Process</th>
              <th class="regular-data-update" rowspan="2">Entry Point 1</th>
              <th class="regular-data-update" rowspan="2">Entry Point 2</th>
              <th class="regular-data-update" rowspan="2">Entry Point 3</th>
              <th class="timeperiod-type" rowspan="2">Week</th>
              <th class="timeperiod-type" rowspan="2">Month</th>
              <th class="timeperiod-type" rowspan="2">Quarter</th>
              <th class="timeperiod-type" rowspan="2">Year</th>
              <th class="variable-data" rowspan="2">Variable code</th>
              <th colspan="3">Update data in Intermediate server</th>
              <th colspan="3">Update data in UAT server</th>
            </tr>
            <tr style="padding: 0; margin: 0">
              <th class="status">Status</th>
              <th class="initiated">Initiated by</th>
              <th class="time">Time</th>
              <th class="status">Status</th>
              <th class="initiated">Initiated by</th>
              <th class="time">Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of statusdata">
              <td>{{ item.Case }}</td>
              <td>{{ item.EP1 }}</td>
              <td>{{ item.EP2 }}</td>
              <td>{{ item.EP3 }}</td>
              <td>{{ item.week_code }}</td>
              <td>{{ item.month_code }}</td>
              <td>{{ item.quarter_code }}</td>
              <td>{{ item.year_code }}</td>
              <td>{{ item.Variable_code }}</td>
              <td>{{ item.DEV_status }}</td>
              <td>{{ item.DEV_initiated_by }}</td>
              <td>{{ item.DEV_process_time }}</td>
              <td>{{ item.UAT_status }}</td>
              <td>{{ item.UAT_initiated_by }}</td>
              <td>{{ item.UAT_process_time }}</td>
            </tr>
          </tbody>
        </table> -->
    </div>
  </div>
</ng-container>
<app-confirmation-dialog *ngIf="isShowDialog" [messageText]="confirmationMessage"
  (confirmed)="onConfirmation($event)"></app-confirmation-dialog>
