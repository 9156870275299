import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CalenderModel } from 'src/app/common/models/calendar.model';
import { AlertMessageModel, results } from 'src/app/common/models/common.model';
import { ActiveFlag, ClientSnapshotNames, ClientStudyNames, ExportSnapshotUserDataSelection, ExportUserDataSelection, ModuleClientStudy, ProjectManagement, Roles, modules, userDetails } from 'src/app/common/models/usermanagement.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { LoaderService } from 'src/app/common/services/loader.service';
import { UserProjectManagementService } from 'src/app/common/services/user-project-management.service';
import * as _ from 'lodash';
import { ExcelExportReportInputs } from 'src/app/common/models/excel-export-report.model';
import { COMMON_CONSTANTS } from 'src/app/common/constants/common-constants';
@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.css']
})
export class ProjectManagementComponent implements OnInit {
  @Input() exportDownloads: boolean = true;
  userDetailsPopupHeader = "User Details";
  searchQuery: string = "";
  searchUsers: string = "";
  moduleList: any = [];
  exportReportInput: ExcelExportReportInputs = {
    report_type:'Usermanagement',
    client_name:'',
    study_name:'',
    customReportType:""
  };
  projectManagement!: ProjectManagement;
  clientStudyList!: ClientStudyNames[];
  clientStudyARKList!: ClientStudyNames[];
  clientStudyBrandList!: ClientStudyNames[];
  clientSnapshotList!: ClientSnapshotNames[];
  rolesList!: Roles[];
  rolesListItems!: Roles[];
  ModuleClientStudyList!: ModuleClientStudy[]
  ModuleClientStudyListDisplay!: ModuleClientStudy[];
  showUserPopup: boolean = false;
  listOfUser!: userDetails[];
  selectedActiveCell: number | null = null;
  selectedRoleCell: number | null = null;
  activeFlag: ActiveFlag[] = [{ name: "Active", is_selected: false }, { name: "Inactive", is_selected: false }];
  activeFlagList: ActiveFlag[] = [];
  isShowDialog: boolean = false;
  confirmationMessage: string = "";
  confirmationType: string = "";
  deleteUserItem!: userDetails;
  isLevel1: boolean = false;
  isLevel2: boolean = false;
  ActiveModule1: boolean = false;
  ActiveModule2: boolean = false;
  showExportsPopup: boolean = false;
  searchClientStudy: string = "";
  searchClientSnapshot: string = "";
  clientStudySelectAll: boolean = false;
  clientSnapshotSelectAll: boolean = false;
  clientStudyListCopy: ClientStudyNames[] = [];
  clientSnapshotListCopy:ClientSnapshotNames[]=[];
  SelectedClientStudy: ClientStudyNames[] = [];
  SelectedClientSnapshot: ClientSnapshotNames[] = [];
  CrossTabName:string=COMMON_CONSTANTS.CrossTabModuleId;
  BrandAnalysisName:string=COMMON_CONSTANTS.BrandAnalysisModuleNameId;
  ArkName:string=COMMON_CONSTANTS.ARKName;
  fromyear: number = 0;
  frommonth: number = 0;
  calenderData: CalenderModel = { fromMonth: -1, fromYear: -1, toMonth: -1, toYear: -1 }
  largeSelectionText: string = "Your excel download is in progress. Please check the file status in \"Settings > Export Downloads\""
  constructor(private _managementService: UserProjectManagementService,
    private _loaderService: LoaderService,
    private _alertService: AlertMessageService,
    private _authenticateService: AuthenticationService) {
    // assign the client and study details from router extra state
  }
  ngOnInit(): void {
    this.ActiveModule1 = true;
    this.ActiveModule2 = false;
    this.getAllModuleDetails()
  }
  /**
 * Retrieves all module details, including moduleList, clientStudyList, and rolesList.
 * Populates ModuleClientStudyList based on the combination of modules, clientStudy, and roles.
 */
  uniqModule: any;
  uniqClientStudy: any;
  getAllModuleDetails() {
    // Show loader while fetching module details
    this._loaderService.showLoader();
    // Call the getModuleDetails method to retrieve module details
    this._managementService.getModuleDetails().subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Determine the access level
        let accessLevel = this._authenticateService.GetAccess();
        // Set flags based on access level
        this.isLevel1 = accessLevel == this._authenticateService.level1;
        this.isLevel2 = accessLevel == this._authenticateService.level2;
        // Parse the result data as projectManagement
        this.projectManagement = JSON.parse(result.Data);
        // ;
        // Extract moduleList, clientStudyList, and rolesList from projectManagement
        this.moduleList = this.projectManagement.moduleList;
        this.clientStudyList = this.projectManagement.clientStudyList;
        this.clientStudyARKList = this.projectManagement.clientStudyARKList;
        this.clientStudyBrandList = this.projectManagement.clientStudyBrandList;
        this.clientSnapshotList = this.projectManagement.ClientSnapshotNamesList;
        this.rolesList = this.projectManagement.roleList;

        // Initialize ModuleClientStudyList
        this.ModuleClientStudyList = [];
        // Populate ModuleClientStudyList based on moduleList, clientStudyList, and rolesList
        if (this.moduleList != null && this.moduleList.length > 0) {
          this.moduleList.forEach((module: modules) => {
            if (module.module_name == this.CrossTabName) {
              this.AddClientStudyDetails(this.clientStudyList,module)
            }
            else if (module.module_name == this.BrandAnalysisName) {
              this.AddClientStudyDetails(this.clientStudyBrandList,module)
            }
            else if (module.module_name == this.ArkName) {
              this.AddClientStudyDetails(this.clientStudyARKList,module)
            }
            else if (module.module_name == 'Snapshot') {
              this.clientSnapshotList.forEach((clientSnapshot: ClientSnapshotNames) => {
                this.ModuleClientStudyList.push({
                  client_name: clientSnapshot.client_name,
                  //client_study_name: "",
                  module_id: module.module_id,
                  module_name: module.module_name == null ? "" : module.module_name,
                  study_name: "",
                  snapshot_name: clientSnapshot.snapshot_name,
                  client_study_name: `${clientSnapshot.client_name} : ${clientSnapshot.snapshot_name}`
                });
              });
            }

          });
        }
      }
      //-----------------Code for filtering-------------------------

      // Created ModuleClientStudyListDisplay for only display purpose after filtering
      this.ModuleClientStudyListDisplay = this.ModuleClientStudyList;

      let modulesFilter = _.uniqBy(_.map(this.ModuleClientStudyListDisplay, item => _.pick(item, 'module_name')), 'module_name');
      this.uniqModule = _.map(modulesFilter, item => ({ data: item.module_name, isSelected: false, header: 'module_name', moduleName: item.module_name }));
      this.uniqModule.sort((a: any, b: any) => {
        // Convert both data values to lowercase for case-insensitive comparison
        const dataA = a.data.toLowerCase();
        const dataB = b.data.toLowerCase();

        // Use localeCompare for string comparison
        return dataA.localeCompare(dataB);
      });

      let clientsSDFilter = _.uniqBy(_.map(this.ModuleClientStudyListDisplay, item => _.pick(item, ['client_study_name', 'module_name'])), 'client_study_name');
      this.uniqClientStudy = _.map(clientsSDFilter, item => ({ data: item.client_study_name, isSelected: false, header: 'client_study_name', moduleName: item.module_name }));
      this.uniqClientStudy.sort((a: any, b: any) => {
        // Convert both data values to lowercase for case-insensitive comparison
        const dataA = a.data.toLowerCase();
        const dataB = b.data.toLowerCase();

        // Use localeCompare for string comparison
        return dataA.localeCompare(dataB);
      });

       ;
      //------------------------------------------
      // Hide the loader after fetching module details
      this._loaderService.hideLoader();
    });
  }
AddClientStudyDetails(clientstudy:ClientStudyNames[],module:modules)
{
  clientstudy.forEach((clientStudy: ClientStudyNames) => {
    this.ModuleClientStudyList.push({
      client_name: clientStudy.client_name,
      client_study_name: `${clientStudy.client_name} : ${clientStudy.study_name}`,
      module_id: module.module_id,
      module_name: module.module_name == null ? "" : module.module_name,
      study_name: clientStudy.study_name,
      snapshot_name: "",
      //client_snapshot_name:""
    });
  });
}
  /**
   * Handles the click event when editing a moduleItem.
   * Displays the user popup, fetches users based on the moduleItem, and populates the listOfUser.
   *
   * @param moduleItem - The ModuleClientStudy item for which users are to be retrieved.
   */
  OnEditClick(moduleItem: ModuleClientStudy) {
    // Display the user popup
    this.showUserPopup = true;
    // Show loader while fetching users
    this._loaderService.showLoader();
    // popup header text
    this.userDetailsPopupHeader = `User Details (${moduleItem.module_name} - ${moduleItem.client_study_name})`;
    // Clear the existing listOfUser
    this.listOfUser = [];
    // Call the GetUsersBasedOnModuleClientStudy method to retrieve users based on the moduleItem
    this._managementService.GetUsersBasedOnModuleClientStudy(moduleItem).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Parse the result data as listOfUser
        this.listOfUser = JSON.parse(result.Data);
      }
      // Hide the loader after fetching users
      this._loaderService.hideLoader();
    });
  }

  /**
 * Handles the click event when deleting a userItem's access.
 * Sets the deleteUserItem and confirmationType, then shows a confirmation dialog.
 *
 * @param userItem - The userDetails item for which access is to be deleted.
 */
  OnDeleteModuleClick(userItem: userDetails) {
    // Set the deleteUserItem to the clicked userItem
    this.deleteUserItem = userItem;
    // Set confirmationType to "delete" to identify the type of action
    this.confirmationType = "delete";
    // Prepare the confirmation message
    let message = "Are you sure you want to delete this user access?";
    // Show the confirmation dialog with the prepared message
    this.showConfirmationDialog(message);
  }

  /**
   * Handles the click event when submitting users' access.
   * Sets confirmationType and shows a confirmation dialog.
   */
  OnSubmitClick() {
    // Set confirmationType to "submit" to identify the type of action
    this.confirmationType = "submit";
    // Prepare the confirmation message
    let message = "Are you sure you want to submit users access?";
    // Show the confirmation dialog with the prepared message
    this.showConfirmationDialog(message);
  }

  /**
 * Handles the click event when downloading user details for a specific module.
 * Initiates the download by calling the DownloadUserDetails method from the management service.
 *
 * @param moduleDetails - The ModuleClientStudy item for which user details are to be downloaded.
 */
  OnDownloadClick(moduleDetails: ModuleClientStudy) {
    // Show loader while downloading user details
    this._loaderService.showLoader();
    // Call the DownloadUserDetails method to retrieve user details for the specified module
    this._managementService.DownloadUserDetails(moduleDetails).subscribe(
      (excelBlob: Blob) => {
        // Generate a unique filename based on moduleDetails and current date time
        let datetimeFormat = this._managementService.getCurrentDateTime();
        //let fileName = `Kantar_Userdetails_${moduleDetails.module_name}_${moduleDetails.client_name}_${moduleDetails.study_name}_${datetimeFormat}.xlsx`;
        let fileName = `Kantar_Userdetails_${moduleDetails.module_name}_${moduleDetails.client_study_name}_${datetimeFormat}.xlsx`;


        // Trigger file download using the downloadFile method from the management service
        this._managementService.downloadFile(excelBlob, fileName);
        // Hide the loader after successful download
        this._loaderService.hideLoader();
      },
      (error) => {
        // Log and display an error message if there is an issue with the download
        console.error('Error exporting to Excel', error);
        this.AlertPopup('Something went wrong, please try again');
        // Hide the loader after handling the error
        this._loaderService.hideLoader();
      }
    );
  }

  /**
 * Handles the deletion of user access based on the deleteUserItem.
 * Calls the DeleteAccessOnModuleClientStudy method from the management service.
 */
  deleteAccess() {
    // Show loader while deleting user access
    this._loaderService.showLoader();
    // Call the DeleteAccessOnModuleClientStudy method to delete user access
    this._managementService.DeleteAccessOnModuleClientStudy(this.deleteUserItem).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Check if the result Data is not null and equals "1" (assuming it represents success)
        if (result.Data != null && result.Data !== "" && result.Data === "1") {
          // Find the index of the deleted user in listOfUser
          let index = this.listOfUser.findIndex((x: userDetails) => x.email === this.deleteUserItem.email);
          // If the user is found, remove it from listOfUser
          if (index > -1) {
            this.listOfUser.splice(index, 1);
            // Display a success alert popup
            this.AlertPopup("Deleted successfully", "Message");
          }
        } else {
          // Display a failure alert popup if deletion fails
          this.AlertPopup("Failed to delete", "Message");
        }
      }
      // Hide the loader after handling the result
      this._loaderService.hideLoader();
    });
  }

  /**
 * Handles the update of all user accesses based on the listOfUser.
 * Calls the UpdateAllUserAccess method from the management service.
 */
  updateAccess() {
    // Show loader while updating user accesses
    this._loaderService.showLoader();

    // Call the UpdateAllUserAccess method to update user accesses
    this._managementService.UpdateAllUserAccess(this.listOfUser).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Check if the result Data is not null and equals "1" (assuming it represents success)
        if (result.Data != null && result.Data !== "" && result.Data === "1") {
          // Display a success alert popup
          this.AlertPopup("Updated successfully", "Message");
        } else {
          // Display a failure alert popup if the update fails
          this.AlertPopup("Failed to update", "Message");
        }
      }
      // Hide the loader after handling the result
      this._loaderService.hideLoader();
    });
  }

  onClosePopup() {
    this.showUserPopup = false
  }
  /**
 * Handles the radio button toggle event for selecting a role.
 * Updates the is_selected property of the selected roleItem and sets the role_name in userData.
 *
 * @param roleItem - The selected kap_roles item.
 * @param userData - The userDetails object to update with the selected role.
 */
  OnRadioRoleFlagToggle(roleItem: Roles, userData: userDetails) {
    // Set the is_selected property of the clicked roleItem to true
    roleItem.is_selected = true;
    // Update the role_name property in userData with the selected roleItem's role_name
    userData.access.role_name = roleItem.role_name;
    // Find any other selected roleItem and set its is_selected property to false
    let otherSelected = this.rolesListItems.find((x: Roles) => x.is_selected && x.role_name !== roleItem.role_name);
    if (otherSelected != null && otherSelected != undefined) {
      otherSelected.is_selected = false;
    }
  }

  /**
 * Handles the radio button toggle event for selecting an active flag.
 * Updates the is_selected property of the selected activeItem and sets the is_active property in userData.
 *
 * @param activeItem - The selected ActiveFlag item.
 * @param userData - The userDetails object to update with the selected active flag.
 */
  OnRadioActiveFlagToggle(activeItem: ActiveFlag, userData: userDetails) {
    // Set the is_selected property of the clicked activeItem to true
    activeItem.is_selected = true;
    // Update the is_active property in userData based on the selected activeItem's name
    if (activeItem.name == "Active") {
      userData.access.is_active = true;
    } else {
      userData.access.is_active = false;
    }
    // Find any other selected activeItem and set its is_selected property to false
    let otherSelected = this.activeFlagList.find((x: ActiveFlag) => x.is_selected && x.name !== activeItem.name);
    if (otherSelected != null && otherSelected != undefined) {
      otherSelected.is_selected = false;
    }
  }

  /**
 * Toggles the active dropdown for a specific user by updating the selectedActiveCell and activeFlagList.
 *
 * @param index - The index of the selected active cell.
 * @param user - The userDetails object for which the active dropdown is toggled.
 */
  toggleActiveDropdown(index: number, user: userDetails) {
    // Toggle the selectedActiveCell based on the index
    this.selectedActiveCell = this.selectedActiveCell === index ? null : index;
    // Clone the original activeFlagList to avoid direct modification
    this.activeFlagList = this.cloneObject(this.activeFlag);
    // Check if the activeFlagList is not null
    if (this.activeFlagList != null) {
      // Find the corresponding active item in the cloned activeFlagList based on user's is_active property
      let active = this.activeFlagList.find((x: ActiveFlag) => {
        if (user.access.is_active) {
          return x.name == 'Active';
        } else {
          return x.name == 'Inactive';
        }
      });
      // If the corresponding active item is found, set its is_selected property to true
      if (active != null) {
        active.is_selected = true;
      }
    }
  }

  /**
 * Toggles the role dropdown for a specific user by updating the selectedRoleCell and rolesListItems.
 *
 * @param index - The index of the selected role cell.
 * @param user - The userDetails object for which the role dropdown is toggled.
 */
  toggleroleDropdown(index: number, user: userDetails) {
    // Toggle the selectedRoleCell based on the index
    this.selectedRoleCell = this.selectedRoleCell === index ? null : index;
    // Clone the original rolesListItems to avoid direct modification
    this.rolesListItems = this.cloneObject(this.rolesList);
    // Check if the rolesListItems is not null
    if (this.rolesListItems != null) {
      // Find the corresponding role item in the cloned rolesListItems based on user's access role_name property
      let role = this.rolesListItems.find((x: Roles) => x.role_name == user.access.role_name);
      // If the corresponding role item is found, set its is_selected property to true
      if (role != null) {
        role.is_selected = true;
      }
    }
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object))
  }

  /**
   * to show the error with message
   * @param message - . string text
   */
  AlertPopup(message: string, headerTxt: string = "Error"): void {
    const alertObj: AlertMessageModel = { header: headerTxt, message: message, show: true };
    this._alertService.showAlert(alertObj);
  }

  /**
 * Displays a confirmation dialog with the specified message.
 *
 * @param message - The message to be displayed in the confirmation dialog.
 */
  showConfirmationDialog(message: string) {
    // Set the confirmationMessage to the provided message
    this.confirmationMessage = message;

    // Set isShowDialog to true to display the confirmation dialog
    this.isShowDialog = true;
  }

  
  onConfirmation(operation: number) {
    if (operation===1) {
      // If the user confirmed, perform the action based on the confirmationType
      switch (this.confirmationType) {
        case "submit":
          this.updateAccess();
          break;
        case "delete":
          this.deleteAccess();
          break;
        default:
          // Close the dialog if no specific action is defined
          this.isShowDialog = false;
          break;
      }
      // Close the dialog after handling the confirmation
      this.isShowDialog = false;
    } else {
      // If the user canceled, simply close the dialog
      this.isShowDialog = false;
    }
  }
  moduleLists: any[] = [
    {name: "Crosstab", isSelected: true},
    {name: "Snapshot", isSelected: false}]
  moduleToggle = false;
  moduleSelected="Crosstab"
  moduleDropdown() {
    this.moduleToggle = !this.moduleToggle;
  }
  DDModule=true;
  SnapModule=false;
  moduleClick(module:any,event:Event){
    ;
    
    if(module.name=="Crosstab"){
      this.searchClientSnapshot="";//Reseting search on change in module
      this.DDModule=true;
      this.SnapModule=false;

      //handling select All based on module toggle
      this.clientStudySelectAll = false;
      this.ClientStudySelectDeSelect(this.clientStudySelectAll);
      this.FilterSelectedClientStudy();

    }else if(module.name=="Snapshot"){
      this.searchClientStudy="";//Reseting search on change in module
      this.DDModule=false;
      this.SnapModule=true;

      //handling select All based on module toggle
      this.clientSnapshotSelectAll = false;
      this.ClientSnapshotSelectDeSelect(this.clientSnapshotSelectAll);
      this.FilterSelectedClientSnapshot();
    }
    for(let i=0;i<this.moduleLists.length;i++){
      if(module.name==this.moduleLists[i].name){
        this.moduleLists[i].isSelected=true;
        this.moduleSelected=this.moduleLists[i].name;
      }
      else{
        this.moduleLists[i].isSelected=false;
      }
    }
    event.stopPropagation();
  }
  OnExportUserDataClick() {
    
    for(let i=0;i<this.moduleLists.length;i++){
      if(this.moduleLists[i].name=="Crosstab"){
        this.moduleLists[i].isSelected=true;
      }
      else{
        this.moduleLists[i].isSelected=false;
      }
    }
    this.moduleSelected="Crosstab";
    this.DDModule=true;
    this.SnapModule=false;
    this.fromyear = 0;
    this.showExportsPopup = true;
    this.clientStudySelectAll = false;
    this.clientSnapshotSelectAll = false;
    this.searchClientStudy = "";
    this.searchClientSnapshot = "";
    
    this.clientStudyListCopy = this.cloneObject(this.projectManagement.clientStudyList);
    if (this.clientStudyListCopy.length > 0) {
      this.clientStudyListCopy = this.clientStudyListCopy.map((x: ClientStudyNames) => {
        x.client_study_name = x.client_name + " : " + x.study_name; x.isSelected = false; return x
      })
    }
    this.clientStudyListCopy.sort((a, b) => a.client_study_name.localeCompare(b.client_study_name));
    


    this.clientSnapshotListCopy = this.cloneObject(this.projectManagement.ClientSnapshotNamesList);
    if (this.clientSnapshotListCopy.length > 0) {
      this.clientSnapshotListCopy = this.clientSnapshotListCopy.map((x: ClientSnapshotNames) => {
        x.client_snapshot_name = x.client_name + " : " + x.snapshot_name; x.isSelected = false; return x
      })
    }
    this.clientSnapshotListCopy.sort((a, b) => a.client_snapshot_name.localeCompare(b.client_snapshot_name));
;

  }
  OnSelectAllClick(moduleName:string) {
    if(moduleName=="Crosstab"){
      this.clientStudySelectAll = !this.clientStudySelectAll;
      this.ClientStudySelectDeSelect(this.clientStudySelectAll);
      this.FilterSelectedClientStudy();
    }
    else if(moduleName=="Snapshot"){
      this.clientSnapshotSelectAll = !this.clientSnapshotSelectAll;
      this.ClientSnapshotSelectDeSelect(this.clientSnapshotSelectAll);
      this.FilterSelectedClientSnapshot();
    }
    
  }
  ClientStudySelectDeSelect(select: boolean) {
    this.clientStudyListCopy.forEach((x: ClientStudyNames) => {
      x.isSelected = select;
    })
  }
  ClientSnapshotSelectDeSelect(select: boolean) {
    this.clientSnapshotListCopy.forEach((x: ClientSnapshotNames) => {
      x.isSelected = select;
    })
  }
  OnCheckBoxClick(clientStudy: ClientStudyNames) {
    clientStudy.isSelected = !clientStudy.isSelected;
    let unselectedItem = this.clientStudyListCopy.some((obj: ClientStudyNames) => obj.isSelected === false);
    if (unselectedItem) {
      this.clientStudySelectAll = false;
    }
    else {
      this.clientStudySelectAll = true;
    }
    this.FilterSelectedClientStudy();
  }
  OnSnapshotCheckBoxClick(clientSnapshot: ClientSnapshotNames) {
    clientSnapshot.isSelected = !clientSnapshot.isSelected;
    let unselectedItem = this.clientSnapshotListCopy.some((obj: ClientSnapshotNames) => obj.isSelected === false);
    if (unselectedItem) {
      this.clientSnapshotSelectAll = false;
    }
    else {
      this.clientSnapshotSelectAll = true;
    }
    this.FilterSelectedClientSnapshot();
  }
  FilterSelectedClientStudy() {
    this.SelectedClientStudy = this.clientStudyListCopy.filter((obj: ClientStudyNames) => { return obj.isSelected })
  }
  FilterSelectedClientSnapshot() {
    this.SelectedClientSnapshot = this.clientSnapshotListCopy.filter((obj: ClientSnapshotNames) => { return obj.isSelected })
  }
  closeModuleDropdown(){
    this.moduleToggle=false;
  }
  onCloseExportsPopup() {
    this.fromyear = 0;
    this.frommonth = 0;
    this.showExportsPopup = false;
    this.clientStudySelectAll = false;
    this.clientSnapshotSelectAll = false;
    this.SelectedClientStudy = []
    this.SelectedClientSnapshot = []
    this.searchClientStudy = "";
    this.searchClientSnapshot= "";
    this.calenderData.fromYear = -1;
    this.calenderData.fromMonth = -1;
    this.calenderData.toYear = -1;
    this.calenderData.toMonth = -1;
  }

  onFromMonthSelected(event: { year: number, month: number }): void {
    this.calenderData.fromYear = event.year;
    this.calenderData.fromMonth = event.month;
    this.calenderData.toYear = -1;
    this.calenderData.toMonth = -1;
  }
  onToMonthSelected(event: { year: number, month: number }): void {
    this.calenderData.toYear = event.year;
    this.calenderData.toMonth = event.month;
  }
  OnDownloadUserDataClick() {
    if (this.SelectedClientStudy.length <= 0 || (this.calenderData.fromYear == -1 || this.calenderData.fromMonth == -1 || this.calenderData.toYear == -1 || this.calenderData.toMonth == -1)) {
      this.AlertPopup("Please select all mandatory selections")
      return;
    }
    let selectionExportDetails: ExportUserDataSelection = {
      clientStudyNames: this.SelectedClientStudy,
      monthRange: this.calenderData,
      selectedAllClients: this.clientStudySelectAll
    }
     ;
    this._loaderService.showLoader();

    // Call the UpdateAllUserAccess method to update user accesses
    this._managementService.ExportUserData(selectionExportDetails).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Check if the result Data is not null and equals "1" (assuming it represents success)
        if (result.Data != null && result.Data !== "") {
          if (result.Data == "1") {
            this.AlertPopup(this.largeSelectionText, "Alert");
            console.log("Started in Background");
            this.onCloseExportsPopup();
          }
          else {
            const blobdata = this.base64toBlob(result.Data);
            let fileName = 'Kantar_Userdetails_' + this._managementService.getCurrentDateTime() + ".xlsx";
            this._managementService.downloadFile(blobdata, fileName);
            this.onCloseExportsPopup();
          }
        }
      }
      // Hide the loader after handling the result
      this._loaderService.hideLoader();
    });
  }

  OnSnapDownloadUserDataClick(){
    if (this.SelectedClientSnapshot.length <= 0 || (this.calenderData.fromYear == -1 || this.calenderData.fromMonth == -1 || this.calenderData.toYear == -1 || this.calenderData.toMonth == -1)) {
      this.AlertPopup("Please select all mandatory selections")
      return;
    }
    let selectionExportDetails: ExportSnapshotUserDataSelection = {
      clientSnapshotNames: this.SelectedClientSnapshot,
      monthRange: this.calenderData,
      selectedAllClients: this.clientSnapshotSelectAll
    }
      ;
    this._loaderService.showLoader();

    // Call the UpdateAllUserAccess method to update user accesses
    this._managementService.ExportSnapshotUserData(selectionExportDetails).subscribe((result: results) => {
      // Check if the response has valid data
      if (result.HasValidData) {
        // Check if the result Data is not null and equals "1" (assuming it represents success)
        if (result.Data != null && result.Data !== "") {
          if (result.Data == "1") {
            this.AlertPopup(this.largeSelectionText, "Alert");
            console.log("Started in Background");
            this.onCloseExportsPopup();
          }
          else {
            const blobdata = this.base64toBlob(result.Data);
            let fileName = 'Kantar_Snapshot_Userdetails_' + this._managementService.getCurrentDateTime() + ".xlsx";
            this._managementService.downloadFile(blobdata, fileName);
            this.onCloseExportsPopup();
          }
        }
      }
      // Hide the loader after handling the result
      this._loaderService.hideLoader();
    });
  }
  private base64toBlob(base64Data: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  }
  // Listen for click events on the document.
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    // Check if the click event occurred outside the <div> block.
    if (this.selectedActiveCell != null) {
      if (!this.isClickInside(event, ".active-flag-container")) {
        this.selectedActiveCell = null;
      }
    }
    if (this.selectedRoleCell != null) {
      if (!this.isClickInside(event, ".role-flag-container")) {
        this.selectedRoleCell = null;
      }
    }

  }
  // Helper function to check if the click occurred inside the <div> block.
  private isClickInside(event: Event, className: string): boolean {
    const elements = document.querySelectorAll(className);
    for (let i = 0; i < elements.length; i++) {
      const container = elements[i];
      if (container.contains(event.target as Node)) {
        return true;
      }
    }
    return false;
  }

  // ----------------------------Adding filter popup-------------------

  filterModulePopup = false;
  filterClientStudyPopup = false;
  showFilterPopup(colSelected: string, event: Event) {
    // ;
    if (colSelected == 'module') {
      this.filterClientStudyPopup = false;
      this.filterModulePopup = !this.filterModulePopup;
    } else {
      this.filterModulePopup = false;
      this.filterClientStudyPopup = !this.filterClientStudyPopup;
    }
    event.stopPropagation();
  }

  closeSummary() {
    this.filterModulePopup = false;
    this.filterClientStudyPopup = false;
  }

  //------------------ Multiselect and Multicolumn search logic--------------------------
  
  heading_items: any[] = [
    { header: "module_name", searchTxt: [] },
    { header: "client_study_name", searchTxt: [] }
  ];

  receivedModuleFilters(event: any) {
    for (let i = 0; i < this.heading_items.length; i++) {
      if (event.header == this.heading_items[i].header) {
        this.heading_items[i].searchTxt = event.searchTxt;
      }
    }
     ;
    
    //-----------On deselct from module dropdown all the selected study of deseledted item will be removed
    // Extract the values from heading_items[0].searchTxt
    const allowedValues = this.heading_items[0].searchTxt.map((item: any) => item.value);
    // Filter heading_items[1].searchTxt based on the allowed values
    this.heading_items[1].searchTxt = this.heading_items[1].searchTxt.filter((item: any) =>
      allowedValues.includes(item.key)
    );
    // ----------------------------------------

    if (this.heading_items[0].searchTxt.length == 0) {

       ;
      let clientsSDFilter = _.uniqBy(_.map(this.ModuleClientStudyList, item => _.pick(item, ['client_study_name', 'module_name'])), 'client_study_name');

      this.uniqClientStudy = _.map(clientsSDFilter, item => ({ data: item.client_study_name, isSelected: false, header: 'client_study_name', moduleName: item.module_name }));
      this.uniqClientStudy.sort((a: any, b: any) => {
        // Convert both data values to lowercase for case-insensitive comparison
        const dataA = a.data.toLowerCase();
        const dataB = b.data.toLowerCase();

        // Use localeCompare for string comparison
        return dataA.localeCompare(dataB);
      });
       ;
      this.heading_items[1].searchTxt.length = 0;
    }
    else {

      const filteredModuleClientStudyList = this.ModuleClientStudyList.filter((object: any) => {
        const moduleObject = this.heading_items.find(item => item.header === "module_name");
        if (moduleObject) {
          const propertyValue = object[moduleObject.header];
          if (typeof propertyValue === "string") {
            // Check if the propertyValue exactly matches one of the searchTxt elements
            return moduleObject.searchTxt.some((searchItem: any) => searchItem.value === propertyValue);
          }
        }
        return false;
      });
       ;

      let clientsSDFilter = filteredModuleClientStudyList.map((item: any) => {
        let pickedItem: any = _.pick(item, ['client_study_name', 'module_name']);
        const clientStudyNameHeader = this.heading_items.find(heading => heading.header === "client_study_name");
        pickedItem.isSelected = clientStudyNameHeader ? clientStudyNameHeader.searchTxt.some((searchItem: any) => searchItem.value === item.client_study_name) : false;
        return pickedItem;
      });
      clientsSDFilter = _.uniqBy(clientsSDFilter, 'client_study_name');
       ;


      this.uniqClientStudy = clientsSDFilter.map(item => ({ data: item.client_study_name, isSelected: item.isSelected, header: 'client_study_name', moduleName: item.module_name }));
      this.uniqClientStudy.sort((a: any, b: any) => {
        const dataA = a.data.toLowerCase();
        const dataB = b.data.toLowerCase();
        return dataA.localeCompare(dataB);
      });
       ;

    }


    this.filterColumnWise();
  }

  receivedStudyFilters(event: any) {
    for (let i = 0; i < this.heading_items.length; i++) {
      if (event.header === this.heading_items[i].header) {
        this.heading_items[i].searchTxt = event.searchTxt;
      }
    }
    this.filterColumnWise();
  }


  filterColumnWise() {
    // Extract all search terms from heading_items
    let searchTermsByColumn: { [key: string]: string[] } = {};
    for (let heading of this.heading_items) {
      searchTermsByColumn[heading.header] = heading.searchTxt.map((item: any) => item.value);
    }
     ;
    // Filter ModuleClientStudyList
    this.ModuleClientStudyListDisplay = this.ModuleClientStudyList.filter((studyItem: any) => {
      return Object.keys(searchTermsByColumn).every((column) => {
        const columnSearchTerms = searchTermsByColumn[column];
        if (columnSearchTerms.length === 0) {
          return true;
        }
        return columnSearchTerms.some((searchTerm) => {
          return studyItem[column].toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
      });
    });

  }
}











