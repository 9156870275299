import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();
  /**
   * show the loader icon
   */
  showLoader():void {
    this.isLoading.next(true);
  }

  /**
   * hide the loader icon
   */
  hideLoader():void {
    this.isLoading.next(false);
  }
}
