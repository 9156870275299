import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-module-list',
  templateUrl: './header-module-list.component.html',
  styleUrls: ['./header-module-list.component.css'],
})
export class HeaderModuleListComponent implements OnInit {
  @Input() selectedModule: string = '';
  @Input() showBrandAnalysis: boolean = false;
  landingdata: any;

  moduleList = [
    { name: 'CROSSTAB', isSelected: true,show:true },
    { name: 'BRAND ANALYSIS', isSelected: false,show:false },
  ];

  constructor(private router: Router) {
    this.landingdata = this.router.getCurrentNavigation()?.extras.state;
  }
  ngOnInit(): void {
    this.setSelectedModule();
  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes["showBrandAnalysis"] && this.showBrandAnalysis) {
      this.setSelectedModule();
    }
  }
  setSelectedModule() {
    this.moduleList.forEach((element) => {
      if (element.name === this.selectedModule) {
        element.isSelected = true;
      } else {
        element.isSelected = false;
      }
      if(element.name === 'BRAND ANALYSIS' && this.showBrandAnalysis){
        element.show = true;
      }
    });
  }

  navigateToModule(module: string) {
    switch (module) {
      case 'BRAND ANALYSIS':
        this.router.navigateByUrl('BrandAnalysis', { state: this.landingdata });
        break;
      case 'CROSSTAB':
        this.router.navigateByUrl('Crosstab', { state: this.landingdata });
        break;
    }
  }
}
